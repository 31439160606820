import ComingSoonImage from '../assets/images/Group 43.svg'

const ComingSoonPage = () => {
  return (
    <div className="text-center mt-5 mb-5 py-2 px-2">
      <img src={ComingSoonImage} className="img-fluid" alt="Coming Soon" />
    </div>
  )
}

export default ComingSoonPage