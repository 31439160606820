import { Box } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import Title from '../../components/title'
import { useState, Fragment } from 'react'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import axios from "axios";
import formatter from '../../util/formatter-v2'

const TermsAndCondition = () => {
  const [components, setComponents] = useState([])

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-terms-and-condtions`
    const { data } = await axios.get(url)
    setComponents(data.slices)
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-terms-and-conditions-slices.content':
        return (
          <>
            <Title data={{ first_partial_title: data.title }} />

            <Box sx={{ lineHeight: 1.5, marginTop: 5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.description) }} />
          </>
        )
      default:
        return null;
    }
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{
        ...containerSpacing
      }}>
        {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
      </Box>
    </ThemeProvider>
  )
}

export default TermsAndCondition