import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button, Tooltip } from '@mui/material'
import { sessionStorage, util } from '../../../util'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useQuery from '../../../hooks/useQuery'
import { useLocation } from 'react-router-dom'
import aecLogo from '../../../assets/images/aec-logo.jpg'

import './styles.scss'
import './sub-menu.scss'

function AxHeader(props) {
  const { className, header: _header, navbar: _navbar } = props
  const [header, setHeader] = useState(util.isObject(_header) ? _header : {})
  const [navbar, setNavbar] = useState(util.isObject(_navbar) ? _navbar : {})
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [subMenuIdx, setSubMenuIdx] = useState(-1)
  const [subMenuTimers, setSubMenuTimers] = useState([])
  const { button: _hButtons, social_icons: _hIcons } = header
  const { button: _nButton, home_icon: _nIcon, panels: _nPanels } = navbar
  const hButtons = Array.isArray(_hButtons) ? _hButtons : []
  const hIcons = Array.isArray(_hIcons) ? _hIcons : []
  const nButton = util.isObject(_nButton) ? _nButton : {}
  const nIcon = util.isObject(_nIcon) ? _nIcon : {}
  const nPanels = Array.isArray(_nPanels) ? _nPanels : []
  const { link: nButtonLink } = nButton
  const { url: nIconUrl } = nIcon
  const { href: nButtonHref, label: nButtonLabel, target: nButtonTarget } = util.isObject(nButtonLink) ? nButtonLink : {}
  const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 0 })
  const query = useQuery()
  const location = useLocation()

  const Menu = (props) => {
    const { keyPrefix } = props

    return (
      <div className='menu'>
        {nPanels.map((panel, idx) => {
          const { id, Panel_Name: _panel, sections } = util.isObject(panel) ? panel : {}
          const pPanel = util.isObject(_panel) ? _panel : {}
          const pSections = Array.isArray(sections) ? sections : []
          const { href, label, target } = pPanel
          const wlPathname = getLocationPathname()
          const mmIdx = sessionStorage.getItem('mmIdx')
          const smIdx = sessionStorage.getItem('smIdx')
          let isLinkActive = false

          if (typeof href === 'string' && href === wlPathname) {
            isLinkActive = true

            if (`${mmIdx}` === `${idx}`) {
              isLinkActive = true
            }
          } else if (typeof smIdx === 'string' && `${smIdx}` !== '-1') {
            const pSection = pSections[0]
            const section = util.isObject(pSection) ? pSection : {}
            const { related_pages: _pages } = util.isObject(section) ? section : {}
            const pages = Array.isArray(_pages) ? _pages : []
            const smIdxInt = parseInt(smIdx, 10)
            const page = util.isObject(pages[smIdxInt]) ? pages[smIdxInt] : {}
            const { href: pageHref } = page

            if (typeof pageHref === 'string' && pageHref === wlPathname) {
              isLinkActive = true
            } else if (href === wlPathname && `${mmIdx}` === `${idx}`) {
              isLinkActive = true
            } else {
              for (let i = 0; i < pages.length; i++) {
                const page = util.isObject(pages[i]) ? pages[i] : {}
                const { href: pageHref } = page

                if (typeof pageHref === 'string' && pageHref === wlPathname) {
                  isLinkActive = true
                  break
                }
              }
            }
          }

          return (
            <div className='item' key={`${keyPrefix}-${id}`} onMouseEnter={(e) => createSubMenu(e, idx)} onMouseLeave={handleHideSubMenu}>
              <div className={clsx('link-box', isLinkActive ? 'active' : '')}>
                {href
                  ? <a href={href} onClick={handleClickMainMenuItem(idx)} target={target || '_self'}>
                    {label || ''}
                  </a>
                  : <span>{label || ''}</span>}
                {pSections.length > 0 ? <KeyboardArrowDownIcon className="dropdown-append-icon" /> : null}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const createSubMenu = (event, idx) => {
    const { top, left } = event.target.getBoundingClientRect()
    setSubMenuPosition({ top: top + event.target.offsetHeight, left })
    handleShowSubMenu(idx)()
  }

  const MenuButton = () => {
    return (
      nButtonLabel
        ? (
          <div className='button-box'>
            <Button className='btn-link' href={nButtonHref || '/'} target={nButtonTarget || '_self'}>{nButtonLabel}</Button>
          </div>
        )
        : null
    )
  }
  const SubMenu = (props) => {
    const { keyPrefix } = props
    const subMenu = nPanels[subMenuIdx]
    const isSubMenuValid = util.isObject(subMenu)
    const { sections } = isSubMenuValid ? subMenu : {}
    const pSections = Array.isArray(sections) ? sections : []
    const isSubMenuNotEmpty = pSections.length > 0
    const canShow = showSubMenu && isSubMenuValid && pSections && isSubMenuNotEmpty

    const subMenuStyle = {
      top: `${subMenuPosition.top}px`,
      left: `${subMenuPosition.left}px`,
      display: canShow ? 'block' : 'none'
    }

    return canShow || 1
      ? createPortal(
        <div className='sub-menu-bar' onMouseEnter={handleShowSubMenu(subMenuIdx)} onMouseLeave={handleHideSubMenu} style={subMenuStyle}>
          <div className='sub-menu'>
            <div className='menu'>
              {pSections.map((section) => {
                const { id, related_pages: _pages } = util.isObject(section) ? section : {}
                const pages = Array.isArray(_pages) ? _pages : []

                return (
                  <div className='item' key={`${keyPrefix}-${id}`}>
                    {pages.map((page, sidx) => {
                      const { id, href, label, target } = util.isObject(page) ? page : {}
                      const wlPathname = getLocationPathname()
                      const smIdx = sessionStorage.getItem('smIdx')
                      const isLinkActive = wlPathname === href ? true : wlPathname === href && `${smIdx}` === `${sidx}`

                      return (
                        <div className={clsx('link-box', isLinkActive ? 'active' : '')} key={`${keyPrefix}-${sidx}-${id}`}>
                          {href
                            ? <a href={href} onClick={handleClickSubMenuItem(subMenuIdx, sidx)} target={target || '_self'}>{label || ''}</a>
                            : <span>{label || ''}</span>}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        , document.body)
      : null
  }

  const handleClickMainMenuItem = (idx) => (e) => {
    e.preventDefault()
    const { target: eTarget } = e
    const { href, target } = eTarget
    sessionStorage.setItem('mmIdx', idx)
    sessionStorage.setItem('smIdx', -1)
    window.open(href, target)
  }

  const handleClickSubMenuItem = (idx, sidx) => (e) => {
    e.preventDefault()
    const { target: eTarget } = e
    const { href, target } = eTarget
    sessionStorage.setItem('mmIdx', idx)
    sessionStorage.setItem('smIdx', sidx)
    window.open(href, target)
  }

  const handleHideSubMenu = (event) => {
    const hoverTo = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;
    if (document.querySelector('.sub-menu-bar').contains(hoverTo)) return;

    const timer = setTimeout(() => {
      setShowSubMenu(false)
    }, 300)
    subMenuTimers.push(timer)
    setSubMenuTimers([].concat(subMenuTimers))
  }

  const handleShowSubMenu = (idx) => () => {
    for (let i = 0; i < subMenuTimers.length; i++) {
      clearTimeout(subMenuTimers[i])
    }

    setShowSubMenu(true)
    setSubMenuIdx(idx)
  }

  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const handleToggleMobileSubMenu = (idx) => () => {
    const { __expand: expand } = nPanels[idx]
    nPanels[idx].__expand = typeof expand === 'boolean' ? !expand : true
    navbar.panels = [].concat(nPanels)
    setNavbar({ ...navbar })
  }

  const isHideNavbar = () => {
    if (location.pathname === '/coming-soon') {
      if (query.get("tab") && query.get("tab") > 0) return true
    }

    return false
  }

  useEffect(() => {
    const { header: _header, navbar: _navbar } = props
    setHeader(util.isObject(_header) ? _header : {})
    setNavbar(util.isObject(_navbar) ? _navbar : {})
  }, [props])

  return (
    <div className={clsx('ax-header', className)}>
      <div className='top-bar'>
        <div className='buttons'>
          {hButtons.map((button, index) => {
            const { id, link } = util.isObject(button) ? button : {}
            const { href, label, target } = util.isObject(link) ? link : {}

            let buttonClass = 'button-box'

            //a dummy solution to when clicking Enterprise Commerce tab making it the active tab
            if (location.pathname === '/coming-soon') {
              if (query.get("tab")) {
                if (id == query.get("tab")) buttonClass += ' active'
              }
              else {
                if (index === 0) buttonClass += ' active'
              }
            } else {
              if (index === 0) buttonClass += ' active'
            }

            return (
              <div className={buttonClass} key={id}>
                <a href={href || `/coming-soon?tab=${id}`} target={target || '_self'}>{label || ''}</a>
              </div>
            )
          })}
        </div>

        <div className='icons'>
          {hIcons.map((icon) => {
            const { id, icon: _icon, label } = util.isObject(icon) ? icon : {}
            let iicon = Array.isArray(_icon) ? util.isObject(_icon[0]) ? _icon[0] : {} : {}
            iicon = typeof iicon === 'object' ? iicon : {}
            const { url: iconUrl } = iicon

            return (
              <div className='icon-box' key={id}>
                <a href={label || '/coming-soon'} target="_blank">
                  <Tooltip arrow placement='bottom' title={label || ''}>
                    <img className='img' alt={label || ''} src={process.env.REACT_APP_BASE_URL + iconUrl} />
                    {/* <img className='img' alt={label || ''} src={'http://axolot.oceannexus.my:1337' + iconUrl} /> */}
                  </Tooltip>
                </a>
              </div>
            )
          })}
        </div>
      </div>

      <div className='nav-bar'>
        <div className='menu-bar'>
          <div className='logo-box'>
            {
              !isHideNavbar() ? (
                <a href="/" className='img'>
                  <img alt='' src={nIconUrl ? process.env.REACT_APP_BASE_URL + nIconUrl : '/icon/logo.svg'} />
                </a>
              ) : (
                <img src={aecLogo} style={{ maxWidth: '58px' }} />
              )
            }
          </div>

          {
            !isHideNavbar() ? (
              <>
                <Menu keyPrefix='menu' />

                <MenuButton />

                <div className='logo-box mobile'>
                  <img className='img' alt='' src={showMobileMenu ? '/icon/close-nav-menu.svg' : '/icon/nav-menu.svg'} onClick={handleToggleMobileMenu} />
                </div>
              </>
            ) : null
          }
        </div>

        {
          !isHideNavbar() ? (
            <div className='menu-bar menu-bar-wrap'>
              <MenuButton />
            </div>
          ) : null
        }


        <SubMenu keyPrefix='sub-menu' subMenuPosition={subMenuPosition} />

        <div className={clsx('mobile-menu-bar', showMobileMenu ? 'expand' : '')}>
          {nPanels.map((panel, idx) => {
            const { id, Panel_Name: _panel, sections, __expand: expand } = util.isObject(panel) ? panel : {}
            const pPanel = util.isObject(_panel) ? _panel : {}
            const pSections = Array.isArray(sections) ? sections : []
            const { href, label, target } = pPanel
            const wlPathname = getLocationPathname()
            const mmIdx = sessionStorage.getItem('mmIdx')
            const smIdx = sessionStorage.getItem('smIdx')
            let isLinkActive = false

            if (typeof href === 'string' && href === wlPathname) {
              isLinkActive = true

              if (`${mmIdx}` === `${idx}`) {
                isLinkActive = true
              }
            } else if (typeof smIdx === 'string' && `${smIdx}` !== '-1') {
              const pSection = pSections[0]
              const section = util.isObject(pSection) ? pSection : {}
              const { related_pages: _pages } = util.isObject(section) ? section : {}
              const pages = Array.isArray(_pages) ? _pages : []
              const smIdxInt = parseInt(smIdx, 10)
              const page = util.isObject(pages[smIdxInt]) ? pages[smIdxInt] : {}
              const { href: pageHref } = page

              if (typeof pageHref === 'string' && pageHref === wlPathname) {
                isLinkActive = true
              } else if (href === wlPathname && `${mmIdx}` === `${idx}`) {
                isLinkActive = true
              } else {
                for (let i = 0; i < pages.length; i++) {
                  const page = util.isObject(pages[i]) ? pages[i] : {}
                  const { href: pageHref } = page

                  if (typeof pageHref === 'string' && pageHref === wlPathname) {
                    isLinkActive = true
                    break
                  }
                }
              }
            }

            return (
              <div className='mobile-menu' key={`mm-${idx}-${id}`}>
                <div className='menu'>
                  <div className='item'>
                    <div className={clsx('link-box', isLinkActive ? 'active' : '')}>
                      {href
                        ? <a href={href} onClick={handleClickMainMenuItem(idx)} target={target || '_self'}>{label || ''}</a>
                        : <span>{label || ''}</span>}

                      {pSections.length > 0
                        ? <img
                          className='img'
                          alt=''
                          src={expand ? '/icon/caret-up.svg' : '/icon/caret-down.svg'}
                          onClick={handleToggleMobileSubMenu(idx)}
                        />
                        : null}
                    </div>
                  </div>
                </div>

                {pSections.length > 0
                  ? (
                    <div className={clsx('sub-menu', expand ? 'expand' : '')}>
                      {pSections.map((section, idx) => {
                        const { id, related_pages: _pages } = util.isObject(section) ? section : {}
                        const pages = Array.isArray(_pages) ? _pages : []

                        return (
                          <div className='item' key={`i-${idx}-${id}`}>
                            {pages.map((page, sidx) => {
                              const { id, href, label, target } = util.isObject(page) ? page : {}
                              const wlPathname = getLocationPathname()
                              const smIdx = sessionStorage.getItem('smIdx')
                              const isLinkActive = wlPathname === href ? true : wlPathname === href && `${smIdx}` === `${sidx}`

                              return (
                                <div className={clsx('link-box', isLinkActive ? 'active' : '')} key={`lb-${sidx}-${id}`}>
                                  {href
                                    ? <a href={href} onClick={handleClickSubMenuItem(subMenuIdx, sidx)} target={target || '_self'}>{label || ''}</a>
                                    : <span>{label || ''}</span>}
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )
                  : null}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )

  function getLocationPathname() {
    const { location } = util.isObject(window) ? window : {}
    const { pathname } = util.isObject(location) ? location : {}
    return pathname
  }
}

AxHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  header: PropTypes.object,
  navbar: PropTypes.object
}

export default AxHeader
