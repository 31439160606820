import { Typography, Box, Grid, Stack, Button as MuiButton } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles";
import CustomTheme, { containerSpacing } from "../../helpers/theme";
import Title from "../../components/title"
import { useState, Fragment } from "react"
import useForm from "../../hooks/useForm"
import * as yup from "yup";
import { add, format as DateFormat } from "date-fns"
import { Button, TextField } from "../../components"
import { DateField, CheckField, RenderForm, SelectField, SliderField, validateForm } from "../../helpers/form"
import { Loop as LoopIcon } from "@mui/icons-material"
import { Fade } from "react-reveal"
import axios from "axios"
import useSnackbar from "../../hooks/useSnackbar";
import formatter from '../../util/formatter-v2'
import useLoadingEffect from '../../hooks/useLoadingEffect'

const Cancellation = () => {
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    subscription: "",
    cancelDate: null,
    cancelReason: [],
    otherCancelReason: "",
    recommend: 0,
  }
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [formValues, setFormValues] = useForm(initialFormState)
  const minCancelDate = add(new Date(), { days: 5 })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isConfirmSubmission, setIsConfirmSubmission] = useState(false)
  const [, showSnackbar, Snackbar] = useSnackbar()
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})

  const validationSchema = yup.object().shape({
    firstName: yup.string().required().label("First Name"),
    lastName: yup.string().required().label("Last Name"),
    email: yup.string().required().email().label("E-mail"),
    phoneNo: !formValues.phoneNo ? yup.string().notRequired() : yup.number().positive().integer("Telephone No. is not valid").label("Telephone No."),
    companyName: yup.string().required().label("Company Name or AIC e-commerce Domain Name or AIC Customer ID"),
    subscription: yup.string().required().label("AIC Subscription"),
    cancelDate: yup.date().min(DateFormat(minCancelDate, "yyyy-MM-dd")).label("Shutdown date"),
    cancelReason: yup.array().min(1).label("Cancel Reason"),
    otherCancelReason: formValues.cancelReason.includes("other") ? yup.string().required().label("Other reasons") : yup.string().notRequired(),
    recommend: yup.number().min(0).max(10).label("Recommendation")
  });
  const formFields = [
    {
      label: "First Name *",
      props: {
        name: "firstName",
        value: formValues.firstName,
        onChange: (event) => {
          setFormValues({ type: "firstName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Last Name *",
      props: {
        name: "lastName",
        value: formValues.lastName,
        onChange: (event) => {
          setFormValues({ type: "lastName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "E-mail *",
      props: {
        name: "email",
        type: "email",
        value: formValues.email,
        onChange: (event) => {
          setFormValues({ type: "email", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Telephone No.",
      props: {
        name: "phoneNo",
        type: "number",
        placeholder: "0121234567",
        value: formValues.phoneNo,
        onChange: (event) => {
          setFormValues({ type: "phoneNo", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Company Name or AIC e-commerce Domain Name or AIC Customer ID *",
      props: {
        name: "companyName",
        value: formValues.companyName,
        onChange: (event) => {
          setFormValues({ type: "companyName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: TextField
    },
    {
      label: "AIC Subscription *",
      props: {
        name: "subscription",
        value: formValues.subscription,
        options: [
          {
            label: "Select Subscription",
            value: "",
            disabled: true,
            selected: true,
          },
          {
            label: "AIC Free",
            value: "AIC Free"
          },
          {
            label: "AIC Web",
            value: "AIC Web"
          },
          {
            label: "AIC App",
            value: "AIC App"
          },
          {
            label: "AIC Complete",
            value: "AIC Complete"
          }
        ],
        onChange: (event) => {
          setFormValues({ type: "subscription", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: SelectField
    },
    {
      label: "When is your preferred shutdown date? * (minimum 5 working days from the date of cancellation request)",
      props: {
        name: "cancelDate",
        value: formValues.cancelDate,
        minDate: minCancelDate,
        onChange: (event) => {
          setFormValues({ type: "cancelDate", value: event })
        }
      },
      sizing: {
        xs: 12,
      },
      component: DateField,
    },
    {
      label: "Please let us know why you wish to cancel your AIC subscription. *",
      props: {
        name: "cancelReason",
        value: formValues.cancelReason,
        options: [
          {
            value: "I no longer wish to run e-commerce",
            label: "I no longer wish to run e-commerce",
          },
          {
            value: "I prefer another e-commerce platform",
            label: "I prefer another e-commerce platform",
          },
          {
            value: "I wish to build my own e-commerce platform from scratch",
            label: "I wish to build my own e-commerce platform from scratch",
          },
          {
            value: "I can no longer afford to run my e-commerce due to circumstances",
            label: "I can no longer afford to run my e-commerce due to circumstances",
          },
          {
            value: "AIC is difficult to use",
            label: "AIC is difficult to use",
          },
          {
            value: "AIC is too expensive",
            label: "AIC is too expensive",
          },
          {
            value: "other",
            label: "Other reasons",
          }
        ],
        onChange: (event) => {
          setFormValues({ type: "cancelReason", value: event })
        }
      },
      sizing: {
        xs: 12,
      },
      component: CheckField,
    },
    {
      hide: !formValues.cancelReason.includes('other'),
      props: {
        name: "otherCancelReason",
        value: formValues.otherCancelReason,
        placeholder: "Insert other cancel reason",
        onChange: (event) => {
          setFormValues({ type: "otherCancelReason", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: TextField
    },
    {
      label: "Would you recommend AIC to your family and friends? (0 – very unlikely to 10 – very likely)",
      props: {
        name: "recommend",
        value: formValues.recommend,
        marks: [
          {
            value: 0,
            label: "0"
          },
          {
            value: 10,
            label: "10"
          }
        ],
        steps: 1,
        max: 10,
        onChange: (event) => {
          setFormValues({ type: "recommend", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: SliderField
    },
  ]

  const confirmSubmission = async () => {
    const { isValid, errors } = await validateForm(validationSchema, formValues)

    if (!isValid) {
      setFormErrors(errors)
      showSnackbar("Please ensure all values are entered correctly.")
    }
    else {
      setFormErrors({})
      setIsConfirmSubmission(true)
      document.querySelector('#form').scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  const cancelSubmission = () => {
    setIsConfirmSubmission(false)
    document.querySelector('#form').scrollIntoView({
      behavior: "smooth"
    })
  }

  const completeSubmission = () => {
    //reset form states
    for (let key in initialFormState) {
      setFormValues({ type: key, value: initialFormState[key] })
    }

    setIsSubmitted(false)

    setTimeout(() => {
      document.querySelector('#form').scrollIntoView({
        behavior: "smooth"
      })
    }, 200)
  }

  const handleSubmit = async () => {
    const { isValid, errors } = await validateForm(validationSchema, formValues)

    if (!isValid) return setFormErrors(errors)
    else {
      setFormErrors({})
      setLoading(true)

      const cancelReason = [...formValues.cancelReason]

      if (formValues.cancelReason.findIndex(item => item === "other") !== -1) {
        cancelReason.splice(formValues.cancelReason.findIndex(item => item === "other"), 1)
        cancelReason.push(`Other - ${formValues.otherCancelReason}`)
      }

      const formValuesCopy = { ...formValues }
      delete formValuesCopy.otherCancelReason

      const data = {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        telephone_number: formValues.phoneNo,
        company_name: formValues.companyName,
        aic_subscription: formValues.subscription,
        shutdown_date: DateFormat(formValues.cancelDate, "yyyy-MM-dd"),
        reason_for_cancellation: cancelReason.join(", "),
        would_you_recommend_aic: `${formValues.recommend}`
      }

      try {
        const url = `${process.env.REACT_APP_BASE_URL}/cancellations`
        await axios.post(url, data)
        setLoading(false)
        setIsSubmitted(true)
        setIsConfirmSubmission(false)

        setTimeout(() => {
          document.querySelector('#cu-success-box').scrollIntoView({
            behavior: "smooth"
          })
        }, 100)
      } catch (error) {
        setLoading(false)
        showSnackbar("Please try again later")
        throw error
      }
    }
  }

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-cancellations`
    const { data } = await axios.get(url)
    setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-cancellation-slices.acknowledgement'))
    setComponents(data.slices.filter(item => item.__component !== 'page-cancellation-slices.acknowledgement'))
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-cancellation-slices.content':
        return (
          <>
            <Title data={{ first_partial_title: data.title, description: data.sub_title }} />

            <Box sx={{ lineHeight: 1.5, marginTop: 5, marginBottom: 5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.description) }} />
          </>
        )
      default:
        return null
    }
  }

  const renderSubmissionSuccessComponent = (data) => {
    return <Box id="cu-success-box" className='cu-success-box'>
      <Box className="image-box">
        <img src={`${process.env.REACT_APP_BASE_URL}${data?.image?.url}`} alt={data?.image?.alternativeText} />
      </Box>

      <Box className='title-box' sx={{ marginBottom: 5 }}>
        <Box className='title mb-3'>{data.first_partial_title} <span className='highlight'>{data.span}</span>{data.second_partial_title}</Box>
        <Box sx={{ lineHeight: 1.5, color: '#506A96' }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.description) }} />
      </Box>

      <Button onClick={completeSubmission} type="solid">
        {data.button_label}
      </Button>
    </Box>
  }

  useLoadingEffect(getData)

  return (
    <Box>
      {Snackbar}

      <ThemeProvider theme={CustomTheme}>
        <Fade>
          <Box sx={{ ...containerSpacing }}>
            {
              !isSubmitted ?
                <>
                  {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}

                  <Grid id="form" container>
                    <RenderForm disabled={isConfirmSubmission} fields={formFields.filter(item => !!!item.hide)} errors={formErrors} header={isConfirmSubmission ? <Typography variant="h4" ml="auto" mr="auto" mb={5}>Confirm Submission Details</Typography> : null} footer={
                      <Grid container direction="row" justifyContent="center" sx={{ marginTop: 5 }}>
                        {
                          !isConfirmSubmission ? <Grid item>
                            <Button onClick={confirmSubmission} type="solid">
                              Submit
                            </Button>
                          </Grid> :
                            <Stack direction={{ xs: "column", custom: "row" }} spacing={2}>
                              <Button disabled={loading} onClick={handleSubmit} type="solid">
                                Confirm{loading ? <LoopIcon className="cu-rotating" /> : null}
                              </Button>
                              <MuiButton disabled={loading} variant="outlined" onClick={cancelSubmission} color="secondary">
                                Cancel
                              </MuiButton>
                            </Stack>
                        }
                      </Grid>
                    } />
                  </Grid>
                </>
                :
                renderSubmissionSuccessComponent(submissionSuccessComponent)

            }
          </Box>
        </Fade>
      </ThemeProvider>
    </Box>
  )
}

export default Cancellation