import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Card, Typography } from '@mui/material'
import { util } from '../../util'

import './styles.css'

function AxLeftIconCard (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { decsription: text2, description: text1, icon, text, title } = data
  const { url: iconUrl } = icon || {}

  return (
    <Card className={clsx('ax-left-icon-card', className)}>
      <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + iconUrl} />
      {/* {iconUrl ? <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + iconUrl} /> : null} */}

      {text || text1 || title
        ? (
          <div className='text-box'>
            {title ? <Typography className='title'>{title}</Typography> : null}

            {text || text1 || text2 ? <Typography className='text'>{text || text1 || text2}</Typography> : null}
          </div>
          )
        : null}
    </Card>
  )
}

AxLeftIconCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftIconCard
