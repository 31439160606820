import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Link, Typography } from '@mui/material'
import { util } from '../../util'

import './styles.css'

function AxLeftImageFullWidth (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    description, image, link_href: linkHref, link_label: linkLabel, texts, title
  } = data
  const { url: imageUrl } = image || {}
  const list = Array.isArray(texts) ? texts : []

  return (
    <div className={clsx('ax-left-image-full-width', className)}>
      {imageUrl
        ? (
          <div className='image-box'>
            <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
            {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
          </div>
          )
        : null}

      <div className='text-box'>
        {title ? <Typography className='title'>{title}</Typography> : null}

        {description ? <Typography className='description'>{description}</Typography> : null}

        <div className='list'>
          {list.map((item) => {
            const { id, text } = item

            return (
              <div className='item' key={id}>
                <div className='image-box'>
                  <img className='img' alt='' src='/icon/tick-solid-blue.png' />
                </div>

                <div className='text'>{text}</div>
              </div>
            )
          })}

          {linkLabel || linkHref
            ? (
              <div style={{ marginRight: '67px' }}>
                <Link underline='none' href={linkHref}>{linkLabel || linkHref}</Link>
              </div>
              )
            : null}
        </div>
      </div>
    </div>
  )
}

AxLeftImageFullWidth.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftImageFullWidth
