import { useReducer } from "react"

export default function (initialState = {}) {
  const reducer = (state, { type, value }) => {
    if (type in initialState) {
      return {
        ...state,
        [type]: value
      }
    } else {
      throw new Error(`${type}: does not exist`)
    }
  }

  return useReducer(reducer, initialState)
}