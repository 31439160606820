import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Tab, Tabs } from '@mui/material'

import './styles.css'

function AxCustomTabs (props) {
  const { className, data } = props
  const tabs = Array.isArray(data) ? data : []
  const [currentTab, setCurrentTab] = React.useState(0)

  const changeTab = (e, value) => {
    setCurrentTab(value)
  }

  const handleScrollToTop = () => {
    setCurrentTab(0)
  }

  return (
    <div className={clsx('ax-custom-tabs', className)}>
      <Tabs
        centered
        onChange={changeTab}
        value={currentTab}
        TabIndicatorProps={{
          sx: { backgroundImage: 'linear-gradient(90.07deg, #4378DF 2.3%, #49F7D9 98.84%)', height: '5px' }
        }}
      >
        {tabs.map((tab) => {
          const { id, href, icon, target, title } = tab
          const { url: imageUrl } = icon || {}

          return (
            <Tab
              className='tab'
              key={id}
              href={href}
              icon={<img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />}
              // icon={<img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} />}
              label={title}
              target={target || '_self'}
            />
          )
        })}
      </Tabs>

      <div className='scroll-to-top'>
        <a className='link' href='#' onClick={handleScrollToTop}><img className='img' alt='' src='/icon/scroller.svg' /></a>
      </div>
    </div>
  )
}

AxCustomTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxCustomTabs
