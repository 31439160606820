import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { formatter, util } from '../../util'
import { Slide } from 'react-reveal'

import './styles.css'

function AxLeftNestedListBlock (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { block, blocks, main_tile: title1, title } = data
  const [currentIdx, setCurrentIdx] = useState(0)
  const items = Array.isArray(block) ? block : Array.isArray(blocks) ? blocks : []
  const { image } = items[currentIdx] || {}
  const { url: imageUrl } = image || {}

  const handleToggle = (idx) => () => {
    setCurrentIdx(idx)
  }

  return (
    <div className={clsx('ax-left-nested-list-block', className)}>
      <div className='text-box'>
        <Slide left>
          {title || title1 ? <Typography className='title'>{title || title1}</Typography> : null}

          {Array.isArray(items)
            ? (
              <div className='list-box'>
                {items.map((item, idx) => {
                  const { id, description: text1, text, title } = item

                  return (
                    <div className={clsx('item', idx === currentIdx ? 'open' : 'close')} key={id} onClick={handleToggle(idx)}>
                      <div className='number-box'>
                        <Typography className='number'>{`${idx + 1}`.padStart(2, '0')}</Typography>
                      </div>

                      <div className='text-box'>
                        <div className='top-box'>
                          <Typography className='title'>{title}</Typography>

                          {idx === currentIdx
                            ? <img className='img' alt='' src='/icon/arrow-up.svg' />
                            : <img className='img' alt='' src='/icon/arrow-down.svg' />}
                        </div>

                        {text || text1 ? <Typography className='text' dangerouslySetInnerHTML={{ __html: formatter.toHtml(text || text1) }} /> : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
            : null}
        </Slide>
      </div>

      <div className='image-box'>
        <Slide right>
          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
        </Slide>
        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
      </div>
    </div>
  )
}

AxLeftNestedListBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftNestedListBlock
