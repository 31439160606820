import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { formatter, util } from '../../util'

import './styles.css'

function AxTitle (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    description: subTitle, first_partia_title: startTitle1, first_partial_title: startTitle, span, second_partial_title: endTitle, title
  } = data

  return (
    <div className={clsx('ax-title', className)}>
      {startTitle || startTitle1 || span || endTitle || title
        ? (
          <Typography className='main'>
            {startTitle || startTitle1}{span ? <span className='span'>{span}</span> : null}{endTitle}{title}
          </Typography>
          )
        : null}

      {subTitle ? <Typography className='sub' dangerouslySetInnerHTML={{ __html: formatter.toHtml(subTitle) }} /> : null}
    </div>
  )
}

AxTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxTitle
