const util = {
  isComponent (componentName, toMatch) {
    toMatch = typeof toMatch === 'string' ? toMatch : ''
    const parts = toMatch.split('.')
    return componentName === parts[parts.length - 1]
  },
  isObject (obj) {
    return !Array.isArray(obj) && typeof obj === 'object'
  }
}
export default util
