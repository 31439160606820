import axios from 'axios'
import React, { useState } from 'react'

import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Button, CentreBanner, HeaderHero, LeftIconCard, Title } from '../../components'
import { ComponentTypes } from '../../constants'
import { formatter, util } from '../../util'

import { styled } from '@mui/material/styles'
import { Fade } from 'react-reveal'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import { Link } from 'react-router-dom'

import './styles.css'

const packages = {
  monthly: {
    web: '68',
    app: '388',
    complete: '588'
  },
  yearly: {
    web: '748',
    app: '4268',
    complete: '6468'
  }
}

const StyledToggleButton = styled(ToggleButton, {})(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #C6C5C5',
  borderRadius: '5px',
  color: '#234171',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '19px',
  maxWidth: '150px',
  padding: '12px 61px',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    backgroundColor: '#4276DB',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2760ce'
    }
  }
}))

function Pricing(props) {
  const [centreBanner, setCentreBanner] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [leftIconCard, setLeftIconCard] = useState()
  const [plan, setPlan] = useState('monthly')
  const [terms, setTerms] = useState()
  const [title, setTitle] = useState()
  const [expand1, setExpand1] = useState(true)
  const [expand2, setExpand2] = useState(true)
  const [expand3, setExpand3] = useState(true)
  const [expand4, setExpand4] = useState(true)
  const [productsPricing, setProductsPricing] = useState([])

  useLoadingEffect(async () => {
    const promises = [axios.get(`${process.env.REACT_APP_BASE_URL}/Page-Pricing/`), axios.get(`${process.env.REACT_APP_BASE_URL}/aic-packages`)]
    const [legacyApiResponse, productsPricingApiResponse] = await Promise.all(promises)

    handleLegacyApiResponse(legacyApiResponse)
    handleProductsPricingApiResponse(productsPricingApiResponse)
  })

  const handleLegacyApiResponse = (response) => {
    if (!Array.isArray(response) && typeof response === 'object') {
      const { data } = response
      const { slices } = data

      if (Array.isArray(slices)) {
        const _headerHero = {}
        let isCentreBannerSet = false
        let isLeftIconCardSet = false
        let isTermSet = false
        let isTitleSet = false

        for (let i = 0; i < slices.length; i++) {
          const slice = slices[i]
          const { __component } = slice

          if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
            const { breadcrumbs } = slice
            Object.assign(_headerHero, {
              breadCrumb: breadcrumbs
            })
          } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
            const {
              background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
            } = slice
            Object.assign(_headerHero, {
              currentHref,
              currentPage,
              subtitle: description,
              title
            })

            if (background && background.url) {
              const { url } = background
              Object.assign(_headerHero, {
                backgroundImage: url
              })
            }
          } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
            if (!isTitleSet) {
              const title = slice || {}
              isTitleSet = true
              setTitle(title)
            }
          } else if (util.isComponent(ComponentTypes.NOTE, __component)) {
            if (!isLeftIconCardSet) {
              const card = slice
              isLeftIconCardSet = true
              setLeftIconCard(card)
            }
          } else if (util.isComponent(ComponentTypes.TERMS, __component)) {
            if (!isTermSet) {
              const { text } = slice
              isTermSet = true
              setTerms(text)
            }
          } else if (util.isComponent(ComponentTypes.CENTER_BANNER2, __component)) {
            if (!isCentreBannerSet) {
              const centreBanner = slice || {}
              isCentreBannerSet = true
              setCentreBanner(centreBanner)
            }
          }
        }

        setHeaderHero(_headerHero)
      }
    }
  }

  const handleProductsPricingApiResponse = ({ data }) => {
    setProductsPricing(data)
  }

  const getMonthlyProducts = () => productsPricing.filter(item => item.subscription_type === 'Monthly')
  const getYearlyProducts = () => productsPricing.filter(item => item.subscription_type === 'Yearly')

  const getProductsBySubscriptionType = () => {
    switch (plan) {
      case 'monthly':
        return {
          free: productsPricing.find(item => item.id === 1),
          web: getMonthlyProducts().find(item => item.id === 2),
          complete: getMonthlyProducts().find(item => item.id === 4),
          app: getMonthlyProducts().find(item => item.id === 3)
        }
      case 'yearly':
        return {
          free: productsPricing.find(item => item.id === 1),
          web: getYearlyProducts().find(item => item.id === 5),
          complete: getYearlyProducts().find(item => item.id === 7),
          app: getYearlyProducts().find(item => item.id === 6)
        }
      default:
        return {}
    }
  }

  const renderPricingLargeComponent = ({ price, sub_name, id } = {}) => {
    const renderPricing = () => {
      if (price == null || price == undefined || parseFloat(price) < 0) {
        return (
          <div className='label'>-</div>
        )
      } else if (parseFloat(price) === 0) {
        return (
          <>
            <div className='label'><b>FREE</b><br />Forever</div>
            <div className='freeOneMonthPlaceHolder' />
          </>
        )
      } else {
        return (
          <>
            <div>RM <b>{price}</b><br /><PlanType /></div>
            <Free1Month description={sub_name} />
          </>
        )
      }
    }

    return (
      <>
        {renderPricing()}
        <Button className='btn-action' color='light blue' type='outline' href={`/pricing/subscribe?p=${id}`}>Subscribe</Button>
      </>

    )
  }

  const renderPriceText = (price) => {
    if (price == null || price == undefined || parseFloat(price) < 0) {
      return '-'
    } else if (parseFloat(price) === 0) {
      return 'Free'
    } else {
      return `RM ${price}`
    }
  }

  function ExcludeIcon() {
    return <CancelIcon style={{ color: '#ccc', height: 24, width: 24 }} />
  }

  function IncludeIcon() {
    return <CheckCircleIcon style={{ color: '#4276DB', height: 24, width: 24 }} />
  }

  function PlanType() {
    return <span style={{ textTransform: 'capitalize' }}>{plan}</span>
  }

  function Free1Month({ description }) {
    return description ? <div className='freeOneMonth'>{description}</div> : <div className='freeOneMonthPlaceHolder' />
  }

  const handleChange = (event, newPlan) => {
    if (newPlan !== null) setPlan(newPlan)
  }

  const handleExpand1 = () => {
    setExpand1(!expand1)
  }

  const handleExpand2 = () => {
    setExpand2(!expand2)
  }

  const handleExpand3 = () => {
    setExpand3(!expand3)
  }

  const handleExpand4 = () => {
    setExpand4(!expand4)
  }

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {title ? <div className='p-title1'><Title data={title} /></div> : null}

      <div className='content'>
        <div style={{ width: '100%', textAlign: 'center', margin: '33px 0 65px 0' }}>
          <ToggleButtonGroup
            color='primary'
            value={plan}
            exclusive
            onChange={handleChange}
            size='small'
          >
            <StyledToggleButton value='monthly' classes={{ selected: 'red' }}> Monthly </StyledToggleButton>
            <StyledToggleButton value='yearly'> Yearly </StyledToggleButton>
          </ToggleButtonGroup>
        </div>

        <Fade cascade down>
          <div className='plan'>
            <div className='row' style={{ 'gridTemplateColumns': '28% 72%' }}>
              <div className='col-image' style={{ backgroundImage: 'url(\'/img/graphic-91.png\')' }} />

              <div className='header'>
                <div className='header1'>
                  <div className='package'>AIC Free</div>

                  <div className='package'>AIC Web</div>

                  <div className='package' style={{ padding: 0 }}>
                    <div id="package-focus">
                      <div className='recommend'>AIC App</div>
                      <div className='popular'>Most Popular</div>
                      <div className='tri tri-left' />
                      <div className='tri tri-right' />
                    </div>
                  </div>

                  <div className='package'>AIC Complete</div>
                </div>

                <div className='header2'>
                  <div className='price'>
                    {renderPricingLargeComponent(getProductsBySubscriptionType()?.free)}
                  </div>

                  <div className='price'>
                    {
                      renderPricingLargeComponent(getProductsBySubscriptionType()?.web)
                    }
                  </div>

                  <div className='price col-highlight' style={{ marginTop: '-10px' }}>
                    {
                      renderPricingLargeComponent(getProductsBySubscriptionType()?.app)
                    }
                  </div>

                  <div className='price'>
                    {
                      renderPricingLargeComponent(getProductsBySubscriptionType()?.complete)
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-1'>Web Store</div>
              <div className='col'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>App Store</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>E-Voucher Feature</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>Store Credit</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>Promo Codes</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>Broadcast</div>
              <div className='col'>Web Only</div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>Report Feature</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>Number of User Accounts</div>
              <div className='col'>3</div>
              <div className='col'>10</div>
              <div className='col-highlight'>10</div>
              <div className='col'>Unlimited</div>
            </div>

            <div className='row'>
              <div className='col-1'>Inventory Locations****</div>
              <div className='col'>1</div>
              <div className='col'>3</div>
              <div className='col-highlight'>3</div>
              <div className='col'>5</div>
            </div>

            <div className='row'>
              <div className='col-1'>Number of SKU</div>
              <div className='col'>800</div>
              <div className='col'>3,000</div>
              <div className='col-highlight'>3,000</div>
              <div className='col'>Unlimited</div>
            </div>

            <div className='row'>
              <div className='col-1'>Number of Email Notifications per month</div>
              <div className='col'>-</div>
              <div className='col'>3,500</div>
              <div className='col-highlight'>3,500</div>
              <div className='col'>35,000</div>
            </div>

            <div className='row'>
              <div className='col-1'>Number of Storefront</div>
              <div className='col'>1</div>
              <div className='col'>Unlimited</div>
              <div className='col-highlight'>Unlimited</div>
              <div className='col'>Unlimited</div>
            </div>

            <div className='row'>
              <div className='col-1'>AIC Hosting**</div>
              <div className='col'><IncludeIcon /><br />Shared Domain</div>
              <div className='col'><IncludeIcon /><br />Own Domain</div>
              <div className='col-highlight'><IncludeIcon /><br />Own Domain</div>
              <div className='col'><IncludeIcon /><br />Own Domain</div>
            </div>

            <div className='row'>
              <div className='col-1'>axPay Payment Integration</div>
              <div className='col'><ExcludeIcon /><br />COD (Order creation without system payment process/offline payment), Optional Payment Gateway ***</div>
              <div className='col'><IncludeIcon /><br />FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
              <div className='col-highlight'><IncludeIcon /><br />FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
              <div className='col'><IncludeIcon /><br />FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
            </div>

            <div className='row'>
              <div className='col-1'>axolot AppCare</div>
              <div className='col'><ExcludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
              <div className='col-highlight'><IncludeIcon /></div>
              <div className='col'><IncludeIcon /></div>
            </div>

            <div className='row'>
              <div className='col-1'>axCelerate Setup & Launch</div>
              <div className='col'>Optional Add-On Service</div>
              <div className='col'>Optional Add-On Service</div>
              <div className='col-highlight'>Optional Add-On Service</div>
              <div className='col'>Optional Add-On Service</div>
            </div>

            <div className='row'>
              <div className='col-1'>Support</div>
              <div className='col'>-</div>
              <div className='col'>Standard</div>
              <div className='col-highlight'>Standard</div>
              <div className='col'>Standard</div>
            </div>

            <div className='row' style={{ 'gridTemplateColumns': '28% 18% 54%' }}>
              <div className='col-1'>Customizable</div>
              <div className='col'>-</div>
              <div className='col-customize'>Looking for something customised? <Link to="/contact" style={{ color: "#fff", textDecoration: "none", "&:visited": { color: "#fff" } }}>Talk to us</Link></div>
            </div>
          </div>

          <div className='plan mobile'>
            <div className='column'>
              <div className='top-box'>
                <div className='header-box'>
                  <div className='left-box'>
                    <div className='plan-name'>AIC Free</div>

                    <div className='plan-period'>Forever</div>
                  </div>

                  <div className='plan-price'>{renderPriceText(getProductsBySubscriptionType()?.free?.price)}</div>
                </div>

                <div className='button-box'>
                  <Button
                    className='btn-action'
                    color='light blue'
                    type='outline'
                    href={`/pricing/subscribe?p=${getProductsBySubscriptionType()?.free?.id}`}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>

              {expand1
                ? (
                  <div className='content-box'>
                    <div className='row'>
                      <div className='left-box'>Web Store</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>App Store</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>E-Voucher Feature</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Store Credit</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Promo Codes</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Broadcast</div>

                      <div className='value'>Web Only</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Report Feature</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of User Accounts</div>

                      <div className='value'>3</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Inventory Locations****</div>

                      <div className='value'>1</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of SKU</div>

                      <div className='value'>800</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of Email Notifications per month</div>

                      <div className='value'>-</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>AIC Hosting**</div>

                        <div className='subtitle'>Shared Domain</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axPay Payment Integration</div>

                        <div className='subtitle'>COD (Order creation without system payment process/ offline payment), Optional Payment Gateway ***</div>
                      </div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>axolot AppCare</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axCelerate Setup & Launch</div>

                        <div className='subtitle'>Optional Add-On Service</div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Support</div>

                      <div className='value'>-</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Customizable</div>

                      <div className='value'>-</div>
                    </div>
                  </div>
                )
                : null}

              <div className='bottom-box' onClick={handleExpand1}>
                <div>Expand Details</div>

                <div><img alt='' src={expand1 ? '/icon/caret-up-blue.svg' : '/icon/caret-down-blue.svg'} /></div>
              </div>
            </div>

            <div className='column'>
              <div className='top-box'>
                <div className='header-box'>
                  <div className='left-box'>
                    <div className='plan-name'>AIC Web</div>

                    <div className='plan-period'>{plan}</div>
                  </div>

                  <div className='plan-price'>{renderPriceText(getProductsBySubscriptionType()?.web?.price)}</div>
                </div>

                {plan === 'yearly'
                  ? <div className='extra-box'><span className='tag'>{getProductsBySubscriptionType()?.web?.sub_name}</span></div>
                  : null}

                <div className='button-box'>
                  <Button
                    className='btn-action'
                    color='light blue'
                    type='outline'
                    href={`/pricing/subscribe?p=${getProductsBySubscriptionType()?.web?.id}`}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>

              {expand2
                ? (
                  <div className='content-box'>
                    <div className='row'>
                      <div className='left-box'>Web Store</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>App Store</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>E-Voucher Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Store Credit</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Promo Codes</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Broadcast</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Report Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of User Accounts</div>

                      <div className='value'>10</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Inventory Locations</div>

                      <div className='value'>3</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of SKU</div>

                      <div className='value'>3,000</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of Email Notifications per month</div>

                      <div className='value'>3,500</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>AIC Hosting**</div>

                        <div className='subtitle'>Own Domain</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axPay Payment Integration</div>

                        <div className='subtitle'>FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>axolot AppCare</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axCelerate Setup & Launch</div>

                        <div className='subtitle'>Optional Add-On Service</div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Support</div>

                      <div className='value'>Standard</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Customizable</div>

                      <div className='value'>-</div>
                    </div>
                  </div>
                )
                : null}

              <div className='bottom-box' onClick={handleExpand2}>
                <div>Expand Details</div>

                <div><img alt='' src={expand2 ? '/icon/caret-up-blue.svg' : '/icon/caret-down-blue.svg'} /></div>
              </div>
            </div>

            <div className='column'>
              <div className='top-box highlight'>
                <div className='header-box'>
                  <div className='highlight-box'>
                    <div className='popular1'>Most Popular</div>

                    <div className='popular1 placeholder'>&nbsp;</div>

                    <div className='flex-box'>
                      <div className='left-box'>
                        <div className='plan-name'>AIC App</div>

                        <div className='plan-period'>{plan}</div>
                      </div>

                      <div className='plan-price'><div className='plan-price'>{renderPriceText(getProductsBySubscriptionType()?.app?.price)}</div></div>
                    </div>
                  </div>
                </div>

                {plan === 'yearly' ? <div className='extra-box'><span className='tag'>{getProductsBySubscriptionType()?.app?.sub_name}</span></div> : null}

                <div className='button-box'>
                  <Button
                    className='btn-action'
                    color='light blue'
                    type='outline'
                    href={`/pricing/subscribe?p=${getProductsBySubscriptionType()?.app?.id}`}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>

              {expand3
                ? (
                  <div className='content-box'>
                    <div className='row'>
                      <div className='left-box'>Web Store</div>

                      <ExcludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>App Store</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>E-Voucher Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Store Credit</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Promo Codes</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Broadcast</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Report Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of User Accounts</div>

                      <div className='value'>10</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Inventory Locations****</div>

                      <div className='value'>3</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of SKU</div>

                      <div className='value'>3,000</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of Email Notifications per month</div>

                      <div className='value'>3,500</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>AIC Hosting**</div>

                        <div className='subtitle'>Own Domain</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axPay Payment Integration</div>

                        <div className='subtitle'>FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>axolot AppCare</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axCelerate Setup & Launch</div>

                        <div className='subtitle'>Optional Add-On Service</div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Support</div>

                      <div className='value'>Standard</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Customizable</div>

                      <div className='value'>-</div>
                    </div>
                  </div>
                )
                : null}

              <div className='bottom-box' onClick={handleExpand3}>
                <div>Expand Details</div>

                <div><img alt='' src={expand3 ? '/icon/caret-up-blue.svg' : '/icon/caret-down-blue.svg'} /></div>
              </div>
            </div>

            <div className='column'>
              <div className='top-box'>
                <div className='header-box'>
                  <div className='left-box'>
                    <div className='plan-name'>AIC Complete</div>

                    <div className='plan-period'>{plan}</div>
                  </div>

                  <div className='plan-price'>{renderPriceText(getProductsBySubscriptionType()?.complete?.price)}</div>
                </div>

                {plan === 'yearly' ? <div className='extra-box'><span className='tag'>{getProductsBySubscriptionType()?.complete?.sub_name}</span></div> : null}

                <div className='button-box'>
                  <Button
                    className='btn-action'
                    color='light blue'
                    type='outline'
                    href={`/pricing/subscribe?p=${getProductsBySubscriptionType()?.complete?.id}`}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>

              {expand4
                ? (
                  <div className='content-box'>
                    <div className='row'>
                      <div className='left-box'>Web Store</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>App Store</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>E-Voucher Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Store Credit</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Promo Codes</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Broadcast</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Report Feature</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of User Accounts</div>

                      <div className='value'>Unlimited</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Inventory Locations****</div>

                      <div className='value'>5</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of SKU</div>

                      <div className='value'>Unlimited</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Number of Email Notifications per month</div>

                      <div className='value'>35,000</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>AIC Hosting**</div>

                        <div className='subtitle'>Own Domain</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axPay Payment Integration</div>

                        <div className='subtitle'>FPX, Credit and Debit cards, Maybank QRPay, Boost, Touch N' Go eWallet</div>
                      </div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>axolot AppCare</div>

                      <IncludeIcon />
                    </div>

                    <div className='row'>
                      <div className='left-box'>
                        <div>axCelerate Setup & Launch</div>

                        <div className='subtitle'>Optional Add-On Service</div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Support</div>

                      <div className='value'>Standard</div>
                    </div>

                    <div className='row'>
                      <div className='left-box'>Customizable</div>

                      <div className='value'>-</div>
                    </div>
                  </div>
                )
                : null}

              <div className='bottom-box' onClick={handleExpand4}>
                <div>Expand Details</div>

                <div><img alt='' src={expand4 ? '/icon/caret-up-blue.svg' : '/icon/caret-down-blue.svg'} /></div>
              </div>
            </div>
          </div>
        </Fade>

        {leftIconCard ? <div className='p-left-icon-card'><LeftIconCard data={leftIconCard} /></div> : null}

        {terms ? <div className='p-text'><div className='additional-note' dangerouslySetInnerHTML={{ __html: formatter.toHtml(terms) }} /></div> : null}
      </div>

      {centreBanner ? <div className='p-centre-banner'><CentreBanner data={centreBanner} /> </div> : null}
    </div>
  )
}

export default Pricing
