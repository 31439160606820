import { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatter } from '../../../../util'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&.Mui-expanded': {
    margin: theme.spacing(0)
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#487FED',
  color: '#fff',
  marginBottom: theme.spacing(2),
  borderRadius: '3px',
  '& .MuiSvgIcon-root': {
    color: '#fff',
    fontSize: '2rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(3),
    '&.Mui-expanded': {
      margin: theme.spacing(3),
    }
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    }
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(5),
  border: '1px solid #EBEBEB',
  marginBottom: theme.spacing(2),
  borderRadius: '3px',
}));

const FAQAccordian = ({ data = [] }) => {
  const [expanded, setExpanded] = useState('panel0');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    data.map(({ title, content }, index) => {
      return (
        <Accordion key={`panel${index}`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={{ __html: formatter.toHtml(content) }} />
          </AccordionDetails>
        </Accordion>
      )
    })
  )
}

export default FAQAccordian