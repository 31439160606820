const Subjects = {
  AIC_PRODUCT_AND_SERVICE: 'AIC Product and Service',
  REQUEST_FOR_DEMO: 'Request for Demo',
  REQUEST_FOR_CUSTOMISATION_ON_AIC: 'Request for customisation on AIC',
  AIC_SUBSCRIPTION_ACCOUNT: 'AIC Subscription / Account',
  AIC_TECHNICAL_SUPPORT: 'AIC Technical Support',
  ECOMMERCE_AXCELLENCE_PROGRAM: 'e-Commerce axCellence Program',
  AXOLOT_APPCARE: 'axolot AppCare',
  AXOLOT_HOSTING: 'axolot Hosting',
  AXOLOT_PAYMENT_INTEGRATION: 'axolot Payment Integration',
  AXCELERATE_SETUP_N_LAUNCH: 'axCelerate Setup & Launch',

  OTHERS: 'Others'
}
export default Subjects
export const SubjectsList = [
  { name: 'AIC Product and Service', value: Subjects.AIC_PRODUCT_AND_SERVICE },
  { name: 'Request for Demo', value: Subjects.REQUEST_FOR_DEMO },
  { name: 'Request for customisation on AIC', value: Subjects.REQUEST_FOR_CUSTOMISATION_ON_AIC },
  { name: 'AIC Subscription / Account', value: Subjects.AIC_SUBSCRIPTION_ACCOUNT },
  { name: 'AIC Technical Support', value: Subjects.AIC_TECHNICAL_SUPPORT },
  { name: 'e-Commerce axCellence Program', value: Subjects.ECOMMERCE_AXCELLENCE_PROGRAM },
  { name: 'axolot Appcare', value: Subjects.AXOLOT_APPCARE },
  { name: 'axolot Hosting', value: Subjects.AXOLOT_HOSTING },
  { name: 'axolot Payment Integration', value: Subjects.AXOLOT_PAYMENT_INTEGRATION },
  { name: 'axCelerate Setup & Launch', value: Subjects.AXCELERATE_SETUP_N_LAUNCH },
  { name: 'Others', value: Subjects.OTHERS }
]
