import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'

import './styles.css'

function AxDisclaimer (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { description, title } = data

  return (
    <div className={clsx('ax-disclaimer', className)}>
      {description || title
        ? (
          <Typography className='text'>
            {title ? <span className='title'>{title} </span> : null}{description}
          </Typography>
          )
        : null}
    </div>
  )
}

AxDisclaimer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxDisclaimer
