import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { formatter, util } from '../../util'
import { Slide } from 'react-reveal'

import './styles.css'

function AxRightContentBlock (props) {
  const { className, listIcon } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    button_href: buttonHref, button_label: buttonLabel, description, first_partial_title: startTitle, note,
    second_partial_title: endTitle, span, subtitle: subTitle1, sub_title: subTitle, text, texts, title
  } = data
  let { image } = data
  let items = text

  if (Array.isArray(image)) {
    image = image[0]
  }

  if (!Array.isArray(items)) {
    items = texts
  }

  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-right-content-block', className)}>
      <Slide left>
        <div className='image-box'>
          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
          {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
        </div>
      </Slide>

      <Slide right>
        <div className='text-box'>
          {title ? <Typography className='title'>{title}</Typography> : null}

          {startTitle || span || endTitle
            ? (
              <Typography className='title1'>
                {startTitle}{span ? <span className='span'>{span}</span> : null}{endTitle}{title}
              </Typography>
            )
            : null}

          {subTitle || subTitle1 ? <Typography className='subtitle'>{subTitle || subTitle1}</Typography> : null}

          {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

          {buttonLabel || buttonHref
            ? (
              <Button
                className='btn-action'
                color='light blue'
                href={buttonHref}
                sx={{ textTransform: 'capitalize' }}
                type='outline'
              >
                {buttonLabel || buttonHref}
              </Button>
            )
            : null}

          {Array.isArray(items)
            ? (
              <div className='list-box'>
                {items.map((item) => {
                  const { id, description, text } = item

                  return (
                    <div className='item' key={id}>
                      <div className='image-box'>
                        <img className='img' alt='' src={listIcon || '/icon/tick-solid-blue.png'} style={{ width: 24, height: 24 }} />
                      </div>

                      <div className='text-box'>
                        <Typography className='text'>{description || text}</Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
            : null}

          {note ? <Typography className='note'>{note}</Typography> : null}
        </div>
      </Slide>
    </div>
  )
}

AxRightContentBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxRightContentBlock
