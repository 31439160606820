import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button as MuiButton, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { util } from '../../util'

import './styles.css'

const BoxStyle = {
  borderRadius: '5px',
  color: '#4378DF',
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  minWidth: '150px',
  textAlign: 'center',
  backgroundColor: '#FDFEFF',
  boxShadow: 'none',
  padding: '16px',
  textTransform: 'capitalize',
}

const StyledBox = styled(Box, {})(({ theme }) => ({
  ...BoxStyle,
}))

const StyledButton = styled(MuiButton, {})(({ theme }) => ({
  ...BoxStyle,
  '&:hover': {
    backgroundColor: '#d6EAFF',
    color: '#2461D6',
    cursor: 'default'
  }
}))

function AxTitleWithButton2(props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    background_image: image, button_href: buttonHref, button_label: buttonLabel, description, first_partial_title: startTitle,
    second_partial_title: endTitle, span
  } = data
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-title-with-button2', className)} style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL + imageUrl})` }}>
      {/* <div className={clsx('ax-title-with-button2', className)} style={{ backgroundImage: `url(${'http://axolot.oceannexus.my:1337' + imageUrl})` }}> */}
      {startTitle || span || endTitle
        ? (
          <Typography className='title'>{startTitle}<span style={{ color: '#4378DF' }}>{span}</span>{endTitle}</Typography>
        )
        : null}

      {buttonLabel || buttonLabel
        ? (
          <div className='button-box'>
            <div className='line' />

            {buttonHref ? <StyledButton href={buttonHref}>{buttonLabel || buttonHref}</StyledButton> : <StyledBox>{buttonLabel}</StyledBox>}

            <div className='line' />
          </div>
        )
        : null}

      {description ? <Typography className='description'>{description}</Typography> : null}
    </div>
  )
}

AxTitleWithButton2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxTitleWithButton2
