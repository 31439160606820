import Title from '../../components/title'
import { Box } from '@mui/material'
import { useState, Fragment } from 'react'
import axios from 'axios'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import formatter from '../../util/formatter-v2'

const Requirements = () => {
  const [components, setComponents] = useState([])

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-requirements`
    const { data } = await axios.get(url)
    setComponents(data.slices)
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-requirements-slices.title':
        return <Title data={{ first_partial_title: data.title }} />
      case 'page-requirements-slices.content':
        return <Box sx={{ lineHeight: 1.5, marginTop: 5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.content) }} />
      default:
        return null;
    }
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{
        ...containerSpacing
      }}>
        {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
      </Box>
    </ThemeProvider >
  )
}

export default Requirements