import axios from 'axios'
import React, { useState } from 'react'

import { ColumnCardGrid2, HeaderHero, LeftContentBlock, RightContentBlock, Title, LogoWithDescription } from '../../../components'
import { ComponentTypes } from '../../../constants/component'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

import './styles.css'

function AICPartners (props) {
  const [sections, setSections] = useState()

  useLoadingEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Aic-Partners/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data
          setSections(slices)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  let imageSectionCount = 0;

  return (
    <div style={{marginBottom: '107px'}}>
      {
        sections && sections.map((section, index) => {
          switch(section.__component.split('.').pop()) {
            case ComponentTypes.HEADER_HERO1 :
              const _headerHero = {}
              const {
                background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title,
                bread_crumb_links: breadcrumbs,
              } = section
              Object.assign(_headerHero, {
                currentHref,
                currentPage,
                subtitle: description,
                title
              })

              if (background && background.url) {
                const { url } = background
                Object.assign(_headerHero, {
                  backgroundImage: url
                })
              }

              if (breadcrumbs) {
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              }

              return <HeaderHero {..._headerHero} key={section.id} />
            case ComponentTypes.TITLE : {
              const { title } = section || {}
              return (
                <div className='po-title'><Title data={title} key={section.id} /></div>
              )
            }
            case ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD2 : {
              const { cards } = section || {}
              return (
                <div className='snr-image-right-title-description-card1'><ColumnCardGrid2 data={cards} key={section.id}/></div>
              )
            }
            case ComponentTypes.IMAGE_TITLE_TEXT: {
              imageSectionCount++ 

              if (imageSectionCount % 2 === 1) {
                return (
                  <div className='po-left-right-content-block'><LeftContentBlock key={section.id} data={section} /></div>
                )
              } else {
                return (
                  <div className='po-left-right-content-block'><RightContentBlock key={section.id} data={section} /></div>
                )
              }
            }
            case ComponentTypes.LOGO_WITH_DESCRIPTION: {

              return (
                <div className='po-logos'><LogoWithDescription key={section.id} data={section}/></div>
              )
            }
          }
        })
      }
    </div>
  )
}

export default AICPartners
