import axios from 'axios'
import clsx from 'clsx'
import React, { useState } from 'react'

import { Typography } from '@mui/material'
import { HeaderHero, LeftRightContentBlock, Title, TwoColumnFullWidth } from '../../../components'
import { ComponentTypes } from '../../../constants'
import { formatter, util } from '../../../util'
import { Fade } from 'react-reveal'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function MobileCommerce (props) {
  const [cardBlocks, setCardBlocks] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [text1, setText1] = useState()
  const [text2, setText2] = useState()
  const [title, setTitle] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Solutions-Mobile-e-Commerce/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isCardBlocksSet = false
            let isLeftRightContentBlock = false
            let isText1Set = false
            let isText2Set = false
            let isTitleSet = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrums: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent('description', __component)) {
                if (!isText1Set) {
                  const { description: text } = slice || {}
                  isText1Set = true
                  setText1(text)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK11, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD1, __component)) {
                if (!isCardBlocksSet) {
                  const { card: blocks } = slice || {}
                  isCardBlocksSet = true
                  setCardBlocks(blocks)
                }
              } else if (util.isComponent('text', __component)) {
                if (!isText2Set) {
                  const { dsecription: text } = slice || {}
                  isText2Set = true
                  setText2(text)
                }
              } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
                if (!isTitleSet) {
                  const title = slice || {}
                  isTitleSet = true
                  setTitle(title)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH5, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock
        ? (
          <div className='snmc-left-right-content-block'>
            <LeftRightContentBlock data={leftRightContentBlock} reverse />
          </div>
          )
        : null}

      {text1 ? <div className='snmc-text1'><Typography className='text'>{text1}</Typography></div> : null}

      {cardBlocks
        ? (
          <div className='snmc-card-blocks'>
            {Array.isArray(cardBlocks)
              ? (
                <div className='ax-card-blocks'>
                  <Fade cascade up>
                    {cardBlocks.map((block, idx) => {
                      const { id, description, image, title } = block
                      const { url: imageUrl } = image || {}
                      const TextBox = (props) => {
                        const { className } = props

                        return (
                          <div className={clsx('text-box', className)}>
                            {title ? <Typography className='title'>{title}</Typography> : null}

                            {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}
                          </div>
                        )
                      }

                      return (
                        <div className='item' key={id}>
                          {idx % 2 !== 0 ? <TextBox className='odd' /> : null}

                          {imageUrl
                            ? (
                              <div className='image-box'>
                                <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                                {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                              </div>
                              )
                            : null}

                          {idx % 2 === 0 ? <TextBox className='even' /> : null}
                        </div>
                      )
                    })}
                  </Fade>
                </div>
                )
              : null}
          </div>
          )
        : null}

      {title ? <div className='snmc-title'><Title data={title} /></div> : null}

      {text2 ? <Fade><div className='snmc-text2'><Typography className='text' dangerouslySetInnerHTML={{ __html: formatter.toHtml(text2) }} /></div></Fade> : null}

      {twoColumnFullWidth ? <div className='snmc-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default MobileCommerce
