import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { formatter } from '../../util'
import { Fade } from 'react-reveal'
import { getReactRevealXStyles } from '../../util/react-reveal-styles'

import './styles.css'

function AxTwoColumnFullWidth (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <div className={clsx('ax-two-column-full-width', className)}>
      <div className='overlay'>
        <div className='text'>OR</div>
      </div>

      <div className='card-box'>
        {columnCards.map((card, idx) => {
          const {
            id, background_href: backgroundImage1, background_image: backgroundImage, button_href: buttonHref,
            button_label: buttonLabel, description, first_partial_description: startTitle, icon,
            second_partial_description: endTitle, span
          } = card
          let image = backgroundImage

          if (Array.isArray(backgroundImage)) {
            image = backgroundImage[0]
          }

          if (Array.isArray(backgroundImage1)) {
            image = backgroundImage1[0]
          }

          const { url: iconUrl } = icon || {}
          const { url: imageUrl } = image || {}

          return (
            <Fade {...getReactRevealXStyles(idx)}>
              <div
                className='card'
                key={id}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_BASE_URL + imageUrl})`,
                  // backgroundImage: `url(${'http://axolot.oceannexus.my:1337' + imageUrl})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className={clsx('box', idx % 2 === 0 ? 'even' : 'odd')}>
                  <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + iconUrl} />
                  {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + iconUrl} /> */}

                  {startTitle || span || endTitle
                    ? (
                      <Typography className='description'>
                        {startTitle}{span ? <span className='span'>{span}</span> : null}{endTitle}
                      </Typography>
                    )
                    : null}

                  {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

                  {buttonLabel || buttonHref
                    ? <Button className='btn-action' href={buttonHref}>{buttonLabel || buttonHref}</Button>
                    : null}
                </div>
              </div>
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

AxTwoColumnFullWidth.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxTwoColumnFullWidth
