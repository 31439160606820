import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Fade } from 'react-reveal'

import './styles.css'

function AxImageCardGrid (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <Fade cascade>
      <div className={clsx('ax-image-card-grid', className)}>
        {columnCards.map((card) => {
          const { id, title } = card
          let { image } = card

          if (Array.isArray(image)) {
            image = image[0]
          }

          const { url: imageUrl } = image || {}

          return (
            <div className='item' key={id}>
              {imageUrl
                ? (
                  <div className='image-box'>
                    <img className='img' alt={title} src={process.env.REACT_APP_BASE_URL + imageUrl} />
                    {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                  </div>
                )
                : null}
            </div>
          )
        })}
      </div>
    </Fade>
  )
}

AxImageCardGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxImageCardGrid
