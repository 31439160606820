import axios from 'axios'
import BigNumber from 'bignumber.js'
import React, { useState } from 'react'

import { Loop as LoopIcon } from '@mui/icons-material'
import {
  Alert, Checkbox, FormControl, FormGroup, FormHelperText, FormLabel, FormControlLabel as MuiFormControlLabel, MenuItem,
  Radio, RadioGroup, Snackbar, ToggleButton, ToggleButtonGroup, Typography, Box
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Button, ColumnCardGrid, FormControlLabel, HeaderHero, ImageTitleListTwoColumnCard, LeftImageFullWidth, LeftTitleTwoCard,
  Select, TextField, Title
} from '../../components'
import { ComponentTypes, IndustriesList, StatesList } from '../../constants'
import { formatter, util, validator } from '../../util'
import FormatterV2 from '../../util/formatter-v2'
import { Slide, Fade } from 'react-reveal'
import useLoadingEffect from '../../hooks/useLoadingEffect'

import './styles.css'

const StyledToggleButton = styled(ToggleButton, {})(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #C6C5C5',
  borderRadius: '5px',
  color: '#234171',
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '24px',
  maxWidth: '250px',
  padding: '16px 61px',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    backgroundColor: '#4378DF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2461d6'
    }
  }
}))
const FormTypes = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
}

function Community(props) {
  const [agreeTncs, setAgreeTncs] = useState([false, false, false])
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [formErrors, setFormErrors] = useState({})
  const [formMessages, setFormMessages] = useState({})
  const [formValues, setFormValues] = useState({})
  const [formType, setFormType] = useState(FormTypes.INDIVIDUAL)
  const [headerHero, setHeaderHero] = useState({})
  const [imageTitleListTwoColumnCard, setImageTitleListTwoColumnCard] = useState()
  const [leftImageFullWidth, setLeftImageFullWidth] = useState()
  const [leftTitleTwoCard, setLeftTitleTwoCard] = useState()
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [stepsBlock, setStepsBlock] = useState(false)
  const [threeColumnCard, setThreeColumnCard] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [title3, setTitle3] = useState()
  const [title4, setTitle4] = useState()
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})
  const { onlinePlatform: fvOnlinePlatform } = formValues
  const showOtherOnlinePlatform = Array.isArray(fvOnlinePlatform) && fvOnlinePlatform.indexOf('Others') > -1

  const changeAgreeTnc = (idx) => (e, value) => {
    agreeTncs[idx] = value
    setAgreeTncs(agreeTncs)
    setDisableSubmit(agreeTncs.filter((agreeTnc) => agreeTnc === false).length > 0)
  }

  const changeFormValue = (e) => {
    const { target } = e || {}
    const { name, value } = target || {}
    const error = validateFormValue(name, value)

    if (name === 'soldOnlineBefore' && value === 'No') {
      delete formValues.onlinePlatform
      delete formValues.otherOnlinePlatform
      clearFormError('onlinePlatform')
      clearFormError('otherOnlinePlatform')
    }

    setFormValues({ ...formValues, [name]: value })

    if (error) {
      setFormErrors({ ...formErrors, [name]: true })
      setFormMessages({ ...formMessages, [name]: error })
    } else {
      clearFormError(name)
    }
  }

  const changeFormType = (e, value) => {
    if (value !== null) {
      setFormErrors({})
      setFormMessages({})
      setFormValues({})
      setFormType(value)
    }
  }

  const changeOnlinePlatform = (onlinePlatform) => (e, value) => {
    let { onlinePlatform: fvOnlinePlatform } = formValues
    fvOnlinePlatform = Array.isArray(fvOnlinePlatform) ? fvOnlinePlatform : []

    if (value) {
      fvOnlinePlatform.push(onlinePlatform)
    } else {
      if (onlinePlatform === 'Others') {
        delete formValues.otherOnlinePlatform
        clearFormError('otherOnlinePlatform')
      }

      fvOnlinePlatform = fvOnlinePlatform.filter((item) => item !== onlinePlatform)
    }

    formValues.onlinePlatform = fvOnlinePlatform
    const error = validateFormValue('onlinePlatform', fvOnlinePlatform)
    setFormValues(formValues)

    if (error) {
      setFormErrors({ ...formErrors, onlinePlatform: true })
      setFormMessages({ ...formMessages, onlinePlatform: error })
    } else {
      clearFormError('onlinePlatform')
      clearFormError('otherOnlinePlatform')
    }
  }

  const handleHideError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowError(false)
  }

  const handleShowError = () => {
    setShowError(true)
  }

  const handleSubmit = () => {
    if (validateFormValues()) {
      handleShowError()
      return
    }

    setLoading(true)
    const {
      bankruptcyStatus, companyAddress, companyPostcode, companyState, companyName, companyRegNo, contactEmail, contactName,
      contactNric, contactPosition, email, fullName, icNo, industry, mailAddress, mailPostcode, mailState, mobileNo, moreDetail,
      numOfWorkers, offering, offeringDetail, onlinePlatform, otherOnlinePlatform, overseasOperation,
      phoneNo, soldOnlineBefore, yearOperating
    } = formValues
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${formType === FormTypes.INDIVIDUAL ? 'individuals' : 'companies'}`,
        formType === FormTypes.INDIVIDUAL
          ? {
            first_name: fullName,
            nric: BigNumber(icNo).toNumber(),
            email,
            mobile_number: mobileNo,
            mailing_address: mailAddress,
            postcode: BigNumber(mailPostcode).toNumber(),
            state: mailState,
            business_industry: industry,
            bankruptcy_status: bankruptcyStatus,
            offer: offering,
            elaborate_on_your_offering: offeringDetail,
            amount_of_workers: numOfWorkers,
            online_sales: soldOnlineBefore === 'Yes',
            online_platform: otherOnlinePlatform ? `${onlinePlatform}`.replace('Others', `Others - ${otherOnlinePlatform}`) : `${onlinePlatform}`,
            tell_us_about_yourself: moreDetail
          }
          : {
            company_name: companyName,
            registration_number: BigNumber(companyRegNo).toNumber(),
            contact_person_name: contactName,
            contact_person_nric: BigNumber(contactNric).toNumber(),
            contact_person_position: contactPosition,
            contact_person_email: contactEmail,
            phone_number: BigNumber(phoneNo).toNumber(),
            company_address: companyAddress,
            postcode: companyPostcode,
            state: companyState,
            business_industry: industry,
            bankruptcy_status: bankruptcyStatus === 'Yes',
            offer: offering,
            elaborate_on_your_offering: offeringDetail,
            amount_of_workers: numOfWorkers,
            company_age: yearOperating,
            branch_in_malaysia: overseasOperation,
            online_sales: soldOnlineBefore === 'Yes',
            online_platform: otherOnlinePlatform ? `${onlinePlatform}`.replace('Others', `Others - ${otherOnlinePlatform}`) : `${onlinePlatform}`,
            tell_us_about_yourself: moreDetail
          }
      )
      .then(() => {
        setLoading(false)
        setShowSuccess(true)
      })
      .catch((err) => {
        setLoading(false)

        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(err.response)
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error(err.message)
        }

        handleShowError()
      })
  }

  const isOnlinePlatformChecked = (name) => {
    const { onlinePlatform: fvOnlinePlatform } = formValues
    const onlinePlatforms = Array.isArray(fvOnlinePlatform) ? fvOnlinePlatform : []
    return onlinePlatforms.indexOf(name) > -1
  }

  const isOnlinePlatformDisabled = () => {
    return formValues.soldOnlineBefore === 'No'
  }

  const renderStepsBlock = () => {
    const { image_text: steps } = stepsBlock

    if (Array.isArray(steps) && steps.length > 0) {
      return (
        <Fade cascade down>
          <div className='ax-steps-block'>
            {steps.map((step, idx) => {
              const { description, image } = step
              const { url: imageUrl } = image || {}

              return (
                <div className='step' key={idx}>
                  {imageUrl
                    ? (
                      <div className='image-box'>
                        <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                      </div>
                    )
                    : null}

                  {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}
                </div>
              )
            })}
          </div>
        </Fade>
      )
    }
  }

  const renderSubmissionSuccessComponent = (data) => {
    return (
      <Box className='cu-success-box'>
        <Box className='image-box'><img className='img-success' alt='' src={`${process.env.REACT_APP_BASE_URL}${data.image?.url}`} /></Box>

        <Box className='title-box'>
          <Box className='title'>{data.first_partial_title} <span className='highlight'>{data.span}</span>.</Box>

          <Box className='subtitle' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(data.description) }}></Box>
        </Box>
      </Box>
    )
  }

  const imageBannerSection = ({ image }) => {
    return (
      <div className='c-img-join-banner'>
        <Fade>
          <img
            className='hp-img-join'
            src={image}
          />
        </Fade>
      </div>
    )
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-community-slices.ambassador':
        return imageBannerSection({
          image: `${process.env.REACT_APP_BASE_URL}${data?.image?.url}`
        })
      default:
        return null;
    }
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Community/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-community-slices.acknowledgement'))
          setComponents(data.slices.filter(item => item.__component !== 'page-community-slices.acknowledgement'))

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isImageTitleListTwoColumnCard = false
            let isLeftImageFullWidthSet = false
            let isLeftTitleTwoCard = false
            let isNoteSet = false
            let isStepsBlockSet = false
            let isThreeColumnCardSet = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTitle3Set = false
            let isTitle4Set = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.COLUMN_CARDS3, __component)) {
                if (!isThreeColumnCardSet) {
                  const { cards: columnCards } = slice || {}
                  isThreeColumnCardSet = true
                  setThreeColumnCard(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_TITLE_LIST_TWO_COLUMN_CARD, __component)) {
                if (!isImageTitleListTwoColumnCard) {
                  const { cards } = slice || {}
                  isImageTitleListTwoColumnCard = true
                  setImageTitleListTwoColumnCard(cards)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_IMAGE_FULL_WIDTH, __component)) {
                if (!isLeftImageFullWidthSet) {
                  const data = slice || {}
                  isLeftImageFullWidthSet = true
                  setLeftImageFullWidth(data)
                }
              } else if (util.isComponent('image-text', __component)) {
                if (!isStepsBlockSet) {
                  const stepsBlock = slice || {}
                  isStepsBlockSet = true
                  setStepsBlock(stepsBlock)
                }
              } else if (util.isComponent(ComponentTypes.NOTE, __component)) {
                if (!isNoteSet) {
                  const { text: note } = slice || {}
                  isNoteSet = true
                  setNote(note)
                }
              } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
                if (!isTitle1Set) {
                  const { title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE, __component)) {
                if (!isTitle2Set) {
                  const title = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.THIRD_TITLE, __component)) {
                if (!isTitle3Set) {
                  const title = slice || {}
                  isTitle3Set = true
                  setTitle3(title)
                }
              } else if (util.isComponent(ComponentTypes.FOURTH_TITLE, __component)) {
                if (!isTitle4Set) {
                  const title = slice || {}
                  isTitle4Set = true
                  setTitle4(title)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_TITLE_TWO_CARD, __component)) {
                if (!isLeftTitleTwoCard) {
                  const card = slice || {}
                  isLeftTitleTwoCard = true
                  setLeftTitleTwoCard(card)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleHideError}
        open={showError}
      >
        <Alert onClose={handleHideError} severity='error' sx={{ width: '100%' }}>
          Unable to submit successfully. {hasFormErrors() ? 'Please ensure all values are entered correctly.' : 'Please try again later.'}
        </Alert>
      </Snackbar>

      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {!!components.find(item => item.__component === 'page-community-slices.ambassador') && renderComponent(components.find(item => item.__component === 'page-community-slices.ambassador'))}

      {leftTitleTwoCard ? <div className='c-left-title-two-card'><LeftTitleTwoCard data={leftTitleTwoCard} /></div> : null}

      {title1 || imageTitleListTwoColumnCard
        ? (
          <div className='c-title-image-title-list'>
            {title1 ? <div className='title'><Title data={title1} /></div> : null}

            {imageTitleListTwoColumnCard
              ? <div className='image-title-list-two-column-card'><ImageTitleListTwoColumnCard data={imageTitleListTwoColumnCard} /></div>
              : null}
          </div>
        )
        : null}

      {title2 ? <div className='c-title2'><Title data={title2} /></div> : null}

      {threeColumnCard ? <div className='c-column-card-grid'><ColumnCardGrid data={threeColumnCard} tick='/icon/tick-solid-blue.png' /></div> : null}

      {note ? <div className='c-note'><Typography className='text'>{note}</Typography></div> : null}

      {LeftImageFullWidth ? <Slide left><div className='c-left-image-full-width'><LeftImageFullWidth data={leftImageFullWidth} /></div></Slide> : null}

      {title3 || stepsBlock
        ? (
          <div className='c-title3-box'>
            {title3 ? <div className='c-title3'><Title data={title3} /></div> : null}

            {stepsBlock ? <div className='c-steps-block'>{renderStepsBlock()}</div> : null}
          </div>
        )
        : null}

      {title4 ? <div className='c-title4'><Title data={title4} /></div> : null}

      <div className='c-select-form-type'>
        <ToggleButtonGroup
          className='toggle-button-group'
          exclusive
          onChange={changeFormType}
          value={formType}
        >
          <StyledToggleButton value={FormTypes.INDIVIDUAL}>
            <img className='img' alt='' src={`/icon/individual${formType === FormTypes.INDIVIDUAL ? '2' : '1'}.svg`} />

            <span>Individual</span>
          </StyledToggleButton>

          <StyledToggleButton value={FormTypes.COMPANY}>
            <img className='img' alt='' src={`/icon/company${formType === FormTypes.COMPANY ? '2' : '1'}.svg`} />

            <span>Company</span>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </div>

      {showSuccess
        ? (
          <Fade>
            {renderSubmissionSuccessComponent(submissionSuccessComponent)}
          </Fade>
        )
        : (
          <>
            <div className='c-box'>
              {formType === FormTypes.INDIVIDUAL
                ? (
                  <>
                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.fullName}
                            helperText={formMessages.fullName}
                            name='fullName'
                            onChange={changeFormValue}
                            placeholder='Enter Your Name'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.fullName || ''}
                            variant='outlined'
                          />
                        }
                        label='Full Name (as per NRIC) *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.icNo}
                            helperText={formMessages.icNo}
                            name='icNo'
                            onChange={changeFormValue}
                            placeholder='Enter Your IC Number'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.icNo || ''}
                            variant='outlined'
                          />
                        }
                        label='NRIC *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.email}
                            helperText={formMessages.email}
                            name='email'
                            onChange={changeFormValue}
                            placeholder='Enter Your E-mail'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.email || ''}
                            variant='outlined'
                          />
                        }
                        label='E-mail *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.mobileNo}
                            helperText={formMessages.mobileNo}
                            name='mobileNo'
                            onChange={changeFormValue}
                            placeholder='e.g. 0123456789'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.mobileNo || ''}
                            variant='outlined'
                          />
                        }
                        label='Mobile Phone *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.mailAddress}
                            helperText={formMessages.mailAddress}
                            name='mailAddress'
                            onChange={changeFormValue}
                            placeholder='Enter Your Mail Address'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.mailAddress || ''}
                            variant='outlined'
                          />
                        }
                        label='Mailing Address *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <>
                            {formMessages.mailState ? <FormHelperText error>{formMessages.mailState}</FormHelperText> : null}

                            <Select
                              displayEmpty
                              name='mailState'
                              onChange={changeFormValue}
                              renderValue={(selected) => {
                                if (selected) {
                                  return selected
                                }

                                return <span style={{ color: '#808080' }}>Choose Your State</span>
                              }}
                              required
                              sx={{ minWidth: '100%' }}
                              value={formValues.mailState || ''}
                            >
                              {StatesList.map(({ name, value }, idx) => <MenuItem key={`${idx}-${value}`} value={value}>{name}</MenuItem>)}
                            </Select>
                          </>
                        }
                        label='State *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.mailPostcode}
                            helperText={formMessages.mailPostcode}
                            name='mailPostcode'
                            onChange={changeFormValue}
                            placeholder='Enter Your Postcode'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.mailPostcode || ''}
                            variant='outlined'
                          />
                        }
                        label='Postcode *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <>
                            {formMessages.industry ? <FormHelperText error>{formMessages.industry}</FormHelperText> : null}

                            <Select
                              displayEmpty
                              name='industry'
                              onChange={changeFormValue}
                              renderValue={(selected) => {
                                if (selected) {
                                  return selected
                                }

                                return (
                                  <span style={{ color: '#808080' }}>
                                    {formType === FormTypes.INDIVIDUAL ? 'Choose Your Industry' : 'Choose Your Business Industry'}
                                  </span>
                                )
                              }}
                              required
                              sx={{ minWidth: '100%' }}
                              value={formValues.industry || ''}
                            >
                              {IndustriesList.map(({ category, name, value }, idx) => (
                                <MenuItem key={`${idx}-${value}`} value={value}>
                                  <span style={{ fontWeight: 'bold' }}>{category}</span>&nbsp;-&nbsp;{name}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        }
                        label='Business Industry *'
                        sx={{ width: '100%' }}
                      />
                    </div>
                  </>
                )
                : (
                  <>
                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.companyName}
                            helperText={formMessages.companyName}
                            name='companyName'
                            onChange={changeFormValue}
                            placeholder='Enter Your Company Name'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.companyName || ''}
                            variant='outlined'
                          />
                        }
                        label='Company Name (as per SSM) *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.companyRegNo}
                            helperText={formMessages.companyRegNo}
                            name='companyRegNo'
                            onChange={changeFormValue}
                            placeholder='Enter Your Company Registration No.'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.companyRegNo || ''}
                            variant='outlined'
                          />
                        }
                        label='Company Registration No. *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.contactName}
                            helperText={formMessages.contactName}
                            name='contactName'
                            onChange={changeFormValue}
                            placeholder='Enter Your Name'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.contactName || ''}
                            variant='outlined'
                          />
                        }
                        label="Contact Person's Full Name (as per NRIC) *"
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.contactNric}
                            helperText={formMessages.contactNric}
                            name='contactNric'
                            onChange={changeFormValue}
                            placeholder='Enter Contact Person’s NRIC'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.contactNric || ''}
                            variant='outlined'
                          />
                        }
                        label="Contact Person's NRIC *"
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.contactPosition}
                            helperText={formMessages.contactPosition}
                            name='contactPosition'
                            onChange={changeFormValue}
                            placeholder='Enter Your Position'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.contactPosition || ''}
                            variant='outlined'
                          />
                        }
                        label="Contact Person's Postion *"
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.contactEmail}
                            helperText={formMessages.contactEmail}
                            name='contactEmail'
                            onChange={changeFormValue}
                            placeholder='Enter Your E-mail'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.contactEmail || ''}
                            variant='outlined'
                          />
                        }
                        label="Contact Person's E-mail *"
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.phoneNo}
                            helperText={formMessages.phoneNo}
                            name='phoneNo'
                            onChange={changeFormValue}
                            placeholder='Enter Your Phone Number'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.phoneNo || ''}
                            variant='outlined'
                          />
                        }
                        label='Phone Number *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.companyAddress}
                            helperText={formMessages.companyAddress}
                            name='companyAddress'
                            onChange={changeFormValue}
                            placeholder='Enter Your Company Address'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.companyAddress || ''}
                            variant='outlined'
                          />
                        }
                        label='Company Address *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid col2'>
                      <FormControlLabel
                        control={
                          <TextField
                            error={formErrors.companyPostcode}
                            helperText={formMessages.companyPostcode}
                            name='companyPostcode'
                            onChange={changeFormValue}
                            placeholder='Enter Your Postcode'
                            required
                            sx={{ width: '100%' }}
                            value={formValues.companyPostcode || ''}
                            variant='outlined'
                          />
                        }
                        label='Postcode *'
                        sx={{ width: '100%' }}
                      />

                      <FormControlLabel
                        control={
                          <>
                            {formMessages.companyState ? <FormHelperText error>{formMessages.companyState}</FormHelperText> : null}

                            <Select
                              displayEmpty
                              name='companyState'
                              onChange={changeFormValue}
                              renderValue={(selected) => {
                                if (selected) {
                                  return selected
                                }

                                return <span style={{ color: '#808080' }}>Choose Your State</span>
                              }}
                              required
                              sx={{ minWidth: '100%' }}
                              value={formValues.companyState || ''}
                            >
                              {StatesList.map(({ name, value }, idx) => <MenuItem key={`${idx}-${value}`} value={value}>{name}</MenuItem>)}
                            </Select>
                          </>
                        }
                        label='State *'
                        sx={{ width: '100%' }}
                      />
                    </div>

                    <div className='c-form-grid'>
                      <FormControlLabel
                        control={
                          <>
                            {formMessages.industry ? <FormHelperText error>{formMessages.industry}</FormHelperText> : null}

                            <Select
                              displayEmpty
                              name='industry'
                              onChange={changeFormValue}
                              renderValue={(selected) => {
                                if (selected) {
                                  return selected
                                }

                                return (
                                  <span style={{ color: '#808080' }}>
                                    {formType === FormTypes.INDIVIDUAL ? 'Choose Your Industry' : 'Choose Your Business Industry'}
                                  </span>
                                )
                              }}
                              required
                              sx={{ minWidth: '100%' }}
                              value={formValues.industry || ''}
                            >
                              {IndustriesList.map(({ category, name, value }, idx) => (
                                <MenuItem key={`${idx}-${value}`} value={value}>
                                  <span style={{ fontWeight: 'bold' }}>{category}</span>&nbsp;-&nbsp;{name}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        }
                        label='Business Industry *'
                        sx={{ width: '100%' }}
                      />
                    </div>
                  </>
                )}

              <div>
                <FormControl className='radio-form-control' component='fieldset' error={formErrors.bankruptcyStatus}>
                  <FormLabel component='legend'>
                    {formType === FormTypes.INDIVIDUAL
                      ? 'Have you been declared bankrupt or have any difficulty applying for loan due to bad credit value on CTOS? *'
                      : 'Has anyone in the board of director of the company been declared bankrupt? *'}
                  </FormLabel>

                  <RadioGroup
                    className='radio-button-group'
                    aria-label='bankruptcyStatus'
                    name='bankruptcyStatus'
                    value={formValues.bankruptcyStatus || ''}
                    onChange={changeFormValue}
                  >
                    {formType === FormTypes.INDIVIDUAL
                      ? (
                        <>
                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Yes, I am or have been bankrupt before'
                            value='Yes, I am or have been bankrupt before'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Yes, I currently have bad credit value on CTOS'
                            value='Yes, I currently have bad credit value on CTOS'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Yes, for both'
                            value='Yes, for both'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='No, for both'
                            value='No, for both'
                          />
                        </>
                      )
                      : (
                        <>
                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Yes'
                            value='Yes'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='No'
                            value='No'
                          />
                        </>
                      )}
                  </RadioGroup>

                  {formMessages.bankruptcyStatus ? <FormHelperText>{formMessages.bankruptcyStatus}</FormHelperText> : null}
                </FormControl>
              </div>

              <div>
                <FormControl className='radio-form-control' component='fieldset' error={formErrors.offering}>
                  <FormLabel component='legend'>
                    {formType === FormTypes.INDIVIDUAL
                      ? 'What do you offer? *'
                      : 'What does your company offer? *'}
                  </FormLabel>

                  <RadioGroup
                    className='radio-button-group'
                    aria-label='offering'
                    name='offering'
                    value={formValues.offering || ''}
                    onChange={changeFormValue}
                  >
                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='Products'
                      value='Products'
                    />

                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='Services'
                      value='Services'
                    />

                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='Products and services'
                      value='Products and services'
                    />

                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='Others'
                      value='Others'
                    />
                  </RadioGroup>

                  {formMessages.offering ? <FormHelperText>{formMessages.offering}</FormHelperText> : null}
                </FormControl>
              </div>

              <div className='c-form-grid'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.offeringDetail}
                      helperText={formMessages.offeringDetail}
                      maxRows={10}
                      minRows={5}
                      multiline
                      name='offeringDetail'
                      onChange={changeFormValue}
                      placeholder='Paragraph'
                      required sx={{ width: '100%' }}
                      value={formValues.offeringDetail || ''}
                      variant='outlined'
                    />
                  }
                  label={formType === FormTypes.INDIVIDUAL
                    ? 'Please elaborate on your offering(s) *'
                    : 'Please elaborate on your company’s offerings. *'}
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='c-form-grid'>
                <FormControlLabel
                  control={
                    <>
                      {formMessages.numOfWorkers ? <FormHelperText error>{formMessages.numOfWorkers}</FormHelperText> : null}

                      <Select
                        displayEmpty
                        name='numOfWorkers'
                        onChange={changeFormValue}
                        renderValue={(selected) => {
                          if (selected) {
                            return selected
                          }

                          return <span style={{ color: '#808080' }}>Choose Number of Workers</span>
                        }}
                        required
                        sx={{ minWidth: '100%' }}
                        value={formValues.numOfWorkers || ''}
                      >
                        <MenuItem value='1 - 10'>1 - 10</MenuItem>

                        <MenuItem value='11 - 20'>11 - 20</MenuItem>

                        <MenuItem value='21 - 50'>21 - 50</MenuItem>

                        <MenuItem value='51 - 100'>51 - 100</MenuItem>

                        <MenuItem value='101 - 500'>101 - 500</MenuItem>

                        <MenuItem value='501 - 1000'>501 - 1000</MenuItem>

                        <MenuItem value='1001 and above'>1001 and above</MenuItem>
                      </Select>
                    </>
                  }
                  label='Number of Workers (incl. yourself) *'
                  sx={{ width: '100%' }}
                />
              </div>

              {formType === FormTypes.INDIVIDUAL
                ? null
                : (
                  <>
                    <div className='c-form-grid'>
                      <FormControlLabel
                        control={
                          <>
                            {formMessages.yearOperating ? <FormHelperText error>{formMessages.yearOperating}</FormHelperText> : null}

                            <Select
                              error={formErrors.yearOperating}
                              helperText={formMessages.yearOperating}
                              displayEmpty
                              name='yearOperating'
                              onChange={changeFormValue}
                              renderValue={(selected) => {
                                if (selected) {
                                  return selected
                                }

                                return <span style={{ color: '#808080' }}>Choose Your Answer</span>
                              }}
                              required
                              sx={{ minWidth: '100%' }}
                              value={formValues.yearOperating || ''}
                            >
                              <MenuItem value='0 - 1'>0 - 1 year</MenuItem>

                              <MenuItem value='2 - 5'>2 - 5 years</MenuItem>

                              <MenuItem value='6 - 10'>6 - 10 years</MenuItem>

                              <MenuItem value='11 - 20'>11 - 20 years</MenuItem>

                              <MenuItem value='21 - 50'>21 - 50 years</MenuItem>

                              <MenuItem value='51 - 100'>51 - 100 years</MenuItem>

                              <MenuItem value='101 and above'>101 years and above</MenuItem>
                            </Select>
                          </>
                        }
                        label='How long has your company been operating? *'
                        sx={{ width: '100%' }}
                      />
                    </div>
                    <div>
                      <FormControl className='radio-form-control' component='fieldset' error={formErrors.overseasOperation}>
                        <FormLabel component='legend'>
                          Does your company have a branch or operate outside of Malaysia? *
                        </FormLabel>

                        <RadioGroup
                          className='radio-button-group'
                          aria-label='overseasOperation'
                          name='overseasOperation'
                          value={formValues.overseasOperation || ''}
                          onChange={changeFormValue}
                        >
                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Yes'
                            value='Yes'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='Currently, no. But we plan to expand outside of Malaysia.'
                            value='Currently, no. But we plan to expand outside of Malaysia.'
                          />

                          <MuiFormControlLabel
                            className='radio'
                            control={<Radio />}
                            label='No, we are doing business in Malaysia only and do not have any plan to expand outside of Malaysia.'
                            value='No, we are doing business in Malaysia only and do not have any plan to expand outside of Malaysia.'
                          />
                        </RadioGroup>

                        {formMessages.overseasOperation ? <FormHelperText>{formMessages.overseasOperation}</FormHelperText> : null}
                      </FormControl>
                    </div>
                  </>
                )}


              <div>
                <FormControl className='radio-form-control' component='fieldset' error={formErrors.soldOnlineBefore}>
                  <FormLabel component='legend'>
                    {formType === FormTypes.INDIVIDUAL
                      ? 'Have you sold anything online before? *'
                      : 'Has your company sold anything online before? *'}
                  </FormLabel>

                  <RadioGroup
                    className='radio-button-group'
                    aria-label='soldOnlineBefore'
                    name='soldOnlineBefore'
                    value={formValues.soldOnlineBefore || ''}
                    onChange={changeFormValue}
                  >
                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='Yes'
                      value='Yes'
                    />

                    <MuiFormControlLabel
                      className='radio'
                      control={<Radio />}
                      label='No'
                      value='No'
                    />
                  </RadioGroup>

                  {formMessages.soldOnlineBefore ? <FormHelperText>{formMessages.soldOnlineBefore}</FormHelperText> : null}
                </FormControl>
              </div>

              <div>
                <FormControl className='checkbox-form-control' component='fieldset' sx={{ width: '100%' }}>
                  <FormLabel component='legend'>
                    If yes, which online platform did you use? *
                  </FormLabel>

                  <FormGroup className='checkbox-form-group'>
                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('Lazada/Shopee')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('Lazada/Shopee')}
                        />
                      }
                      label='Lazada/Shopee'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('Amazon')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('Amazon')}
                        />
                      }
                      label='Amazon'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('eBay')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('eBay')}
                        />
                      }
                      label='eBay'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('Mudah/Carousel')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('Mudah/Carousel')}
                        />
                      }
                      label='Mudah/Carousel'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('WooCommerce')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('WooCommerce')}
                        />
                      }
                      label='WooCommerce'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('Shopify')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('Shopify')}
                        />
                      }
                      label='Shopify'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox
                          className='checkbox'
                          checked={isOnlinePlatformChecked('Others')}
                          disabled={isOnlinePlatformDisabled()}
                          onChange={changeOnlinePlatform('Others')}
                        />
                      }
                      label='Others, please specify:'
                    />

                    {formMessages.onlinePlatform ? <FormHelperText error>{formMessages.onlinePlatform}</FormHelperText> : null}

                    {showOtherOnlinePlatform
                      ? (
                        <TextField
                          disabled={isOnlinePlatformDisabled()}
                          error={formErrors.otherOnlinePlatform}
                          helperText={formMessages.otherOnlinePlatform}
                          name='otherOnlinePlatform'
                          onChange={changeFormValue}
                          placeholder='Enter Your Text'
                          required
                          sx={{ width: '100%' }}
                          value={formValues.otherOnlinePlatform || ''}
                          variant='outlined'
                        />
                      )
                      : null}
                  </FormGroup>
                </FormControl>
              </div>

              <div className='c-form-grid'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.moreDetail}
                      helperText={formMessages.moreDetail}
                      maxRows={10}
                      minRows={5}
                      multiline
                      name='moreDetail'
                      onChange={changeFormValue}
                      placeholder='Enter Your Message or Inquiry'
                      required sx={{ width: '100%' }}
                      value={formValues.moreDetail || ''}
                      variant='outlined'
                    />
                  }
                  label='Tell us more about yourself and why you would like to launch your business online. *'
                  sx={{ width: '100%' }}
                />
              </div>

              <div>
                <FormControl className='checkbox-form-control tnc' component='fieldset'>
                  <FormGroup className='checkbox-form-group tnc'>
                    <MuiFormControlLabel
                      control={
                        <Checkbox onChange={changeAgreeTnc(0)} />
                      }
                      label={<div>I have read and understood the <a className='link' href='/tnc' target="_blank">terms and conditions</a> for the e-commerce axCellence Program.</div>}
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox onChange={changeAgreeTnc(1)} />
                      }
                      label='I agree to have my personal information processed for the purpose of applying for the e-commerce axCellence Program.'
                    />

                    <MuiFormControlLabel
                      control={
                        <Checkbox onChange={changeAgreeTnc(2)} />
                      }
                      label='The information above is accurate to the best of my knowledge at the time of application.'
                    />
                  </FormGroup>
                </FormControl>
              </div>

              {/* <div>
                Recaptcha
              </div> */}

              <div className='button-box'>
                <Button disabled={disableSubmit || loading} onClick={handleSubmit} type='solid'>
                  Submit{loading ? <LoopIcon className='cu-rotating' /> : null}
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  )

  function clearFormError(name) {
    delete formErrors[name]
    delete formMessages[name]
    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
  }

  function getFormValidator() {
    return {
      bankruptcyStatus(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please choose Bankruptcy Status'
        }
      },
      companyAddress(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Company Address'
        }
      },
      companyPostcode(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Company Postcode'
        }
      },
      companyState(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please choose Company State'
        }
      },
      companyName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Company Name'
        }
      },
      companyRegNo(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Company Registration No.'
        }
      },
      contactEmail(value) {
        if (validator.isEmptyStr(value)) {
          return "Please enter Contact Person's E-mail"
        }
      },
      contactName(value) {
        if (validator.isEmptyStr(value)) {
          return "Please enter Contact Person's Full Name"
        }
      },
      contactNric(value) {
        if (validator.isEmptyStr(value)) {
          return "Please enter Contact Person's NRIC"
        }
      },
      contactPosition(value) {
        if (validator.isEmptyStr(value)) {
          return "Please enter Contact Person's Postion"
        }
      },
      email(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter E-mail'
        } else if (!validator.isEmail(value)) {
          return 'E-mail is invalid'
        }
      },
      fullName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Full Name'
        }
      },
      icNo(value) {
        if (!validator.isDigit(value)) {
          return 'NRIC can only contain numbers'
        }
      },
      industry(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Business Industry'
        }
      },
      mailAddress(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Mailing Address'
        }
      },
      mailPostcode(value) {
        if (!validator.isDigit(value)) {
          return 'Postcode can only contain numbers'
        }
      },
      mailState(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter State'
        }
      },
      mobileNo(value) {
        if (!validator.isDigit(value)) {
          return 'Mobile Phone can only contain numbers'
        }
      },
      moreDetail(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please tell us more about yourself and why you would like to launch your business online'
        }
      },
      numOfWorkers(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please choose Number of Workers'
        }
      },
      offering(value) {
        if (validator.isEmptyStr(value)) {
          return formType.INDIVIDUAL ? 'Please choose Offering' : 'Please choose Company Offering'
        }
      },
      offeringDetail(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please elaborate on your offering(s)'
        }
      },
      onlinePlatform(value) {
        const isEmpty = Array.isArray(value) ? value.length < 1 : true

        if (formValues.soldOnlineBefore === 'Yes' && isEmpty) {
          return 'Please choose Online Platform'
        }
      },
      otherOnlinePlatform(value) {
        const { onlinePlatform: fvOnlinePlatform } = formValues
        const onlinePlatforms = Array.isArray(fvOnlinePlatform) ? fvOnlinePlatform : []

        if (onlinePlatforms.indexOf('Others') > -1 && validator.isEmptyStr(value)) {
          return 'Please specify others'
        }
      },
      overseasOperation(value) {
        if (validator.isEmptyStr(value)) {
          return 'Does your company have a branch or operate outside of Malaysia?'
        }
      },
      phoneNo(value) {
        if (!validator.isDigit(value)) {
          return 'Phone Number can only contain numbers'
        }
      },
      soldOnlineBefore(value) {
        if (validator.isEmptyStr(value)) {
          return 'Has your company sold anything online before?'
        }
      },
      yearOperating(value) {
        if (validator.isEmptyStr(value)) {
          return 'How long has your company been operating?'
        }
      }
    }
  }

  function hasFormErrors() {
    return Object.keys(formErrors).length > 0
  }

  function validateFormValue(name, value) {
    const formValidator = getFormValidator()
    const validate = formValidator[name]

    if (typeof validate === 'function') {
      return validate(value)
    }
  }

  function validateFormValues() {
    const fields = formType === FormTypes.INDIVIDUAL
      ? [
        'bankruptcyStatus', 'email', 'fullName', 'icNo', 'industry', 'mailAddress', 'mailPostcode', 'mailState', 'mobileNo',
        'moreDetail', 'numOfWorkers', 'offering', 'offeringDetail', 'onlinePlatform', 'otherOnlinePlatform',
        'soldOnlineBefore'
      ]
      : [
        'bankruptcyStatus', 'companyAddress', 'companyPostcode', 'companyState', 'companyName', 'companyRegNo', 'contactEmail',
        'contactName', 'contactNric', 'contactPosition', 'industry', 'moreDetail', 'numOfWorkers', 'offering', 'offeringDetail',
        'onlinePlatform', 'otherOnlinePlatform', 'overseasOperation', 'phoneNo', 'soldOnlineBefore', 'yearOperating'
      ]
    const formErrors = {}
    const formMessages = {}
    let errorCount = 0

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const value = formValues[field]
      const error = validateFormValue(field, value)

      if (error) {
        formErrors[field] = true
        formMessages[field] = error
        errorCount += 1
      } else {
        delete formErrors[field]
        delete formMessages[field]
      }
    }

    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
    return errorCount > 0
  }
}

export default Community
