import axios from 'axios'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { HeaderHero, LeftContentButtonCard, LeftRightContentBlock } from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

import './styles.css'

function Hosting (props) {
  const [headerHero, setHeaderHero] = useState({})
  const [leftContentButtonCard, setLeftContentButtonCard] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Services-Axolot-Hosting/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isLeftContentButtonCard = false
            let isLeftRightContentBlock = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_CONTENT_BUTTON_CARD, __component)) {
                if (!isLeftContentButtonCard) {
                  const card = slice || {}
                  isLeftContentButtonCard = true
                  setLeftContentButtonCard(card)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK4, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock ? <div className='sh-left-right-content-block'><LeftRightContentBlock data={leftRightContentBlock} /></div> : null}

      {leftContentButtonCard
        ? <div className='sh-left-content-button-card'><LeftContentButtonCard data={leftContentButtonCard} /></div>
        : null}

      <div className='sh-video-box'>
        <ReactPlayer
          className='react-player'
          url='https://www.youtube.com/watch?v=OqooJn_Ji0s'
          height='500px'
          width='100%'
          style={{
            maxWidth: '100%'
          }}
        />
      </div>
    </div>
  )
}

export default Hosting
