import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'
import { Fade, Slide } from 'react-reveal'

import './styles.css'

function AxLeftContentCardBlock (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { cards, main_image: image, title } = data
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-left-content-card-block', className)}>
      <div className='text-box'>
        {title
          ? (
            <Typography className='title'>{title}</Typography>
          )
          : null}

        {Array.isArray(cards)
          ? (
            <Fade cascade top>
              <div className='list-box'>
                {cards.map((card) => {
                  const { id, description, image, title } = card
                  const { url: imageUrl } = image || {}

                  return (
                    <div className='item' key={id}>
                      <div className='top-box'>
                        <div style={{ marginRight: '23px' }}>
                          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                          {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                        </div>

                        <div>
                          <Typography className='title'>{title}</Typography>
                        </div>
                      </div>

                      <Typography className='description'>{description}</Typography>
                    </div>
                  )
                })}
              </div>
            </Fade>
          )
          : null}
      </div>

      <div className='image-box'>
        <Slide right>
          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
        </Slide>
        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
      </div>
    </div>
  )
}

AxLeftContentCardBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftContentCardBlock
