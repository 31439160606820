import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Select as MuiSelect } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(MuiSelect, {})(({ theme }) => ({
  '& .MuiInputBase-formControl, & .MuiOutlinedInput-input, & .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '5px'
  },
  '& .MuiOutlinedInput-input': {
    backgroundColor: '#F0F5F7',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    padding: '22px 29px 23px 29px',
    ':hover': {
      backgroundColor: '#D6E4E9'
    }
  }
}))

function AxSelect (props) {
  const { children, className } = props

  return (
    <StyledSelect
      className={clsx(className)}
      {...props}
    >
      {children}
    </StyledSelect>
  )
}

AxSelect.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
}

export default AxSelect
