import { useState } from "react"
import { Snackbar, Alert } from "@mui/material"

const useSnackbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState('')

  const hide = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setIsOpen(false)
  }

  const show = (error) => {
    setIsOpen(true)
    setError(error)
  }

  return [hide, show, <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={6000}
    onClose={hide}
    open={isOpen}
  >
    <Alert onClose={hide} severity='error' sx={{ width: '100%' }}>
      Unable to submit successfully. {error}
    </Alert>
  </Snackbar>]
}

export default useSnackbar