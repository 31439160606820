import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(MuiTextField, {})(({ theme }) => ({
  '& .MuiInputBase-formControl, & .MuiOutlinedInput-input, & .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '5px',
    padding: 0
  },
  '& .MuiOutlinedInput-input': {
    backgroundColor: '#F0F5F7',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    padding: '22px 29px 23px 29px',
    ':hover': {
      backgroundColor: '#D6E4E9'
    },
    '::placeholder': {
      color: '#808080'
    },
    '::-webkit-input-placeholder': {
      color: '#808080'
    },
    '::-moz-placeholder': {
      color: '#808080'
    },
    ':-ms-input-placeholder': {
      color: '#808080'
    },
    ':-moz-placeholder': {
      color: '#808080'
    }
  }
}))

function AxTextField (props) {
  const { children, className } = props

  return (
    <StyledTextField
      className={clsx(className)}
      {...props}
    >
      {children}
    </StyledTextField>
  )
}

AxTextField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
}

export default AxTextField
