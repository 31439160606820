import dompurify from 'dompurify'
import { marked } from 'marked'

const DomPurify = dompurify(window)

const formatter = {
  toHtml (text) {
    text = typeof text === 'string' ? text : ''
    text = DomPurify.sanitize(marked.parse(text))
    return text
  },
  toNumber (number) {
    return number.toLocaleString('en-US', {minimumFractionDigits: 2})
  }
}
export default formatter
