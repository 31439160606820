import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { formatter, util } from '../../util'

import './styles.css'

function AxLeftContentButtonCard (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { button_href: buttonHref, button_label: buttonLabel, description } = data
  let { background_imgae: image } = data

  if (Array.isArray(image)) {
    image = image[0]
  }

  const { url: imageUrl } = image || {}
  const style = imageUrl
    ? {
        backgroundImage: `url(${process.env.REACT_APP_BASE_URL + imageUrl})`,
        // backgroundImage: `url(${'http://axolot.oceannexus.my:1337' + imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    : null

  return (
    <div className={clsx('ax-left-content-button-card', className)} style={style}>
      {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

      {buttonLabel || buttonHref
        ? (
          <Button
            className='btn-action'
            href={buttonHref}
            sx={{ textTransform: 'capitalize' }}
            type='solid'
          >
            {buttonLabel || buttonHref}
          </Button>
          )
        : null}
    </div>
  )
}

AxLeftContentButtonCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftContentButtonCard
