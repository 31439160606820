import axios from 'axios'
import React, { useState } from 'react'

import { ColumnCardGrid2, HeaderHero, LeftRightContentBlock, Title, TwoColumnFullWidth } from '../../../components'
import { ComponentTypes } from '../../../constants'
import useLoadingEffect from '../../../hooks/useLoadingEffect'
import { util } from '../../../util'

import './styles.css'

function WebsiteCommerce (props) {
  const [headerHero, setHeaderHero] = useState({})
  const [imageRightTitleDescriptionCard, setImageRightTitleDescriptionCard] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Solution-Website-e-Commerce/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isImageRightTitleDescriptionCardSet = false
            let isLeftRightContentBlock = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB5, __component)) {
                const { link: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK10, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  const _contents = [].concat(contents)
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(_contents.splice(0, 2))
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.BORDERLESS_COLUMN_CARDS, __component)) {
                if (!isImageRightTitleDescriptionCardSet) {
                  const { card: cards } = slice || {}
                  isImageRightTitleDescriptionCardSet = true
                  setImageRightTitleDescriptionCard(cards)
                }
              } else if (util.isComponent(ComponentTypes.TITLE1, __component)) {
                if (!isTitle1Set) {
                  const { title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE1, __component)) {
                if (!isTitle2Set) {
                  const title = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH5, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock
        ? (
          <div className='snwc-left-right-content-block'>
            <LeftRightContentBlock data={leftRightContentBlock} reverse listIcon='/icon/arrow-right-circle.svg' />
          </div>
          )
        : null}

      {title1 ? <div className='snwc-title1'><Title data={title1} /></div> : null}

      {imageRightTitleDescriptionCard
        ? <div className='snwc-image-right-title-description-card'><ColumnCardGrid2 data={imageRightTitleDescriptionCard} /></div>
        : null}

      {title2 ? <div className='snwc-title2'><Title data={title2} /></div> : null}

      {twoColumnFullWidth ? <div className='snwc-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default WebsiteCommerce
