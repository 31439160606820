import NotFoundImage from '../assets/images/Group 80.svg'

const NotFoundPage = () => {
  return (
    <div className="text-center mt-5 mb-5 py-2 px-2">
      <img src={NotFoundImage} className="img-fluid" alt="Page Not Found" />
    </div>
  )
}

export default NotFoundPage