import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { formatter, util } from '../../util'

import './styles.css'

function AxLeftContentImageCardBlock (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    button_href: buttonHref, button_label: buttonLabel, description, first_partial_title: startTitle, image,
    second_partial_title: endTitle, span, text: cards, title
  } = data
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-left-content-image-card-block', className)}>
      <div className='text-box'>
        {startTitle || span || endTitle || title
          ? (
            <Typography className='title'>
              {startTitle}{span ? <span className='span'>{span}</span> : null}{endTitle}{title}
            </Typography>
            )
          : null}

        {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

        {Array.isArray(cards)
          ? (
            <div className='list-box'>
              {cards.map((card) => {
                const { id, description } = card

                return (
                  <div className='item' key={id}>
                    <img className='img' alt='' src='/icon/arrow-right-circle.svg' />

                    <Typography className='description'>{description}</Typography>
                  </div>
                )
              })}
            </div>
            )
          : null}

        {buttonLabel || buttonHref
          ? (
            <div className='button-box'>
              <Button className='btn-action' color='blue' href={buttonHref} type='solid'>{buttonLabel || buttonHref}</Button>
            </div>
            )
          : null}
      </div>

      <div className='image-box'>
        <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
      </div>
    </div>
  )
}

AxLeftContentImageCardBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftContentImageCardBlock
