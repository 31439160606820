import axios from 'axios'
import React, { useState } from 'react'

import { Loop as LoopIcon } from '@mui/icons-material'
import {
  Alert, Checkbox, FormControl, FormGroup, FormControlLabel as MuiFormControlLabel, Snackbar, Typography, Box
} from '@mui/material'
import {
  Button, ColumnCardGrid2, FormControlLabel, HeaderHero, LeftContentButtonCard, LeftIconCard, RightContentBlock, TextField, Title
} from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util, validator } from '../../../util'
import { Fade } from 'react-reveal'
import FormatterV2 from '../../../util/formatter-v2'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function AxCelerate(props) {
  const [agreeTnc, setAgreeTnc] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [formMessages, setFormMessages] = useState({})
  const [formValues, setFormValues] = useState({})
  const [headerHero, setHeaderHero] = useState({})
  const [imageRightTitleDescriptionCard, setImageRightTitleDescriptionCard] = useState()
  const [leftContentButtonCard, setLeftContentButtonCard] = useState()
  const [leftIconCard1, setLeftIconCard1] = useState()
  const [leftIconCard2, setLeftIconCard2] = useState()
  const [loading, setLoading] = useState(false)
  const [rightContentBlock1, setRightContentBlock1] = useState()
  const [rightContentBlock2, setRightContentBlock2] = useState()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [terms, setTerms] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})

  const changeAgreeTnc = (e, value) => {
    setAgreeTnc(value)
  }

  const changeFormValue = (e) => {
    const { target } = e || {}
    const { name, value } = target || {}
    const error = validateFormValue(name, value)
    setFormValues({ ...formValues, [name]: value })

    if (error) {
      setFormErrors({ ...formErrors, [name]: true })
      setFormMessages({ ...formMessages, [name]: error })
    } else {
      clearFormError(name)
    }
  }

  const handleHideError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowError(false)
  }

  const handleShowError = () => {
    setShowError(true)
  }

  const handleSubmit = () => {
    if (validateFormValues()) {
      handleShowError()
      return
    }

    setLoading(true)
    const { email, firstName, lastName, phoneNo, subscriptionNo, storeAppName } = formValues
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/axcelerates`,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNo,
          subscription_number: subscriptionNo,
          store_name: storeAppName,
        }
      )
      .then(() => {
        setLoading(false)
        setShowSuccess(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      .catch((err) => {
        setLoading(false)

        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(err.response)
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error(err.message)
        }

        handleShowError()
      })
  }

  const renderSubmissionSuccessComponent = ({ image, first_partial_description, span, description, note, button_label, button_href }) => {
    return (
      <Box className='sax-success-box'>
        <Box className='image-box'><img className='img-success' src={image} /></Box>

        <Box className='title-box'>
          <Box className='title'>{first_partial_description} <span className='highlight'>{span}</span>.</Box>

          <Box className='subtitle' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(description) }} />
        </Box>

        <Box className='note' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(note) }} />

        <Box className='button-box'>
          <Button href={button_href} type='solid'>{button_label}</Button>
        </Box>
      </Box>
    )
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Services-Axcelerate-Setup-Launch/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-services-axcelerate-setup-launch-slices.acknowledgement'))
          setComponents(data.slices.filter(item => item.__component !== 'page-services-axcelerate-setup-launch-slices.acknowledgement'))

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isImageRightTitleDescriptionCardSet = false
            let isLeftIconCard1 = false
            let isLeftIconCard2 = false
            let isLeftContentButtonCardSet = false
            let isRightContentBlockSet1 = false
            let isRightContentBlockSet2 = false
            let isTermsSet = false
            let isTitle1Set = false
            let isTitle2Set = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB6, __component)) {
                const { links: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_CONTENT_BUTTON_CARD1, __component)) {
                if (!isLeftContentButtonCardSet) {
                  const card = slice || {}
                  isLeftContentButtonCardSet = true
                  setLeftContentButtonCard(card)
                }

                if (!isRightContentBlockSet2) {
                  const { help: contents } = slice || {}
                  isRightContentBlockSet2 = true
                  setRightContentBlock2(contents[1])
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK6, __component)) {
                if (!isRightContentBlockSet1) {
                  const { help: contents } = slice || {}
                  isRightContentBlockSet1 = true
                  setRightContentBlock1(contents[0])
                }

                if (!isRightContentBlockSet2) {
                  const { help: contents } = slice || {}
                  isRightContentBlockSet2 = true
                  setRightContentBlock2(contents[1])
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD3, __component)) {
                if (!isLeftIconCard2) {
                  const card = slice || {}
                  isLeftIconCard2 = true
                  setLeftIconCard2(card)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD1, __component)) {
                if (!isImageRightTitleDescriptionCardSet) {
                  const { cards } = slice || {}
                  isImageRightTitleDescriptionCardSet = true
                  setImageRightTitleDescriptionCard(cards)
                }
              } else if (util.isComponent(ComponentTypes.NOTE, __component)) {
                if (!isLeftIconCard1) {
                  const card = slice || {}
                  isLeftIconCard1 = true
                  setLeftIconCard1(card)
                }
              } else if (util.isComponent(ComponentTypes.TERMS1, __component)) {
                if (!isTermsSet) {
                  const terms = slice || {}
                  isTermsSet = true
                  setTerms(terms)
                }
              } else if (util.isComponent(ComponentTypes.FIRST_TITLE1, __component)) {
                if (!isTitle1Set) {
                  const title = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE, __component)) {
                if (!isTitle2Set) {
                  const title = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleHideError}
        open={showError}
      >
        <Alert onClose={handleHideError} severity='error' sx={{ width: '100%' }}>
          Unable to submit successfully. {hasFormErrors() ? 'Please ensure all values are entered correctly.' : 'Please try again later.'}
        </Alert>
      </Snackbar>

      {showSuccess
        ? (
          <Fade>
            {renderSubmissionSuccessComponent({
              ...submissionSuccessComponent,
              image: `${process.env.REACT_APP_BASE_URL}${submissionSuccessComponent?.image?.url}`,
            })}
          </Fade>
        )
        : (
          <>
            {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

            {rightContentBlock1 ? <div className='sax-right-content-block1'><RightContentBlock data={rightContentBlock1} /></div> : null}

            {leftContentButtonCard
              ? <div className='sax-left-content-button-card'><LeftContentButtonCard data={leftContentButtonCard} /></div>
              : null}

            {title1 ? <div className='sax-title1'><Title data={title1} /></div> : null}

            {imageRightTitleDescriptionCard
              ? <div className='sax-image-right-title-description-card'><ColumnCardGrid2 data={imageRightTitleDescriptionCard} /></div>
              : null}

            {leftIconCard1 ? <div className='sax-left-icon-card1'><LeftIconCard data={leftIconCard1} /></div> : null}

            {rightContentBlock2 ? <div className='sax-right-content-block2'><RightContentBlock data={rightContentBlock2} /></div> : null}

            {terms
              ? (
                <div className='sax-terms'>
                  <div className='terms'>
                    {terms.title ? <Typography className='title'>{terms.title}</Typography> : null}

                    <ol className='list-box'>
                      {Array.isArray(terms.text)
                        ? terms.text.map((item, idx) => {
                          const { id, text } = item

                          return text ? <li key={id}><Typography>{text}</Typography></li> : null
                        })
                        : null}
                    </ol>
                  </div>
                </div>
              )
              : null}

            {title2 ? <div className='sax-title2'><Title data={title2} /></div> : null}

            {leftIconCard2 ? <div className='sax-left-icon-card2'><LeftIconCard data={leftIconCard2} /></div> : null}

            <div className='sax-box'>
              <div className='sax-form-grid col2'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.firstName}
                      helperText={formMessages.firstName}
                      name='firstName'
                      onChange={changeFormValue}
                      placeholder='Enter Your First Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.firstName || ''}
                      variant='outlined'
                    />
                  }
                  label='First Name *'
                  sx={{ width: '100%' }}
                />

                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.lastName}
                      helperText={formMessages.lastName}
                      name='lastName'
                      onChange={changeFormValue}
                      placeholder='Enter Your Last Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.lastName || ''}
                      variant='outlined'
                    />
                  }
                  label='Last Name *'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='sax-form-grid col2'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.subscriptionNo}
                      helperText={formMessages.subscriptionNo}
                      name='subscriptionNo'
                      onChange={changeFormValue}
                      placeholder='Enter Your Subscription No'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.subscriptionNo || ''}
                      variant='outlined'
                    />
                  }
                  label='Your AIC Subscription No. *'
                  sx={{ width: '100%' }}
                />

                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.email}
                      helperText={formMessages.email}
                      name='email'
                      onChange={changeFormValue}
                      placeholder='Enter Your E-mail'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.email || ''}
                      variant='outlined'
                    />
                  }
                  label='E-mail *'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='sax-form-grid col2'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.phoneNo}
                      helperText={formMessages.phoneNo}
                      name='phoneNo'
                      onChange={changeFormValue}
                      placeholder='Enter Your Telephone No.'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.phoneNo || ''}
                      variant='outlined'
                    />
                  }
                  label='Telephone No. *'
                  sx={{ width: '100%' }}
                />

                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.storeAppName}
                      helperText={formMessages.storeAppName}
                      name='storeAppName'
                      onChange={changeFormValue}
                      placeholder='Enter Your Store/App Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.storeAppName || ''}
                      variant='outlined'
                    />
                  }
                  label='Store/App Name *'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='form-box'>
                <FormControl className='checkbox-form-control' component='fieldset'>
                  <FormGroup className='checkbox-form-group tnc'>
                    <MuiFormControlLabel
                      control={
                        <Checkbox onChange={changeAgreeTnc} />
                      }
                      label={<div>I have read and understood the <a className='link' href='/tnc' target="_blank">terms and conditions</a> for Axolot Instant Commerce (AIC).</div>}
                    />
                  </FormGroup>
                </FormControl>
              </div>

              {/* <div>
                Recaptcha
              </div> */}

              <div className='button-box'>
                <Button disabled={!agreeTnc || loading} onClick={handleSubmit} type='solid'>
                  Submit Inquiry{loading ? <LoopIcon className='cu-rotating' /> : null}
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  )

  function clearFormError(name) {
    delete formErrors[name]
    delete formMessages[name]
    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
  }

  function getFormValidator() {
    return {
      firstName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter First Name'
        }
      },
      lastName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Last Name'
        }
      },
      subscriptionNo(value) {
        if (!validator.isDigit(value)) {
          return 'AIC Subscription No. can only contain numbers'
        }
      },
      email(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter E-mail'
        } else if (!validator.isEmail(value)) {
          return 'E-mail is invalid'
        }
      },
      phoneNo(value) {
        if (!validator.isDigit(value)) {
          return 'Telephone No. can only contain numbers'
        }
      },
      storeAppName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Store/App Name'
        }
      }
    }
  }

  function hasFormErrors() {
    return Object.keys(formErrors).length > 0
  }

  function validateFormValue(name, value) {
    const formValidator = getFormValidator()
    const validate = formValidator[name]

    if (typeof validate === 'function') {
      return validate(value)
    }
  }

  function validateFormValues() {
    const fields = ['firstName', 'lastName', 'subscriptionNo', 'email', 'phoneNo', 'storeAppName']
    const formErrors = {}
    const formMessages = {}
    let errorCount = 0

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const value = formValues[field]
      const error = validateFormValue(field, value)

      if (error) {
        formErrors[field] = true
        formMessages[field] = error
        errorCount += 1
      } else {
        delete formErrors[field]
        delete formMessages[field]
      }
    }

    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
    return errorCount > 0
  }
}

export default AxCelerate
