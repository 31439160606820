import Title from '../../components/title'
import Accordion from './components/accordion/index'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import axios from 'axios'
import { useState, Fragment } from 'react'
import { ComponentTypes } from '../../constants'
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import Box from '@mui/material/Box';

const FAQ = () => {
  const [components, setComponents] = useState([])

  const getData = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/page-faq`
      const { data: { slices = [] } = {} } = await axios.get(url)

      setComponents(
        slices.map((slice) => {
          const { __component = '' } = slice;
          const __componentType = __component.split('.').pop()

          switch (__componentType) {
            case ComponentTypes.PAGE_TITLE:
              const title = { first_partial_title: slice.title }
              return (
                <Box sx={{ marginBottom: 5 }}><Title data={title} /></Box>
              )
            case ComponentTypes.FAQS:
              const data = slice.FAQS.map(({ question, answer }) => ({ title: question, content: answer }))
              return (
                <Accordion data={data} />
              )
            default:
              return null
          }
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{ ...containerSpacing }}>
        {
          components.map((component, index) => {
            return (
              <Fragment key={`component${index}`}>
                {component}
              </Fragment>
            )
          })
        }
      </Box>
    </ThemeProvider>
  )
}

export default FAQ