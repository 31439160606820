import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button as MuiButton } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

const ButtonTypes = {
  OUTLINE: 'outline',
  SOLID: 'solid'
}
const Colors = {
  [ButtonTypes.OUTLINE]: {
    DARK_BLUE: 'dark blue',
    GREY: 'grey',
    LIGHT_BLUE: 'light blue'
  },
  [ButtonTypes.SOLID]: {
    BLACK: 'black',
    BLUE: 'blue',
    DARK_BLUE: 'dark blue',
    GREY: 'grey',
    WHITE: 'white'
  }
}

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'type'
})(({ color, type, theme }) => ({
  borderRadius: '5px',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  height: '45px',
  lineHeight: '19px',
  minWidth: '150px',
  textAlign: 'center',
  ...(type === ButtonTypes.OUTLINE && {
    backgroundColor: 'transparent',
    border: '1px solid #FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${alpha('#000000', 0.2)}`
    },
    ...(color === Colors[ButtonTypes.OUTLINE].DARK_BLUE && {
      backgroundColor: 'transparent',
      border: '1px solid #3560AE',
      color: '#3560AE',
      '&:hover': {
        backgroundColor: `${alpha('#3560AE', 0.2)}`
      }
    }),
    ...(color === Colors[ButtonTypes.OUTLINE].GREY && {
      backgroundColor: 'transparent',
      border: '1px solid #C6C5C5',
      color: '#234171',
      '&:hover': {
        backgroundColor: `${alpha('#C6C5C5', 0.2)}`
      }
    }),
    ...(color === Colors[ButtonTypes.OUTLINE].LIGHT_BLUE && {
      backgroundColor: 'transparent',
      border: '1px solid #4276DB',
      color: '#4276DB',
      '&:hover': {
        backgroundColor: `${alpha('#4276DB', 0.2)}`
      }
    })
  }),
  ...(type === ButtonTypes.SOLID && {
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(95.15deg, #46E6DA -15.35%, #4382DF 100%)',
    border: 'none',
    '&:hover': {
      backgroundImage: 'linear-gradient(95.15deg, #23E1d3 -15.35%, #246CD6 100%)'
    },
    ...(color === Colors[ButtonTypes.SOLID].BLACK && {
      backgroundColor: '#000000',
      backgroundImage: 'none',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#292929'
      }
    }),
    ...(color === Colors[ButtonTypes.SOLID].BLUE && {
      backgroundColor: '#4276DB',
      backgroundImage: 'none',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#2760CE'
      }
    }),
    ...(color === Colors[ButtonTypes.SOLID].DARK_BLUE && {
      backgroundColor: '#3560AE',
      backgroundImage: 'none',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#2C4F90'
      }
    }),
    ...(color === Colors[ButtonTypes.SOLID].GREY && {
      backgroundColor: '#E8E8E8',
      backgroundImage: 'none',
      color: '#A6A6A6',
      '&:hover': {
        backgroundColor: '#D4D4D4'
      }
    }),
    ...(color === Colors[ButtonTypes.SOLID].WHITE && {
      backgroundColor: '#FFFFFF',
      backgroundImage: 'none',
      color: '#234171',
      '&:hover': {
        backgroundColor: '#EBEBEB'
      }
    })
  })
}))

function AxButton (props) {
  const { children, className } = props

  return (
    <StyledButton
      className={clsx(className)}
      variant='contained'
      {...props}
    >
      {children}
    </StyledButton>
  )
}

AxButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string
}

export default AxButton
