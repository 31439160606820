import axios from 'axios'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { Typography, Box } from '@mui/material'
import {
  BannerLeftText, BorderlessColumnCardGrid, ColumnCardGrid, CustomTabs, HeaderHero, LeftIconCard, LeftRightContentBlock,
  LeftRightNestedListBlock, RightListBlock, Steps, Text, Text2, Title, TitleWithButton, TitleWithButton2, TwoColumnCards,
  TwoColumnFullWidth
} from '../../components'
import { ComponentTypes } from '../../constants/component'
import { util } from '../../util'
import { Fade } from 'react-reveal'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import formatter from '../../util/formatter-v2'

import './styles.css'

function ProductAICApp(props) {
  const [bannerLeftText, setBannerLeftText] = useState()
  const [borderlessColumnCardGrid, setBorderlessColumnCardGrid] = useState()
  const [customTabs, setCustomTabs] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [leftIconCard, setLeftIconCard] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [leftRightNestedListBlock, setLeftRightNestedListBlock] = useState()
  const [rightListBlocks, setRightListBlocks] = useState()
  const [steps, setSteps] = useState()
  const [text1, setText1] = useState()
  const [text2, setText2] = useState()
  const [threeColumnCard, setThreeColumnCard] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [title3, setTitle3] = useState()
  const [titleWithButton1, setTitleWithButton1] = useState()
  const [titleWithButton2, setTitleWithButton2] = useState()
  const [twoColumnCards, setTwoColumnCards] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()
  const [components, setComponents] = useState([])

  const structureSection = ({ image, description }) => {
    return (
      <div className='paa-chart-box'>
        <div className='image-box'>
          <Fade><img className='img' src={image} /></Fade>
        </div>

        <Box className='title' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />
      </div>
    )
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'shared.structure':
        return structureSection({
          image: `${process.env.REACT_APP_BASE_URL}${data?.image?.url}`,
          description: data.description
        })
      default:
        return null;
    }
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Product-Aic-App/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          setComponents(slices)

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isBannerLeftTextSet = false
            let isBorderlessColumnCardGridSet = false
            let isCustomTabsSet = false
            let isLeftIconCardSet = false
            let isLeftRightContentBlockSet = false
            let isLeftRightNestedListBlockSet = false
            let isRightListBlocksSet = false
            let isStepsSet = false
            let isTextSet1 = false
            let isTextSet2 = false
            let isThreeColumnCardSet = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTitle3Set = false
            let isTitleWithButton1Set = false
            let isTitleWithButton2Set = false
            let isTwoColumnCardsSet = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BANNER_LEFT_TEXT1, __component)) {
                if (!isBannerLeftTextSet) {
                  const bannerLeftText = slice || {}
                  isBannerLeftTextSet = true
                  setBannerLeftText(bannerLeftText)
                }
              } else if (util.isComponent(ComponentTypes.BREADCRUMB1, __component)) {
                const { bread_crumb: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.BORDERLESS_COLUMN_CARDS, __component)) {
                if (!isBorderlessColumnCardGridSet) {
                  const { comp_cards: columnCards } = slice
                  isBorderlessColumnCardGridSet = true
                  setBorderlessColumnCardGrid(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.COLUMN_CARDS, __component)) {
                if (!isThreeColumnCardSet) {
                  const { card: columnCards } = slice || {}
                  isThreeColumnCardSet = true
                  setThreeColumnCard(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK1, __component)) {
                if (!isLeftRightContentBlockSet) {
                  const { features: contents } = slice || {}
                  isLeftRightContentBlockSet = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_CARDS, __component)) {
                if (!isTwoColumnCardsSet) {
                  const { comp_cards_with_title: columnCards } = slice
                  isTwoColumnCardsSet = true
                  setTwoColumnCards(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.CUSTOM_TABS, __component)) {
                if (!isCustomTabsSet) {
                  const { comp_custom_tabs: tabs } = slice
                  isCustomTabsSet = true
                  setCustomTabs(tabs)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD, __component)) {
                if (!isLeftIconCardSet) {
                  const { comp_icon_text: card } = slice
                  isLeftIconCardSet = true
                  setLeftIconCard(card)
                }
              } else if (util.isComponent(ComponentTypes.RIGHT_LIST_BLOCK, __component)) {
                if (!isRightListBlocksSet) {
                  const { type: blocks } = slice || {}
                  isRightListBlocksSet = true
                  setRightListBlocks(blocks)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_NESTED_LIST_BLOCK, __component)) {
                if (!isLeftRightNestedListBlockSet) {
                  const { blocks } = slice || {}
                  isLeftRightNestedListBlockSet = true
                  setLeftRightNestedListBlock(blocks)
                }
              } else if (util.isComponent(ComponentTypes.STEPS, __component)) {
                if (!isStepsSet) {
                  const { steps } = slice || {}
                  isStepsSet = true
                  setSteps(steps)
                }
              } else if (util.isComponent(ComponentTypes.TEXT, __component)) {
                if (!isTextSet1) {
                  const { comp_first_sinigle_text: text } = slice || {}
                  isTextSet1 = true
                  setText1(text)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TEXT, __component)) {
                if (!isTextSet2) {
                  const text = slice || {}
                  isTextSet2 = true
                  setText2(text)
                }
              } else if (util.isComponent(ComponentTypes.FIRST_TITLE, __component)) {
                if (!isTitle1Set) {
                  const { comp_first_title: title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE1, __component)) {
                if (!isTitle2Set) {
                  const { comp_second_title: title } = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.TITLE2, __component)) {
                if (!isTitle3Set) {
                  const { comp_last_title: title } = slice || {}
                  isTitle3Set = true
                  setTitle3(title)
                }
              } else if (util.isComponent(ComponentTypes.TITLE_WITH_BUTTON, __component)) {
                if (!isTitleWithButton1Set) {
                  isTitleWithButton1Set = true
                  setTitleWithButton1(slice)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE_WITH_BUTTON, __component)) {
                if (!isTitleWithButton2Set) {
                  isTitleWithButton2Set = true
                  setTitleWithButton2(slice)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })


  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {customTabs ? <CustomTabs data={customTabs} /> : null}

      <>
        <div id='package' />

        {title1 ? <div className='paa-title1'><Title data={title1} /></div> : null}

        {borderlessColumnCardGrid
          ? (
            <div className='paa-borderless-column-card-grid'>
              <BorderlessColumnCardGrid data={borderlessColumnCardGrid} />
            </div>
          )
          : null}
      </>

      <div id='features' />

      {Array.isArray(rightListBlocks)
        ? (
          <div className='paa-right-list-block'>
            {rightListBlocks.map((block) => {
              const { id } = block

              return <RightListBlock className='item' key={id} data={block} />
            })}
          </div>
        )
        : null}

      <div className='paa-video-box'>
        <div className='flex-box'>
          <Fade left>
            <div className='left-box'>
              <Typography className='title'>Be where the crowd is.</Typography>

              <Typography className='description'>
                With Axolot Instant Commerce App (AIC App), you are in among the right crowd. Studies show that over 79% of smartphone users have made at least one purchase via their devices and in 2020, over 10% of the entire retail market belong to mobile e-commerce. Ever since the pandemic hit us worldwide, mobile e-commerce trend will only become more prominent and you shouldn’t be left out.
                <br /><br />
                Keep your business relevant, be where the crowd is.
              </Typography>
            </div>
          </Fade>

          <Fade right>
            <div className='right-box'>
              <ReactPlayer
                className='react-player'
                url='https://www.youtube.com/watch?v=riD4BxA6AUM'
                height='290px'
                width='100%'
                style={{
                  maxWidth: '420px'
                }}
              />
            </div>
          </Fade>
        </div>
      </div>

      {titleWithButton1 ? <div className='paa-title-with-button1'><TitleWithButton data={titleWithButton1} /></div> : null}

      {twoColumnCards ? <div className='paa-two-column-cards'><TwoColumnCards data={twoColumnCards} /></div> : null}

      {titleWithButton2 ? <div className='paa-title-with-button2'><TitleWithButton2 data={titleWithButton2} /></div> : null}

      {text1 ? <div className='paa-text1'><Text data={text1} /></div> : null}

      {threeColumnCard ? <div className='paa-column-card-grid'><ColumnCardGrid data={threeColumnCard} tick='/icon/tick-solid-blue.png' /></div> : null}

      {!!components.find(item => item.__component === 'shared.structure') && renderComponent(components.find(item => item.__component === 'shared.structure'))}

      <div id='benefits' />

      {leftRightNestedListBlock ? <div className='paa-left-right-nested-list-block'><Fade cascade left><LeftRightNestedListBlock data={leftRightNestedListBlock} /></Fade></div> : null}

      {title2 ? <div className='paa-title2'><Title data={title2} /></div> : null}

      {leftRightContentBlock ? <div className='paa-left-right-content-block'><Fade cascade right><LeftRightContentBlock className='paa-lrcb' data={leftRightContentBlock} /></Fade></div> : null}

      <div id='getting-started' />

      {text2 ? <div className='paa-text2'><Text2 data={text2} /></div> : null}

      {steps ? <div className='paa-steps'><Steps data={steps} /></div> : null}

      {leftIconCard ? <div className='paa-left-icon-card'><LeftIconCard data={leftIconCard} /></div> : null}

      {bannerLeftText ? <div className='paa-banner-left-text'><BannerLeftText data={bannerLeftText} /></div> : null}

      {title3 ? <div className='paa-title3'><Title data={title3} /></div> : null}

      {twoColumnFullWidth ? <div className='paa-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default ProductAICApp
