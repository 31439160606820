import axios from 'axios'
import React, { useState } from 'react'

import {
  ColumnCardGrid2, HeaderHero, LeftContentImageCardBlock, LeftImageColumnCardsBlock, Text, Title, TwoColumnFullWidth
} from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'
import { Fade } from 'react-reveal'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function RetailB2C (props) {
  const [headerHero, setHeaderHero] = useState({})
  const [imageRightTitleDescriptionCard1, setImageRightTitleDescriptionCard1] = useState()
  const [imageRightTitleDescriptionCard2, setImageRightTitleDescriptionCard2] = useState()
  const [imageRightTitleDescriptionCard3, setImageRightTitleDescriptionCard3] = useState()
  const [leftContentImageCardBlock, setLeftContentImageCardBlock] = useState()
  const [leftImageColumnCardsBlock, setLeftImageColumnCardsBlock] = useState()
  const [text, setText] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [title3, setTitle3] = useState()
  const [title4, setTitle4] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Solution-Retail/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isImageRightTitleDescriptionCard1Set = false
            let isImageRightTitleDescriptionCard2Set = false
            let isImageRightTitleDescriptionCard3Set = false
            let isLeftContentImageCardBlockSet = false
            let isLeftImageColumnCardsBlockSet = false
            let isTextSet = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTitle3Set = false
            let isTitle4Set = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB4, __component)) {
                const { bread_crumbs: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_CONTENT_IMAGE_CARD_BLOCK, __component)) {
                if (!isLeftContentImageCardBlockSet) {
                  const block = slice || {}
                  isLeftContentImageCardBlockSet = true
                  setLeftContentImageCardBlock(block)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD2, __component)) {
                if (!isTextSet) {
                  const { note: text } = slice || {}
                  isTextSet = true
                  setText(text)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_IMAGE_COLUMN_CARDS_BLOCK, __component)) {
                if (!isLeftImageColumnCardsBlockSet) {
                  const block = slice || {}
                  isLeftImageColumnCardsBlockSet = true
                  setLeftImageColumnCardsBlock(block)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD2, __component)) {
                if (!isImageRightTitleDescriptionCard1Set) {
                  const { cards } = slice || {}
                  isImageRightTitleDescriptionCard1Set = true
                  setImageRightTitleDescriptionCard1(cards)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD3, __component)) {
                if (!isImageRightTitleDescriptionCard2Set) {
                  const { cards } = slice || {}
                  isImageRightTitleDescriptionCard2Set = true
                  setImageRightTitleDescriptionCard2(cards)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD4, __component)) {
                if (!isImageRightTitleDescriptionCard3Set) {
                  const { cards } = slice || {}
                  isImageRightTitleDescriptionCard3Set = true
                  setImageRightTitleDescriptionCard3(cards)
                }
              } else if (util.isComponent(ComponentTypes.FIRST_TITLE, __component)) {
                if (!isTitle1Set) {
                  const { title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE1, __component)) {
                if (!isTitle2Set) {
                  const { title } = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE2, __component)) {
                if (!isTitle3Set) {
                  const { title } = slice || {}
                  isTitle3Set = true
                  setTitle3(title)
                }
              } else if (util.isComponent(ComponentTypes.FOURTH_TITLE1, __component)) {
                if (!isTitle4Set) {
                  const { title } = slice || {}
                  isTitle4Set = true
                  setTitle4(title)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH3, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {title1 ? <div className='snr-title1'><Title data={title1} /></div> : null}

      {imageRightTitleDescriptionCard1
        ? <div className='snr-image-right-title-description-card1'><ColumnCardGrid2 data={imageRightTitleDescriptionCard1} /></div>
        : null}

      {title2 ? <div className='snr-title2'><Title data={title2} /></div> : null}

      {leftImageColumnCardsBlock
        ? <div className='snr-left-image-column-cards-block'><LeftImageColumnCardsBlock data={leftImageColumnCardsBlock} /></div>
        : null}

      {title3 ? <div className='snr-title3'><Title data={title3} /></div> : null}

      {imageRightTitleDescriptionCard2
        ? <div className='snr-image-right-title-description-card2'><ColumnCardGrid2 data={imageRightTitleDescriptionCard2} /></div>
        : null}

      {title4 ? <div className='snr-title4'><Title data={title4} /></div> : null}

      {imageRightTitleDescriptionCard3
        ? <div className='snr-image-right-title-description-card3'><ColumnCardGrid2 data={imageRightTitleDescriptionCard3} /></div>
        : null}

      {text ? <div className='snr-text'><Text data={text} /></div> : null}

      {leftContentImageCardBlock
        ? <Fade><div className='snr-left-content-image-card-block'><LeftContentImageCardBlock data={leftContentImageCardBlock} /></div></Fade>
        : null}

      {title1 ? <div className='snr-title5'><Title data={{ first_partial_title: 'Ready To Be ', span: 'Different?' }} /></div> : null}

      {twoColumnFullWidth ? <div className='snr-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default RetailB2C
