import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import LeftNestedListBlock from '../leftNestedListBlock'
import RightNestedListBlock from '../rightNestedListBlock'

import './styles.css'

function AxLeftRightNestedListBlock (props) {
  const { className, data } = props
  let { reverse } = props
  const blocks = Array.isArray(data) ? data : []
  reverse = typeof reverse === 'boolean' ? reverse : false

  return (
    <div className={clsx('ax-left-right-nested-list-block', className)}>
      {blocks.map((block, idx) => {
        const { id } = block

        return idx % 2 === (reverse ? 1 : 0)
          ? <LeftNestedListBlock key={id} data={block} />
          : <RightNestedListBlock key={id} data={block} />
      })}
    </div>
  )
}

AxLeftRightNestedListBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  reverse: PropTypes.bool
}

export default AxLeftRightNestedListBlock
