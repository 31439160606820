import axios from 'axios'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { Typography, Box } from '@mui/material'
import {
  BannerLeftText, BorderlessColumnCardGrid, ColumnCardGrid, CustomTabs, HeaderHero, LeftIconCard, LeftRightContentBlock,
  LeftRightNestedListBlock, RightListBlock, Steps, Text, Text2, Title, TitleWithButton, TitleWithButton2, TwoColumnCards,
  TwoColumnFullWidth
} from '../../components'
import { ComponentTypes } from '../../constants/component'
import { util } from '../../util'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import { Fade } from 'react-reveal'
import formatter from '../../util/formatter-v2'

import './styles.css'

function ProductAICComplete(props) {
  const [bannerLeftText, setBannerLeftText] = useState()
  const [borderlessColumnCardGrid, setBorderlessColumnCardGrid] = useState()
  const [customTabs, setCustomTabs] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [leftIconCard, setLeftIconCard] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [leftRightNestedListBlock, setLeftRightNestedListBlock] = useState()
  const [rightListBlocks, setRightListBlocks] = useState()
  const [steps, setSteps] = useState()
  const [text1, setText1] = useState()
  const [text2, setText2] = useState()
  const [threeColumnCard, setThreeColumnCard] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [title3, setTitle3] = useState()
  const [titleWithButton1, setTitleWithButton1] = useState()
  const [titleWithButton2, setTitleWithButton2] = useState()
  const [twoColumnCards, setTwoColumnCards] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()
  const [components, setComponents] = useState([])

  const structureSection = ({ image, description }) => {
    return (
      <div className='paa-chart-box'>
        <div className='image-box'>
          <Fade><img className='img' src={image} /></Fade>
        </div>

        <Box className='title' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />
      </div>
    )
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'shared.structure':
        return structureSection({
          image: `${process.env.REACT_APP_BASE_URL}${data?.image?.url}`,
          description: data.description
        })
      default:
        return null;
    }
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Product-Aic-Complete/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { page_product_aic_complete_slices: slices } = data

          setComponents(slices)

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isBannerLeftTextSet = false
            let isBorderlessColumnCardGridSet = false
            let isCustomTabsSet = false
            let isLeftIconCardSet = false
            let isLeftRightContentBlockSet = false
            let isLeftRightNestedListBlockSet = false
            let isRightListBlocksSet = false
            let isStepsSet = false
            let isTextSet1 = false
            let isTextSet2 = false
            let isThreeColumnCardSet = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTitle3Set = false
            let isTitleWithButton1Set = false
            let isTitleWithButton2Set = false
            let isTwoColumnCardsSet = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BANNER_LEFT_TEXT2, __component)) {
                if (!isBannerLeftTextSet) {
                  const bannerLeftText = slice || {}
                  isBannerLeftTextSet = true
                  setBannerLeftText(bannerLeftText)
                }
              } else if (util.isComponent(ComponentTypes.BREADCRUMB3, __component)) {
                const { brad_crumb: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.BORDERLESS_COLUMN_CARDS, __component)) {
                if (!isBorderlessColumnCardGridSet) {
                  const { comp_cards: columnCards } = slice
                  isBorderlessColumnCardGridSet = true
                  setBorderlessColumnCardGrid(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.COLUMN_CARDS1, __component)) {
                if (!isThreeColumnCardSet) {
                  const { cards: columnCards } = slice || {}
                  isThreeColumnCardSet = true
                  setThreeColumnCard(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK2, __component)) {
                if (!isLeftRightContentBlockSet) {
                  const { feat: contents } = slice || {}
                  isLeftRightContentBlockSet = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_CARDS, __component)) {
                if (!isTwoColumnCardsSet) {
                  const { comp_cards_with_title: columnCards } = slice
                  isTwoColumnCardsSet = true
                  setTwoColumnCards(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.CUSTOM_TABS, __component)) {
                if (!isCustomTabsSet) {
                  const { comp_custom_tab: tabs } = slice
                  isCustomTabsSet = true
                  setCustomTabs(tabs)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD, __component)) {
                if (!isLeftIconCardSet) {
                  const { comp_icon_text: card } = slice
                  isLeftIconCardSet = true
                  setLeftIconCard(card)
                }
              } else if (util.isComponent(ComponentTypes.RIGHT_LIST_BLOCK, __component)) {
                if (!isRightListBlocksSet) {
                  const { type: blocks } = slice || {}
                  isRightListBlocksSet = true
                  setRightListBlocks(blocks)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_NESTED_LIST_BLOCK1, __component)) {
                if (!isLeftRightNestedListBlockSet) {
                  const { block: blocks } = slice || {}
                  isLeftRightNestedListBlockSet = true
                  setLeftRightNestedListBlock(blocks)
                }
              } else if (util.isComponent(ComponentTypes.STEPS1, __component)) {
                if (!isStepsSet) {
                  const { step: steps } = slice || {}
                  isStepsSet = true
                  setSteps(steps)
                }
              } else if (util.isComponent(ComponentTypes.TEXT1, __component)) {
                if (!isTextSet1) {
                  const { comp_text: text } = slice || {}
                  isTextSet1 = true
                  setText1(text)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TEXT1, __component)) {
                if (!isTextSet2) {
                  const { comp_single_text: text } = slice || {}
                  isTextSet2 = true
                  setText2(text)
                }
              } else if (util.isComponent(ComponentTypes.TITLE1, __component)) {
                if (!isTitle1Set) {
                  const { comp_title: title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE1, __component)) {
                if (!isTitle2Set) {
                  const { comp_second_title: title } = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.TITLE2, __component)) {
                if (!isTitle3Set) {
                  const { comp_last_title: title } = slice || {}
                  isTitle3Set = true
                  setTitle3(title)
                }
              } else if (util.isComponent(ComponentTypes.TITLE_WITH_BUTTON1, __component)) {
                if (!isTitleWithButton1Set) {
                  isTitleWithButton1Set = true
                  setTitleWithButton1(slice)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE_WITH_BUTTON1, __component)) {
                if (!isTitleWithButton2Set) {
                  isTitleWithButton2Set = true
                  setTitleWithButton2(slice)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH1, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { column: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {customTabs ? <CustomTabs data={customTabs} /> : null}

      <div id='package' />

      {title1 ? <div className='pac-title1'><Title data={title1} /></div> : null}

      {borderlessColumnCardGrid
        ? (
          <div className='pac-borderless-column-card-grid'>
            <BorderlessColumnCardGrid data={borderlessColumnCardGrid} />
          </div>
        )
        : null}

      <div id='features' />

      {Array.isArray(rightListBlocks)
        ? (
          <div className='pac-right-list-block'>
            {rightListBlocks.map((block) => {
              const { id } = block

              return <RightListBlock className='item' key={id} data={block} />
            })}
          </div>
        )
        : null}

      <div className='pac-video-box'>
        <div className='flex-box'>
          <div className='left-box'>
            <Typography className='title'>Become a pro.</Typography>

            <Typography className='description'>
              Reach out to your customers and make your online store accessible both on the web and on app. Create a seamless shopping experience for your shoppers wherever they are and whenever they want to.
              <br /><br />
              Increase your customers’ trust and recognition with web presence but don’t skimp and only go for mobile-responsive website as it doesn’t utilize the full potential of the mobile environment. Become a pro e-commerce owner and get your own app store too.
            </Typography>

            <div className='list-box'>
              <div className='item'>
                <div className='image-box'>
                  <img className='img' alt='' src='/icon/arrow-right-circle.svg' />
                </div>

                <div>Attract new shoppers via the website.</div>
              </div>

              <div className='item'>
                <div className='image-box'>
                  <img className='img' alt='' src='/icon/arrow-right-circle.svg' />
                </div>

                <div>Improve customer retention rate especially on the app.</div>
              </div>

              <div className='item'>
                <div className='image-box'>
                  <img className='img' alt='' src='/icon/arrow-right-circle.svg' />
                </div>

                <div>Reduce abandoned cart issues with persistent cart on both web and app.</div>
              </div>

              <div className='item'>
                <div className='image-box'>
                  <img className='img' alt='' src='/icon/arrow-right-circle.svg' />
                </div>

                <div>Increase sales through visibility and accessibility by making your online store available on all major platforms.</div>
              </div>
            </div>
          </div>

          <div className='right-box'>
            <ReactPlayer
              className='react-player'
              url='https://www.youtube.com/watch?v=VEPg_6k5kg4'
              height='290px'
              width='100%'
              style={{
                maxWidth: '420px'
              }}
            />
          </div>
        </div>
      </div>

      {titleWithButton1 ? <div className='pac-title-with-button1'><TitleWithButton data={titleWithButton1} /></div> : null}

      {twoColumnCards ? <div className='pac-two-column-cards'><TwoColumnCards data={twoColumnCards} /></div> : null}

      {titleWithButton2 ? <div className='pac-title-with-button2'><TitleWithButton2 data={titleWithButton2} /></div> : null}

      {text1 ? <div className='pac-text1'><Text data={text1} /></div> : null}

      {threeColumnCard ? <div className='pac-column-card-grid'><ColumnCardGrid data={threeColumnCard} tick='/icon/tick-solid-blue.png' /></div> : null}

      {!!components.find(item => item.__component === 'shared.structure') && renderComponent(components.find(item => item.__component === 'shared.structure'))}

      <div id='benefits' />

      {leftRightNestedListBlock ? <div className='pac-left-right-nested-list-block'><LeftRightNestedListBlock data={leftRightNestedListBlock} /></div> : null}

      {title2 ? <div className='pac-title2'><Title data={title2} /></div> : null}

      {leftRightContentBlock ? <div className='pac-left-right-content-block'><LeftRightContentBlock className='pac-lrcb' data={leftRightContentBlock} /></div> : null}

      <div id='getting-started' />

      {text2 ? <div className='pac-text2'><Text2 data={text2} /></div> : null}

      {steps ? <div className='pac-steps'><Steps data={steps} /></div> : null}

      {leftIconCard ? <div className='pac-left-icon-card'><LeftIconCard data={leftIconCard} /></div> : null}

      {bannerLeftText ? <div className='pac-banner-left-text'><BannerLeftText data={bannerLeftText} /></div> : null}

      {title3 ? <div className='pac-title3'><Title data={title3} /></div> : null}

      {twoColumnFullWidth ? <div className='pac-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default ProductAICComplete
