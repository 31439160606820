import { useState } from 'react'

const useLoader = () => {
  let timer;
  const [isLoading, setIsLoading] = useState(false)
  const showLoader = () => setIsLoading(true)
  const hideLoader = (delay = 1500) => {
    timer = setTimeout(() => {
      if (timer) clearTimeout(timer)
      setIsLoading(false)
    }, delay)
  }
  return [isLoading, showLoader, hideLoader]
}

export default useLoader