import useLoadingEffect from './hooks/useLoadingEffect'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from './helpers/theme';
import { useState, Fragment } from "react";
import { Box } from "@mui/material"
import Title from './components/title'
import formatter from './util/formatter-v2'

const RouteComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const [components, setComponents] = useState([])

  const getData = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}${location.pathname}`
      const { data } = await axios.get(url)
      setComponents(data.slices)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 404) history.replace('/404')
      }
      throw error
    }
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-upcoming-slices.content':
        return (
          <>
            {data.title || data.subtitle ? <Title data={{ first_partial_title: data.title, description: data.sub_title }} /> : null}

            <Box sx={{ marginTop: 5, 'img': { maxWidth: '100%' } }}>
              {data.image ? <img src={`${process.env.REACT_APP_BASE_URL}${data.image.url}`} /> : null}

              {data.text ? <Box sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.text) }} /> : null}
            </Box>
          </>
        )
      default:
        return null;
    }
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{ ...containerSpacing }}>
        {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
      </Box>
    </ThemeProvider>
  )
}

export default RouteComponent