import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { Slide } from 'react-reveal'

import './styles.css'

function AxColumnCardGrid2 (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <Slide cascade down>
      <div className={clsx('ax-column-card-grid2', className)}>
        {columnCards.map((card) => {
          const {
            id, description, first_partial_description: startDesc, image, second_partial_description: endDesc, span, title
          } = card
          const { url: imageUrl } = image || {}

          return (
            <div className='item' key={id}>
              <div className='top-box'>
                {imageUrl
                  ? (
                    <div className='image-box'>
                      <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                      {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                    </div>
                  )
                  : null}

                {title ? <Typography className='title'>{title}</Typography> : null}
              </div>

              <div className='text-box'>
                {startDesc || span || endDesc
                  ? (
                    <Typography className='description'>
                      {startDesc}{span ? <span className='span'>{span}</span> : null}{endDesc}
                    </Typography>
                  )
                  : null}

                {description ? <Typography className='description'>{description}</Typography> : null}
              </div>
            </div>
          )
        })}
      </div>
    </Slide>
  )
}

AxColumnCardGrid2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxColumnCardGrid2
