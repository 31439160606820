import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { Fade } from 'react-reveal'
import { getReactRevealXStyles } from '../../util/react-reveal-styles'

import './styles.css'

function AxImageTitleListTwoColumnCard (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <div className={clsx('ax-image-title-list-two-column-card', className)}>
      {columnCards.map((card, idx) => {
        const { id, icon, texts, title } = card
        const { url: iconUrl } = icon || {}
        const list = Array.isArray(texts) ? texts : []

        return (
          <Fade {...getReactRevealXStyles(idx)}>
            <div className='card' key={id}>
              {iconUrl
                ? (
                  <div className='image-box'>
                    <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + iconUrl} />
                    {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + iconUrl} /> */}
                  </div>
                )
                : null}

              <div className='text-box'>
                {title ? <Typography className='title'>{title}</Typography> : null}

                {list.length > 0
                  ? (
                    <div className='list'>
                      {list.map((item) => {
                        const { id, text } = item

                        return (
                          <div className='item' key={`${idx}-${id}`}>
                            <div className='image-box'>
                              <img className='img' alt='' src='/icon/arrow-right-circle.svg' />
                            </div>

                            <div className='text'>{text}</div>
                          </div>
                        )
                      })}
                    </div>
                  )
                  : null}
              </div>
            </div>
          </Fade>
        )
      })}
    </div>
  )
}

AxImageTitleListTwoColumnCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxImageTitleListTwoColumnCard
