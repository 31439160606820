import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Title from '../../components/title'
import { useHistory } from "react-router-dom"
import PostInfo from './components/post-info'
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import useLoadingEffect from '../../hooks/useLoadingEffect'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios'
import { useState } from 'react'
import { format } from 'date-fns'
import { Fade } from 'react-reveal'
import useQuery from '../../hooks/useQuery'
import { LoaderContext } from "../../helpers/context";
import { useContext } from "react";

const Blog = () => {
  let page = parseInt(useQuery().get('page')) || 1
  const history = useHistory()
  const title = { first_partial_title: 'Blog' }
  const [data, setData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const pageSize = 6
  const { hideLoader, showLoader } = useContext(LoaderContext)

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/blogs`
    const [{ data: listOfPost = [] }, { data: totalPostResponse }] = await Promise.all([axios.get(`${url}?page=${page}&pageSize=${pageSize}`), axios.get(`${url}/count`)])

    setData(listOfPost.map(({ title, author, date, slug, content, image }) => {
      return {
        title,
        author,
        date: format(new Date(date), 'd LLL yyyy'),
        slug,
        summary: content.slice(0, 200),
        image: image?.url ? `${process.env.REACT_APP_BASE_URL}${image.url}` : null
      }
    }))
    setTotalPage(Math.ceil(parseInt(totalPostResponse) / pageSize))
  }

  const onNavigatePage = async (newPage) => {
    page = newPage
    history.push(`/blog?page=${page}`)
    showLoader()
    await getData()
    hideLoader()
  }

  useLoadingEffect(getData)

  return (
    <>
      <ThemeProvider theme={CustomTheme}>
        <Box sx={{ ...containerSpacing }}>
          <Box sx={{ marginBottom: 5 }}><Title data={title} /></Box>
          {
            data.length > 0 ?
              <>
                <Fade cascade>
                  <Box sx={{ display: "grid", gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", custom: "repeat(3, 1fr)" }, marginBottom: 5, columnGap: 1, rowGap: 3 }}>
                    {data.map(({ title, image, author, date, summary, slug }, index) => (
                      <Box key={`blog-${index}`} sx={{ '&:hover > .MuiBox-root': { boxShadow: '0px 0px 20px 2px rgba(184,184,184,1)', borderRadius: '3px', } }}>
                        <Box key={index} sx={{ width: '100%', transition: 'box-shadow 0.3s ease-in' }}>
                          <Box sx={{ p: 1 }}>
                            <Box height={200} sx={{ backgroundImage: image ? `url('${image}')` : undefined, backgroundColor: "#efefef", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', color: '#234171', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{image ? null : 'No Image Found'}</Box>
                            <Box>
                              <Typography gutterBottom variant="body2" sx={{ fontSize: '1.75rem', fontWeight: 'bold', marginTop: 3, color: '#487FED' }}>
                                {title}
                              </Typography>

                              <Grid container sx={{ marginBottom: 3 }}>
                                <Grid item container xs={6}>
                                  <PostInfo body={`by ${author}`} />
                                </Grid>

                                <Grid item container xs={6}>
                                  <PostInfo body={date} />
                                </Grid>
                              </Grid>

                              <Typography sx={{ color: '#234171', letterSpacing: 0.5, maxHeight: '70px', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: 3 }}>
                                {summary}
                              </Typography>

                              <Button variant="contained" size="large" disableElevation sx={{ backgroundColor: '#487FED' }} onClick={() => { history.push(`/blog/${slug}`) }}>
                                Read More
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Fade>

                <Stack spacing={2}>
                  <Pagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event, page) => onNavigatePage(page)} sx={
                    {
                      margin: 'auto', '.MuiSvgIcon-root': { fontSize: '2.75rem', color: '#BBBBBB' },
                      '.MuiPaginationItem-previousNext': { border: 'none' },
                      '.MuiPaginationItem-page': { color: '#234171', marginLeft: 1, marginRight: 1, '&:hover': { color: '#fff', backgroundColor: '#487FED', borderColor: '#487FED' } },
                      '.Mui-selected': { color: '#fff', backgroundColor: '#487FED !important', borderColor: '#487FED', }
                    }
                  } />
                </Stack>
              </>
              :
              <Typography textAlign="center">No records found</Typography>
          }

        </Box>
      </ThemeProvider>
    </>
  );
}


export default Blog
