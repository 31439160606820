import { Button, Box } from '@mui/material';
import formatter from '../../../util/formatter-v2'
import './submission-success.scss'

const SubmissionSuccess = ({ image, first_partial_title, span, description, buttons = [], note }) => {
  return (
    <Box id="submission-success" className="text-center py-5">
      <img className="mb-1" src={image} style={{ maxWidth: '371px' }} />

      <Box className="huge">
        <b className="color-custom-primary-light">{first_partial_title}</b><b className="color-custom-primary-dark">&nbsp;{span}</b>
      </Box>

      <Box className="color-custom-primary-light" sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />

      {
        buttons.map((button, index) => {
          return (
            <Box key={`button-box-${index}`} sx={{ marginBottom: 2 }}>
              <Box className="color-custom-tertiary" sx={{ marginBottom: 2 }}>{button.description}</Box>
              <Button variant="contained" size="large" onClick={
                () => {
                  window.open(button.button_href, '_blank')
                }
              } disableElevation>{button.button_label}</Button>
            </Box>
          )
        })
      }

      <Box className="color-custom-tertiary" sx={{ lineHeight: 1.5, marginTop: 5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(note) }} />
    </Box >
  )
}

export default SubmissionSuccess