import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'

function AxText2 (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { description, title } = data
  const renderText = function (text) {
    return (
      <Typography
        className='main'
        sx={{
          color: '#4378DF',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '40px',
          lineHeight: '47px',
          textAlign: 'center'
        }}
      >
        {text}
      </Typography>
    )
  }

  return (
    <div className={clsx('ax-text2', className)}>
      {description ? renderText(description) : null}

      {title ? renderText(title) : null}
    </div>
  )
}

AxText2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxText2
