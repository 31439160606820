import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { Slide } from 'react-reveal'
import { getReactRevealYStyles } from '../../util/react-reveal-styles'

import './styles.css'

function AxBorderlessColumnCardGrid (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <div className={clsx('ax-borderless-column-card-grid', className)}>
      {columnCards.map((card, index) => {
        const { id, description, image } = card
        const { url: imageUrl } = image || {}

        return (
          <Slide {...getReactRevealYStyles(index)}>
            <div key={id}>
              <div className='card-box'>
                {imageUrl
                  ? (
                    <div className='image-box'>
                      <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                      {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                    </div>
                  )
                  : null}

                {description ? <Typography className='description'>{description}</Typography> : null}
              </div>
            </div>
          </Slide>
        )
      })}
    </div>
  )
}

AxBorderlessColumnCardGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxBorderlessColumnCardGrid
