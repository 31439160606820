import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'
import { Fade } from 'react-reveal'

import './styles.css'

function AxBorderlessColumnCardGrid (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { icons_texts: list, icons_and_texts: list1, main_icon: mainIcon, title } = data
  const items = Array.isArray(list) ? list : Array.isArray(list1) ? list1 : []
  const image = Array.isArray(mainIcon) ? mainIcon[0] || {} : mainIcon
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-right-list-box', className)}>
      <div className='left-box'>
        <div className='box'>
          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
          {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}

          <Typography className='title'>{title}</Typography>
        </div>
      </div>

      <div className='right-box'>
        <div className='list-box'>
          <Fade top cascade >
            {Array.isArray(items)
              ? items.map((item, idx) => {
                const { id, icon, text } = item
                const { url: imageUrl } = icon || {}

                return (
                  <div className={clsx('item', idx < (Math.floor(items.length / 3) * 3) ? 'bordered' : '')} key={`right-list-block-${id}`}>
                    <div className='box'>
                      <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                      {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}

                      <Typography className='text'>{text}</Typography>
                    </div>
                  </div>
                )
              })
              : null}
          </Fade>
        </div>
      </div>
    </div>
  )
}

AxBorderlessColumnCardGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxBorderlessColumnCardGrid
