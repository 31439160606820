import axios from 'axios'
import React, { useState } from 'react'

import { Typography } from '@mui/material'
import { ColumnCardFlex, ColumnCardGrid2, HeaderHero, RightContentBlock, Title } from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

import './styles.css'

function AppCare (props) {
  const [headerHero, setHeaderHero] = useState({})
  const [imageTitleDescriptionCard, setImageTitleDescriptionCard] = useState()
  const [imageRightTitleDescriptionCard, setImageRightTitleDescriptionCard] = useState()
  const [note, setNote] = useState()
  const [rightContentBlock, setRightContentBlock] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
 
  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Services-Axolot-App-Care/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isImageTitleDescriptionCardSet = false
            let isImageRightTitleDescriptionCardSet = false
            let isNoteSet = false
            let isRightContentBlockSet = false
            let isTitle1Set = false
            let isTitle2Set = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.RIGHT_CONTENT_BLOCK, __component)) {
                if (!isRightContentBlockSet) {
                  const content = slice || {}
                  isRightContentBlockSet = true
                  setRightContentBlock(content)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_TITLE_DESCRIPTION_CARD, __component)) {
                if (!isImageTitleDescriptionCardSet) {
                  const { cards } = slice || {}
                  isImageTitleDescriptionCardSet = true
                  setImageTitleDescriptionCard(cards)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD, __component)) {
                if (!isImageRightTitleDescriptionCardSet) {
                  const { cards } = slice || {}
                  isImageRightTitleDescriptionCardSet = true
                  setImageRightTitleDescriptionCard(cards)
                }
              } else if (util.isComponent(ComponentTypes.NOTE, __component)) {
                if (!isNoteSet) {
                  const { note } = slice || {}
                  isNoteSet = true
                  setNote(note)
                }
              } else if (util.isComponent(ComponentTypes.FIRST_TITLE1, __component)) {
                if (!isTitle1Set) {
                  const title = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE, __component)) {
                if (!isTitle2Set) {
                  const title = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {rightContentBlock ? <div className='sac-right-content-block'><RightContentBlock data={rightContentBlock} /></div> : null}

      {title1 ? <div className='sac-title1'><Title data={title1} /></div> : null}

      {imageTitleDescriptionCard
        ? <div className='sac-image-title-description-card'><ColumnCardFlex data={imageTitleDescriptionCard} /></div>
        : null}

      {title2 ? <div className='sac-title2'><Title data={title2} /></div> : null}

      {imageRightTitleDescriptionCard
        ? <div className='sac-image-right-title-description-card'><ColumnCardGrid2 data={imageRightTitleDescriptionCard} /></div>
        : null}

      {note ? <div className='sac-note'><Typography className='text'>{note}</Typography></div> : null}
    </div>
  )
}

export default AppCare
