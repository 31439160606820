import { Typography, Box, Card, CardContent, Stack } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import Title from '../../components/title'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import axios from "axios";
import { useState, Fragment } from "react";
import formatter from '../../util/formatter-v2'

const About = () => {
  const [components, setComponents] = useState([])

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-about-us`
    const { data } = await axios.get(url)
    setComponents(data.slices)
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-about-us-slices.image':
        return <img className="mb-5" src={`${process.env.REACT_APP_BASE_URL}${data.image.url}`} style={{ maxWidth: "400px", width: "100%", margin: "auto", display: "block" }} />;
      case 'page-about-us-slices.title':
        return <Title data={{ first_partial_title: data.title }} />
      case 'page-about-us-slices.description':
        return <Typography mb={5} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.text) }} />
      case 'page-about-us-slices.services':
        return data.content.map((item, index) => <Fragment key={`card${index}`}>{renderCard(item)}</Fragment>)
      default:
        return null;
    }
  }

  const renderCard = (data) => {
    return <Card sx={{ marginBottom: 5 }}>
      <CardContent>
        {
          data.title || data.logo ?
            <Stack direction="row" alignItems="center" mb={2}>
              {data.title ? <Typography variant="h6" mr={2}>{data.title}</Typography> : null}
              {data.logo ? <img src={`${process.env.REACT_APP_BASE_URL}${data.logo.url}`} style={{ maxWidth: '150px', maxHeight: '80px' }} /> : null}
            </Stack>
            : null
        }

        <Typography dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.text) }} />
      </CardContent>
    </Card>
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{ ...containerSpacing }}>
        {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
      </Box>
    </ThemeProvider>
  )
}

export default About