import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { Fade } from 'react-reveal'

import './styles.css'

function AxTwoColumnCardWithButton (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <Fade bottom cascade>
      <div className={clsx('ax-two-column-card-with-button', className)}>
          {columnCards.map((card) => {
            const { id, button, description, image, sub_title: subTitle, title } = card
            const { link } = button || {}
            const { url: imageUrl } = image || {}
            const { href: buttonHref, label: buttonLabel, target: buttonTarget } = link || {}

            return (
              <div className='card' key={id}>
                <div className='top-box'>
                  {imageUrl
                    ? (
                      <div className='image-box'>
                        <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                      </div>
                    )
                    : null}

                  <div className='text-box'>
                    {title ? <Typography className='title'>{title}</Typography> : null}

                    {subTitle ? <Typography className='subtitle'>{subTitle}</Typography> : null}
                  </div>
                </div>

                {description ? <Typography className='description'>{description}</Typography> : null}

                {buttonLabel || buttonHref ? <Button className='btn-action' color='blue' href={buttonHref} target={buttonTarget || '_self'} type='solid'>{buttonLabel || buttonHref}</Button> : null}
              </div>
            )
          })}
      </div>
    </Fade>
  )
}

AxTwoColumnCardWithButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxTwoColumnCardWithButton
