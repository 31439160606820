import axios from 'axios'
import React, { useState } from 'react'

import { Loop as LoopIcon } from '@mui/icons-material'
import { Alert, FormHelperText, MenuItem, Snackbar } from '@mui/material'
import { Button, FormControlLabel, HeaderHero, Select, TextField, Title } from '../../components'
import { ComponentTypes, IndustriesList } from '../../constants'
import { util, validator } from '../../util'
import SubmissionSuccess from './components/submission-success'
import { Fade } from 'react-reveal'
import useLoadingEffect from '../../hooks/useLoadingEffect'

import './styles.css'

function Demo(props) {
  const [formErrors, setFormErrors] = useState({})
  const [formMessages, setFormMessages] = useState({})
  const [formValues, setFormValues] = useState({})
  const [headerHero, setHeaderHero] = useState({})
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [title, setTitle] = useState({})
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})

  const changeFormValue = (e) => {
    const { target } = e || {}
    const { name, value } = target || {}
    const error = validateFormValue(name, value)
    setFormValues({ ...formValues, [name]: value })

    if (error) {
      setFormErrors({ ...formErrors, [name]: true })
      setFormMessages({ ...formMessages, [name]: error })
    } else {
      clearFormError(name)
    }
  }

  const handleHideError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowError(false)
  }

  const handleShowError = () => {
    setShowError(true)
  }

  const handleSubmit = () => {
    if (validateFormValues()) {
      handleShowError()
      return
    }

    setLoading(true)
    const { companyName, email, firstName, industry, lastName, phoneNo } = formValues
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/demos`,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phoneNo,
          business_industry: industry,
          company_name: companyName
        }
      )
      .then(() => {
        setLoading(false)
        setShowSuccess(true)
      })
      .catch((err) => {
        setLoading(false)

        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(err.response)
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error(err.message)
        }

        handleShowError()
      })
  }

  const renderSubmissionSuccessComponent = (data) => {
    const props = {
      image: `${process.env.REACT_APP_BASE_URL}${data.image?.url}`,
      first_partial_title: data.first_partial_title,
      span: data.span,
      description: data.description,
      buttons: data.links,
      note: data.note
    }

    return <SubmissionSuccess {...props} />
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Download-Demo/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-download-demo-slices.acknowledgement'))
          setComponents(data.slices.filter(item => item.__component !== 'page-download-demo-slices.acknowledgement'))

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isTitleSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
                if (!isTitleSet) {
                  const title = slice || {}
                  isTitleSet = true
                  setTitle(title)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleHideError}
        open={showError}
      >
        <Alert onClose={handleHideError} severity='error' sx={{ width: '100%' }}>
          Unable to submit successfully. {hasFormErrors() ? 'Please ensure all values are entered correctly.' : 'Please try again later.'}
        </Alert>
      </Snackbar>

      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {showSuccess
        ? <Fade>{renderSubmissionSuccessComponent(submissionSuccessComponent)}</Fade>
        : (
          <>
            {title ? <div className='d-title'><Title data={title} /></div> : null}

            <div className='d-box'>
              <div className='d-form-grid col2'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.firstName}
                      helperText={formMessages.firstName}
                      name='firstName'
                      onChange={changeFormValue}
                      placeholder='Enter Your First Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.firstName || ''}
                      variant='outlined'
                    />
                  }
                  label='First Name*'
                  sx={{ width: '100%' }}
                />

                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.lastName}
                      helperText={formMessages.lastName}
                      name='lastName'
                      onChange={changeFormValue}
                      placeholder='Enter Your Last Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.lastName || ''}
                      variant='outlined'
                    />
                  }
                  label='Last Name*'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='d-form-grid col2'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.email}
                      helperText={formMessages.email}
                      name='email'
                      onChange={changeFormValue}
                      placeholder='Enter Your E-mail'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.email || ''}
                      variant='outlined'
                    />
                  }
                  label='E-mail*'
                  sx={{ width: '100%' }}
                />

                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.phoneNo}
                      helperText={formMessages.phoneNo}
                      name='phoneNo'
                      onChange={changeFormValue}
                      placeholder='e.g. 0123456789'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.phoneNo || ''}
                      variant='outlined'
                    />
                  }
                  label='Telephone No.'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='d-form-grid'>
                <FormControlLabel
                  control={
                    <>
                      {formMessages.industry ? <FormHelperText error>{formMessages.industry}</FormHelperText> : null}

                      <Select
                        displayEmpty
                        name='industry'
                        onChange={changeFormValue}
                        renderValue={(selected) => {
                          if (selected) {
                            return selected
                          }

                          return <span style={{ color: '#808080' }}>Choose the most relevant answer</span>
                        }}
                        required
                        sx={{ minWidth: '100%' }}
                        value={formValues.industry || ''}
                      >
                        {IndustriesList.map(({ category, name, value }, idx) => (
                          <MenuItem key={`${idx}-${value}`} value={value}>
                            <span style={{ fontWeight: 'bold' }}>{category}</span>&nbsp;-&nbsp;{name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  }
                  label='Business Industry*'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='d-form-grid'>
                <FormControlLabel
                  control={
                    <TextField
                      error={formErrors.companyName}
                      helperText={formMessages.companyName}
                      name='companyName'
                      onChange={changeFormValue}
                      placeholder='Enter Your Company Name'
                      required
                      sx={{ width: '100%' }}
                      value={formValues.companyName || ''}
                      variant='outlined'
                    />
                  }
                  label='Company Name*'
                  sx={{ width: '100%' }}
                />
              </div>

              <div className='note'>*compulsory field</div>

              {/* <div>
                Recaptcha
              </div> */}

              <div className='button-box'>
                <Button disabled={loading} onClick={handleSubmit} type='solid'>
                  Submit{loading ? <LoopIcon className='cu-rotating' /> : null}
                </Button>
              </div>
            </div>
          </>
        )}
    </div>
  )

  function clearFormError(name) {
    delete formErrors[name]
    delete formMessages[name]
    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
  }

  function getFormValidator() {
    return {
      firstName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter First Name'
        }
      },
      lastName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Last Name'
        }
      },
      email(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter E-mail'
        } else if (!validator.isEmail(value)) {
          return 'E-mail is invalid'
        }
      },
      phoneNo(value) {
        if (value && !validator.isDigit(value)) {
          return 'Telephone No. can only contain numbers'
        }
      },
      industry(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please choose the most relevant answer'
        }
      },
      companyName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Company Name'
        }
      }
    }
  }

  function hasFormErrors() {
    return Object.keys(formErrors).length > 0
  }

  function validateFormValue(name, value) {
    const formValidator = getFormValidator()
    const validate = formValidator[name]

    if (typeof validate === 'function') {
      return validate(value)
    }
  }

  function validateFormValues() {
    const fields = ['firstName', 'lastName', 'email', 'phoneNo', 'industry', 'companyName']
    const formErrors = {}
    const formMessages = {}
    let errorCount = 0

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const value = formValues[field]
      const error = validateFormValue(field, value)

      if (error) {
        formErrors[field] = true
        formMessages[field] = error
        errorCount += 1
      } else {
        delete formErrors[field]
        delete formMessages[field]
      }
    }

    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
    return errorCount > 0
  }
}

export default Demo
