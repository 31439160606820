import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { Fade } from 'react-reveal'

import './styles.css'

function AxColumnCardFlex (props) {
  const { className, data } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <Fade cascade down>
      <div className={clsx('ax-column-card-flex', className)}>
        {columnCards.map((card) => {
          const { id, description, image, title } = card
          const { url: imageUrl } = image || {}

          return (
            <div className='item' key={id}>
              {imageUrl
                ? (
                  <div className='image-box'>
                    <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl}/>
                    {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                  </div>
                )
                : null}

              <div className='text-box'>
                {title ? <Typography className='title'>{title}</Typography> : null}

                {description ? <Typography className='description'>{description}</Typography> : null}
              </div>
            </div>
          )
        })}
      </div>
    </Fade>
  )
}

AxColumnCardFlex.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxColumnCardFlex
