import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import LeftContentBlock from '../leftContentBlock'
import RightContentBlock from '../rightContentBlock'

import './styles.css'

function AxLeftRightContentBlock (props) {
  const { className, data, listIcon } = props
  let { reverse } = props
  const blocks = Array.isArray(data) ? data : []
  reverse = typeof reverse === 'boolean' ? reverse : false

  return (
    <div className={clsx('ax-left-right-content-block', className)}>
      {blocks.map((block, idx) => {
        const { id } = block

        return idx % 2 === (reverse ? 1 : 0)
          ? <LeftContentBlock key={id} data={block} listIcon={listIcon} />
          : <RightContentBlock key={id} data={block} listIcon={listIcon} />
      })}
    </div>
  )
}

AxLeftRightContentBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array,
  reverse: PropTypes.bool
}

export default AxLeftRightContentBlock
