import axios from 'axios'
import BigNumber from 'bignumber.js'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { createPortal } from 'react-dom'

import {
  Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Slide, Switch, Typography, Box
} from '@mui/material'
import {
  BannerLeftText, Button, CentreBanner, ColumnCardGrid, ImageBlock, LeftContentCardBlock, RightContentCardBlock, Title,
  TwoColumnCardWithButton
} from '../../components'
import { ComponentTypes } from '../../constants/component'
import { util } from '../../util'
import { Fade, Slide as SlideReveal } from 'react-reveal'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import formatter from '../../util/formatter-v2'

import './styles.css'
import { Link } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function HomePage(props) {
  const [acceptAllCookies, setAcceptAllCookies] = useState(true)
  const [bannerLeftText, setBannerLeftText] = useState()
  const [centreBanner, setCentreBanner] = useState()
  const [imageBlock, setImageBlock] = useState()
  const [leftContentCardBlock, setLeftContentCardBlock] = useState()
  const [rightContentCardBlock, setRightContentCardBlock] = useState()
  const [showCookieConsent, setShowCookieConsent] = useState(getCookie('user_cookie_consent') !== '1')
  const [showCookieConsentDialog, setShowCookieConsentDialog] = useState(false)
  const [threeColumnCard, setThreeColumnCard] = useState()
  const [title1, setTitle1] = useState()
  const [title2, setTitle2] = useState()
  const [title3, setTitle3] = useState()
  const [twoColumnCardWithButton, setTwoColumnCardWithButton] = useState()
  const [components, setComponents] = useState([])

  const changeAcceptAllCookies = (e) => {
    const { target } = e || {}
    const { checked } = target || {}
    setAcceptAllCookies(checked)
  }

  const handleCloseDialog = (justCloseDialog = false) => {
    if (!justCloseDialog) {
      if (acceptAllCookies) {
        handleAcceptCookie()
      } else {
        removeCookie('user_cookie_consent')
      }
    }

    setShowCookieConsentDialog(false)
  }

  const handleShowDialog = () => {
    setShowCookieConsentDialog(true)
  }

  const learnMoreSection = ({ image, to, first_partial_title, span, second_partial_title }) => {
    return (
      <>
        <Typography className='hp-title4'>
          {first_partial_title} <span style={{ color: '#4378DF' }}> {span}</span> {second_partial_title}
        </Typography>

        <Link className='hp-img-join-banner' to={to}>
          <Fade>
            <img
              className='hp-img-join'
              alt='Learn More'
              src={image}
            />
          </Fade>
        </Link>
      </>
    )
  }

  const facebookSection = (data) => {
    return (
      <Box className='hp-two-column-social'>
        <Box className='grid-box'>
          {data.map((item, index) => facebookCard({
            backgroundImage: `${process.env.REACT_APP_BASE_URL}${item.background_image.url}`,
            title: item.title,
            description: item.description,
            buttonLabel: item.button.label,
            buttonTo: item.button.href,
            type: index % 2 === 0 ? 'left' : 'right'
          }))}
        </Box>
      </Box>
    )
  }

  const facebookCard = ({ backgroundImage, title, description, buttonLabel, buttonTo, type }) => {
    const className = {
      card: true,
      [type]: type === type,
    }

    return (
      <Card className={className} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <Fade>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
            <Typography className='title' mb={2}>{title}</Typography>

            <Typography className='description'>{description}</Typography>

            <Button className='btn-action' href={buttonTo} target="_blank" sx={{ marginTop: "auto" }}>{buttonLabel}</Button>
          </div>
        </Fade>
      </Card>
    )
  }

  const bannerSection = ({ first_partial_title, span, second_partial_title, description, button_label, button_href, image }) => {
    return (
      <div className='hp-main-banner'>
        <SlideReveal top>
          <div className='text-box'>
            <div className='title'>{first_partial_title}<span style={{ color: '#4177dd' }}>{span}</span> {second_partial_title}</div>

            <Box className='subtitle' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />

            <Button className='btn-action' href={button_href}>{button_label}</Button>
          </div>

        </SlideReveal>

        <SlideReveal right>
          <div className='image-box'>
            <img className='img-banner' src={image} />
          </div>
        </SlideReveal>
      </div>
    )
  }

  const logoSection = ({ image }) => {
    return (
      <div className='hp-img-aic-service'><img className='img' src={image} /></div>
    )
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'home-page-slices.ambassador':
        return learnMoreSection({
          first_partial_title: data.first_partial_title,
          span: data.span,
          second_partial_title: data.second_partial_title,
          image: `${process.env.REACT_APP_BASE_URL}${data.image?.url}`,
          to: data.button_href,
        })
      case 'home-page-slices.comp-facebook-cards':
        return facebookSection(data.comp_facebook_cards)
      case 'home-page-slices.solution':
        return bannerSection({
          ...data,
          image: `${process.env.REACT_APP_BASE_URL}${data.image?.url}`,
        })
      case 'home-page-slices.logo':
        return logoSection({
          image: `${process.env.REACT_APP_BASE_URL}${data?.logo?.url}`,
        })
      default:
        return null
    }
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Home/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { page_home_slices: slices } = data
          setComponents(slices)

          if (Array.isArray(slices)) {
            let isBannerLeftTextSet = false
            let isCentreBannerSet = false
            let isImageBlockSet = false
            let isLeftContentCardBlockSet = false
            let isRightContentCardBlockSet = false
            let isThreeColumnCardSet = false
            let isTitle1Set = false
            let isTitle2Set = false
            let isTitle3Set = false
            let isTwoColumnCardWithButtonSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BANNER_LEFT_TEXT, __component)) {
                if (!isBannerLeftTextSet) {
                  const { comp_banner_left_content: bannerLeftText } = slice || {}
                  isBannerLeftTextSet = true
                  setBannerLeftText(bannerLeftText)
                }
              } else if (util.isComponent(ComponentTypes.CENTER_BANNER, __component)) {
                if (!isCentreBannerSet) {
                  const { comp_center_banner: centreBanner } = slice || {}
                  isCentreBannerSet = true
                  setCentreBanner(centreBanner)
                }
              } else if (util.isComponent(ComponentTypes.COLUMN_CARDS, __component)) {
                if (!isThreeColumnCardSet) {
                  const { collect_column_cards: columnCards } = slice || {}
                  isThreeColumnCardSet = true
                  setThreeColumnCard(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_CARD_WITH_BUTTON, __component)) {
                if (!isTwoColumnCardWithButtonSet) {
                  const { comp_two_column_cards: columnCards } = slice || {}
                  isTwoColumnCardWithButtonSet = true
                  setTwoColumnCardWithButton(columnCards)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_CONTENT_CARD_BLOCK, __component)) {
                if (!isLeftContentCardBlockSet) {
                  isLeftContentCardBlockSet = true
                  setLeftContentCardBlock(slice)
                }
              } else if (util.isComponent(ComponentTypes.RIGHT_CONTENT_CARD_BLOCK, __component)) {
                if (!isRightContentCardBlockSet) {
                  isRightContentCardBlockSet = true
                  setRightContentCardBlock(slice)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_BLOCK, __component)) {
                if (!isImageBlockSet) {
                  const { image_block: imageBlock } = slice || {}
                  isImageBlockSet = true
                  setImageBlock(imageBlock)
                }
              } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
                if (!isTitle1Set) {
                  const { title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              } else if (util.isComponent(ComponentTypes.SECOND_TITLE, __component)) {
                if (!isTitle2Set) {
                  const { second_title: title } = slice || {}
                  isTitle2Set = true
                  setTitle2(title)
                }
              } else if (util.isComponent(ComponentTypes.THIRD_TITLE, __component)) {
                if (!isTitle3Set) {
                  const { third_title_description: title } = slice || {}
                  isTitle3Set = true
                  setTitle3(title)
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {!!components.find(item => item.__component === 'home-page-slices.solution') && renderComponent(components.find(item => item.__component === 'home-page-slices.solution'))}

      {showCookieConsent
        ? createPortal(
          <div className='hp-cookie-consent'>
            <div className='text-box'>
              We use cookies and similar technologies to enable services and functionality on our site and to understand your interaction with our service.
              <br />
              By clicking on accept, you agree to our use of such technologies for marketing and analytics. See <Link className='link' to='/privacy'>Privacy Policy</Link> to understand more.
            </div>

            <div className='button-box'>
              <Button className='btn-settings' onClick={handleShowDialog}>Cookie Settings</Button>

              <Button className='btn-action' onClick={handleAcceptCookie}>Accept</Button>
            </div>
          </div>
          , document.body)
        : null}

      {threeColumnCard ? <div className='hp-column-card-grid'><ColumnCardGrid data={threeColumnCard} /></div> : null}

      <div className='hp-video-box'>
        <div className='flex-box'>
          <Fade left>
            <div className='left-box'>
              <Typography className='title-left'>PROBLEM?</Typography>

              <div className='list'>
                <div className='item'>
                  <div className='icon'>
                    <img className='img' alt='' src='/icon/arrow-white.png' />
                  </div>

                  <div>
                    <Typography>High monthly commitments and rentals</Typography>
                  </div>
                </div>

                <div className='item'>
                  <div className='icon'>
                    <img className='img' alt='' src='/icon/arrow-white.png' />
                  </div>

                  <div>
                    <Typography>Redundant workforce</Typography>
                  </div>
                </div>

                <div className='item'>
                  <div className='icon'>
                    <img className='img' alt='' src='/icon/arrow-white.png' />
                  </div>

                  <div>
                    <Typography>Saturated exposure on online marketplace</Typography>
                  </div>
                </div>

                <div className='item'>
                  <div className='icon'>
                    <img className='img' alt='' src='/icon/arrow-white.png' />
                  </div>

                  <div>
                    <Typography>Uncertain and limited opening hours</Typography>
                  </div>
                </div>

                <div className='item'>
                  <div className='icon'>
                    <img className='img' alt='' src='/icon/arrow-white.png' />
                  </div>

                  <div>
                    <Typography>Limited customers due to space constraints and health restrictions</Typography>
                  </div>
                </div>
              </div>

              <Card className='card'>
                <Typography className='description'>Then It’s Time to Embrace e-Commerce And Welcome to your own e-Store</Typography>
              </Card>
            </div>
          </Fade>

          <Fade right>
            <div className='right-box'>
              <Typography className='title-right'>SOLUTION</Typography>

              <ReactPlayer
                className='react-player'
                url='https://www.youtube.com/watch?v=Bung7IXKGTo'
                height='290px'
                width='100%'
                style={{
                  maxWidth: '480px'
                }}
              />
            </div>
          </Fade>
        </div>
      </div>

      {title1 ? <div className='hp-title1'><Title data={title1} /></div> : null}

      {imageBlock ? <Fade><Box className='hp-image-block' sx={{ paddingLeft: 3, paddingRight: 3 }}><ImageBlock data={imageBlock} /></Box></Fade> : null}

      {title2 ? <div className='hp-title2'><Title data={title2} /></div> : null}

      {rightContentCardBlock ? <div className='hp-right-content-card-block'><RightContentCardBlock data={rightContentCardBlock} /></div> : null}

      {leftContentCardBlock ? <div className='hp-left-content-card-block'><LeftContentCardBlock data={leftContentCardBlock} /></div> : null}

      {bannerLeftText ? <div className='hp-banner-left-text'><BannerLeftText data={bannerLeftText} /></div> : null}

      {!!components.find(item => item.__component === 'home-page-slices.ambassador') && renderComponent(components.find(item => item.__component === 'home-page-slices.ambassador'))}

      {!!components.find(item => item.__component === 'home-page-slices.comp-facebook-cards') && renderComponent(components.find(item => item.__component === 'home-page-slices.comp-facebook-cards'))}

      {title3 ? <div className='hp-title3'><Title data={title3} /></div> : null}

      {!!components.find(item => item.__component === 'home-page-slices.logo') && renderComponent(components.find(item => item.__component === 'home-page-slices.logo'))}

      {twoColumnCardWithButton ? <div className='hp-two-column-card-with-button'><TwoColumnCardWithButton data={twoColumnCardWithButton} /></div> : null}

      {centreBanner ? <div className='hp-centre-banner'><CentreBanner data={centreBanner} /></div> : null}

      <Dialog
        open={showCookieConsentDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog(true)}
        aria-describedby='alert-dialog-slide-description'
      >

        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <DialogTitle sx={{ padding: 0, color: "black", marginBottom: 1 }}>Cookie Settings</DialogTitle>

            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={changeAcceptAllCookies} checked={acceptAllCookies} sx={{ marginLeft: "auto" }} />}
                label='Accept all cookies'
                labelPlacement='start'
                sx={{ display: "flex", justifyContent: 'flex-end', marginLeft: 0, flexGrow: 1 }}
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ padding: 3, paddingTop: 0 }}>
          <Button color='white' onClick={() => handleCloseDialog(true)} type='solid'>Close</Button>

          <Button onClick={() => handleCloseDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  function removeCookie(name) {
    if (document) {
      document.cookie = `${name}=;path=/;max-age=0;samesite=lax`
    }
  }

  function getCookie(name) {
    if (document) {
      name = `${name}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const keys = decodedCookie.split(';')

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        if (key.indexOf(name) === 0) {
          return key.split('=')[1]
        }
      }
    }
  }

  function setCookie(name, value, maxAgeInSeconds) {
    if (document) {
      maxAgeInSeconds = BigNumber(maxAgeInSeconds).isNaN() ? 2592000 : maxAgeInSeconds
      document.cookie = `${name}=${value};path=/;max-age=${maxAgeInSeconds};samesite=lax`
    }
  }

  function handleAcceptCookie() {
    removeCookie()
    setCookie('user_cookie_consent', 1)
    setShowCookieConsent(false)
  }
}

export default HomePage
