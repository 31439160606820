const ComponentTypes = {
  APP_HEADER: 'comp-header',
  APP_NAVBAR: 'comp-navigation',
  APP_FOOTER: 'comp-footer',
  BANNER_LEFT_TEXT: 'comp-banner-left-content',
  BANNER_LEFT_TEXT1: 'comp-banner-content-left',
  BANNER_LEFT_TEXT2: 'comp-left-banner-content',
  BANNER_LEFT_TEXT3: 'comp-left-content-banner',
  BORDERLESS_COLUMN_CARDS: 'comp-cards',
  BREADCRUMB: 'crumbs',
  BREADCRUMB1: 'comp-array-breadcrumb',
  BREADCRUMB2: 'comp-crumbs',
  BREADCRUMB3: 'comp-breadcrumbs',
  BREADCRUMB4: 'comp-bread-crumbs',
  BREADCRUMB5: 'crumb',
  BREADCRUMB6: 'links',
  CENTER_BANNER: 'comp-center-banner',
  CENTER_BANNER2: 'center-banner',
  COLUMN_CARDS: 'column-cards',
  COLUMN_CARDS1: 'comp-card-column',
  COLUMN_CARDS2: 'comp-three-column-cards',
  COLUMN_CARDS3: 'three-cards',
  TWO_COLUMN_CARDS: 'comp-cards-with-title',
  TWO_COLUMN_CARD_WITH_BUTTON: 'two-column-cards',
  TWO_COLUMN_FULL_WIDTH: 'comp-columns',
  TWO_COLUMN_FULL_WIDTH1: 'comp-full-column',
  TWO_COLUMN_FULL_WIDTH2: 'comp-two-column-full-width',
  TWO_COLUMN_FULL_WIDTH3: 'comp-column',
  TWO_COLUMN_FULL_WIDTH4: 'com-colums',
  TWO_COLUMN_FULL_WIDTH5: 'column',
  LEFT_CONTENT_BUTTON_CARD: 'comp-banner',
  LEFT_CONTENT_BUTTON_CARD1: 'banner',
  LEFT_RIGHT_CONTENT_BLOCK: 'customer',
  LEFT_RIGHT_CONTENT_BLOCK1: 'comp-features-on-app',
  LEFT_RIGHT_CONTENT_BLOCK2: 'comp-features',
  LEFT_RIGHT_CONTENT_BLOCK3: 'comp-features-on-web',
  LEFT_RIGHT_CONTENT_BLOCK4: 'comp-content',
  LEFT_RIGHT_CONTENT_BLOCK5: 'comp-pay',
  LEFT_RIGHT_CONTENT_BLOCK6: 'help',
  LEFT_RIGHT_CONTENT_BLOCK7: 'comp-key',
  LEFT_RIGHT_CONTENT_BLOCK8: 'comp-present',
  LEFT_RIGHT_CONTENT_BLOCK9: 'comp-skip',
  LEFT_RIGHT_CONTENT_BLOCK10: 'comp-dtc',
  LEFT_RIGHT_CONTENT_BLOCK11: 'begin',
  LEFT_CONTENT_CARD_BLOCK: 'comp-backend-features',
  LEFT_CONTENT_IMAGE_CARD_BLOCK: 'comp-quality',
  RIGHT_CONTENT_BLOCK: 'comp-keep-it-working',
  RIGHT_CONTENT_CARD_BLOCK: 'comp-fronted-features',
  CUSTOM_TABS: 'comp-custom-tabs',
  CUSTOM_TABS1: 'custom-tabs',
  DISCLAIMER: 'comp-disclaimer',
  HEADER_HERO: 'header-hero',
  HEADER_HERO1: 'comp-header-hero',
  HEADER_HERO2: 'comp-hero-header',
  LEFT_ICON_CARD: 'comp-icon-text',
  LEFT_ICON_CARD1: 'comp-icon-text-note',
  LEFT_ICON_CARD2: 'comp-note',
  LEFT_ICON_CARD3: 'important-note',
  IMAGE_BLOCK: 'image-block',
  IMAGE_CARDS: 'pay-type',
  LEFT_IMAGE_COLUMN_CARDS_BLOCK: 'comp-different',
  LEFT_IMAGE_COLUMN_CARDS_BLOCK1: 'comp-rules',
  LEFT_IMAGE_FULL_WIDTH: 'comp-benefits',
  IMAGE_TITLE_DESCRIPTION_CARD: 'first-cards',
  IMAGE_TITLE_LIST_TWO_COLUMN_CARD: 'two-cards',
  IMAGE_RIGHT_TITLE_DESCRIPTION_CARD: 'second-cards',
  IMAGE_RIGHT_TITLE_DESCRIPTION_CARD1: 'cards',
  IMAGE_RIGHT_TITLE_DESCRIPTION_CARD2: 'comp-first-cards',
  IMAGE_RIGHT_TITLE_DESCRIPTION_CARD3: 'comp-second-cards',
  IMAGE_RIGHT_TITLE_DESCRIPTION_CARD4: 'comp-third-cards',
  LINK: 'comp-link',
  RIGHT_LIST_BLOCK: 'comp-app-admin',
  RIGHT_LIST_BLOCK1: 'comp-web-admin',
  LEFT_RIGHT_NESTED_LIST_BLOCK: 'nested-block',
  LEFT_RIGHT_NESTED_LIST_BLOCK1: 'nested-blocks',
  LEFT_RIGHT_NESTED_LIST_BLOCK2: 'comp-nested-block',
  NOTE: 'note',
  STEPS: 'comp-multiple-steps',
  STEPS1: 'comp-flow',
  STEPS2: 'comp-step',
  TERMS: 'first-terms',
  TERMS1: 'terms',
  TEXT: 'comp-first-single-text',
  TEXT1: 'comp-text',
  SECOND_TEXT: 'second-text',
  SECOND_TEXT1: 'comp-single-text',
  SECOND_TEXT2: 'comp-second-text',
  TITLE: 'title',
  TITLE1: 'comp-title',
  TITLE2: 'comp-last-title',
  FIRST_TITLE: 'comp-first-title',
  FIRST_TITLE1: 'first-title',
  SECOND_TITLE: 'second-title',
  SECOND_TITLE1: 'comp-second-title',
  SECOND_TITLE2: 'comp-third-title',
  THIRD_TITLE: 'third-title',
  FOURTH_TITLE: 'fourth-title',
  FOURTH_TITLE1: 'comp-fourth-title',
  TITLE_WITH_BUTTON: 'comp-title-and-button',
  TITLE_WITH_BUTTON1: 'comp-title-with-button',
  TITLE_WITH_BUTTON2: 'comp-fisrt-title-with-button',
  SECOND_TITLE_WITH_BUTTON: 'comp-second-title-and-button',
  SECOND_TITLE_WITH_BUTTON1: 'comp-title-button-second',
  SECOND_TITLE_WITH_BUTTON2: 'comp-second-title-with-button',
  LEFT_TITLE_TWO_CARD: 'comp-story',
  PAGE_TITLE: 'page-title',
  FAQS: 'faqs',
  IMAGE_TITLE_TEXT: 'image-title-text',
  LOGO_WITH_DESCRIPTION: 'logo',
}
export default ComponentTypes
export const ComponentTypesList = [
  { name: 'App Header', value: ComponentTypes.APP_HEADER },
  { name: 'App Navbar', value: ComponentTypes.APP_NAVBAR },
  { name: 'App Footer', value: ComponentTypes.APP_FOOTER },
  { name: 'Banner Left Text', value: ComponentTypes.BANNER_LEFT_TEXT },
  { name: 'Banner Left Text', value: ComponentTypes.BANNER_LEFT_TEXT1 },
  { name: 'Banner Left Text', value: ComponentTypes.BANNER_LEFT_TEXT2 },
  { name: 'Banner Left Text', value: ComponentTypes.BANNER_LEFT_TEXT3 },
  { name: 'Borderless Column Cards', value: ComponentTypes.BORDERLESS_COLUMN_CARDS },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB1 },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB2 },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB3 },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB4 },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB5 },
  { name: 'Breadcrumb', value: ComponentTypes.BREADCRUMB6 },
  { name: 'Center Banner', value: ComponentTypes.CENTER_BANNER },
  { name: 'Column Cards', value: ComponentTypes.COLUMN_CARDS },
  { name: 'Column Cards', value: ComponentTypes.COLUMN_CARDS1 },
  { name: 'Column Cards', value: ComponentTypes.COLUMN_CARDS2 },
  { name: 'Column Cards', value: ComponentTypes.COLUMN_CARDS3 },
  { name: 'Two Column Cards', value: ComponentTypes.TWO_COLUMN_CARDS },
  { name: 'Two Column Cards with Button', value: ComponentTypes.TWO_COLUMN_CARD_WITH_BUTTON },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH1 },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH2 },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH3 },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH4 },
  { name: 'Two Column Full Width', value: ComponentTypes.TWO_COLUMN_FULL_WIDTH5 },
  { name: 'Left Content Button Card', value: ComponentTypes.LEFT_CONTENT_BUTTON_CARD },
  { name: 'Left Content Button Card', value: ComponentTypes.LEFT_CONTENT_BUTTON_CARD1 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK1 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK2 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK3 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK4 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK5 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK6 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK7 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK8 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK9 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK10 },
  { name: 'Left Right Content Block', value: ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK11 },
  { name: 'Left Content Card Block', value: ComponentTypes.LEFT_CONTENT_CARD_BLOCK },
  { name: 'Left Content Image Card Block', value: ComponentTypes.LEFT_CONTENT_IMAGE_CARD_BLOCK },
  { name: 'Right Content Block', value: ComponentTypes.RIGHT_CONTENT_BLOCK },
  { name: 'Right Content Card Block', value: ComponentTypes.RIGHT_CONTENT_CARD_BLOCK },
  { name: 'Custom Tabs', value: ComponentTypes.CUSTOM_TABS },
  { name: 'Custom Tabs', value: ComponentTypes.CUSTOM_TABS1 },
  { name: 'Header Hero', value: ComponentTypes.HEADER_HERO },
  { name: 'Header Hero', value: ComponentTypes.HEADER_HERO1 },
  { name: 'Header Hero', value: ComponentTypes.HEADER_HERO2 },
  { name: 'Left Icon Card', value: ComponentTypes.LEFT_ICON_CARD },
  { name: 'Left Icon Card', value: ComponentTypes.LEFT_ICON_CARD1 },
  { name: 'Left Icon Card', value: ComponentTypes.LEFT_ICON_CARD2 },
  { name: 'Left Icon Card', value: ComponentTypes.LEFT_ICON_CARD3 },
  { name: 'Image Block', value: ComponentTypes.IMAGE_BLOCK },
  { name: 'Image Cards', value: ComponentTypes.IMAGE_CARDS },
  { name: 'Left Image Column Cards Block', value: ComponentTypes.LEFT_IMAGE_COLUMN_CARDS_BLOCK },
  { name: 'Left Image Column Cards Block', value: ComponentTypes.LEFT_IMAGE_COLUMN_CARDS_BLOCK1 },
  { name: 'Left Image Full Width', value: ComponentTypes.LEFT_IMAGE_FULL_WIDTH },
  { name: 'Image Title Description Cards', value: ComponentTypes.IMAGE_TITLE_DESCRIPTION_CARD },
  { name: 'Image Title List Two Column Cards', value: ComponentTypes.IMAGE_TITLE_LIST_TWO_COLUMN_CARD },
  { name: 'Image Right Title Description Cards', value: ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD },
  { name: 'Image Right Title Description Cards', value: ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD1 },
  { name: 'Image Right Title Description Cards', value: ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD2 },
  { name: 'Image Right Title Description Cards', value: ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD3 },
  { name: 'Image Right Title Description Cards', value: ComponentTypes.IMAGE_RIGHT_TITLE_DESCRIPTION_CARD4 },
  { name: 'Link', value: ComponentTypes.LINK },
  { name: 'Right List Block', value: ComponentTypes.RIGHT_LIST_BLOCK },
  { name: 'Right List Block', value: ComponentTypes.RIGHT_LIST_BLOCK1 },
  { name: 'Left Right Nested List Block', value: ComponentTypes.LEFT_RIGHT_NESTED_LIST_BLOCK },
  { name: 'Left Right Nested List Block', value: ComponentTypes.LEFT_RIGHT_NESTED_LIST_BLOCK1 },
  { name: 'Left Right Nested List Block', value: ComponentTypes.LEFT_RIGHT_NESTED_LIST_BLOCK2 },
  { name: 'Note', value: ComponentTypes.NOTE },
  { name: 'Steps', value: ComponentTypes.STEPS },
  { name: 'Steps', value: ComponentTypes.STEPS1 },
  { name: 'Steps', value: ComponentTypes.STEPS2 },
  { name: 'Text', value: ComponentTypes.TEXT },
  { name: 'Text', value: ComponentTypes.TEXT1 },
  { name: 'Text 2', value: ComponentTypes.SECOND_TEXT },
  { name: 'Text 2', value: ComponentTypes.SECOND_TEXT1 },
  { name: 'Text 2', value: ComponentTypes.SECOND_TEXT2 },
  { name: 'Title', value: ComponentTypes.TITLE },
  { name: 'Title', value: ComponentTypes.TITLE1 },
  { name: 'Title', value: ComponentTypes.TITLE2 },
  { name: 'First Title', value: ComponentTypes.FIRST_TITLE },
  { name: 'First Title', value: ComponentTypes.FIRST_TITLE1 },
  { name: 'Second Title', value: ComponentTypes.SECOND_TITLE },
  { name: 'Second Title', value: ComponentTypes.SECOND_TITLE1 },
  { name: 'Second Title', value: ComponentTypes.SECOND_TITLE2 },
  { name: 'Third Title', value: ComponentTypes.THIRD_TITLE },
  { name: 'Fourth Title', value: ComponentTypes.FOURTH_TITLE },
  { name: 'Fourth Title', value: ComponentTypes.FOURTH_TITLE1 },
  { name: 'Tittle With Button', value: ComponentTypes.TITLE_WITH_BUTTON },
  { name: 'Tittle With Button', value: ComponentTypes.TITLE_WITH_BUTTON1 },
  { name: 'Tittle With Button', value: ComponentTypes.TITLE_WITH_BUTTON2 },
  { name: 'Second Tittle With Button', value: ComponentTypes.SECOND_TITLE_WITH_BUTTON },
  { name: 'Second Tittle With Button', value: ComponentTypes.SECOND_TITLE_WITH_BUTTON1 },
  { name: 'Second Tittle With Button', value: ComponentTypes.SECOND_TITLE_WITH_BUTTON2 },
  { name: 'Left Tittle Two Card', value: ComponentTypes.LEFT_TITLE_TWO_CARD }
]
