export const getReactRevealYStyles = (index) => {
    return {
        ...(index % 2 == 0 ? { bottom: true } : { top: true })
    }
}

export const getReactRevealXStyles = (index) => {
    return {
        ...(index % 2 == 0 ? { left: true } : { right: true })
    }
}