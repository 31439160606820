import { Bars } from 'react-loader-spinner'
import './loader.scss'
import { Fade } from 'react-reveal'

const LoaderComponent = () => {
  return (
    <div id='loader'>
      <Fade>
        <Bars heigth="100" width="100" arialLabel="loading-indicator" color="white" />
      </Fade>
    </div>
  )
}

export default LoaderComponent