import axios from 'axios'
import React, { useState } from 'react'

import { Link } from '@mui/material'
import { CentreBanner, ColumnCardGrid, HeaderHero, LeftRightContentBlock, Title } from '../../components'
import { ComponentTypes } from '../../constants/component'
import { util } from '../../util'
import useLoadingEffect from '../../hooks/useLoadingEffect'

import './styles.css'

function ProductAICApp (props) {
  const [centreBanner, setCentreBanner] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [link, setLink] = useState()
  const [threeColumnCard, setThreeColumnCard] = useState()
  const [title1, setTitle1] = useState()

  useLoadingEffect(() => {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Product/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isCentreBannerSet = false
            let isLeftRightContentBlockSet = false
            let isLinkSet = false
            let isThreeColumnCardSet = false
            let isTitle1Set = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB2, __component)) {
                const { crumbs: breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.CENTER_BANNER, __component)) {
                if (!isCentreBannerSet) {
                  const { comp_center_banner: centreBanner } = slice || {}
                  isCentreBannerSet = true
                  setCentreBanner(centreBanner)
                }
              } else if (util.isComponent(ComponentTypes.COLUMN_CARDS, __component)) {
                if (!isThreeColumnCardSet) {
                  isThreeColumnCardSet = true
                  const { card: columnsCards } = slice || {}
                  setThreeColumnCard(columnsCards)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK, __component)) {
                if (!isLeftRightContentBlockSet) {
                  const { content: contents } = slice || {}
                  isLeftRightContentBlockSet = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.LINK, __component)) {
                if (!isLinkSet) {
                  const { comp_link: link } = slice || {}
                  const { href, label, target } = link || {}
                  isLinkSet = true

                  if (href || label || target) {
                    setLink({ href, label, target })
                  }
                }
              } else if (util.isComponent(ComponentTypes.TITLE, __component)) {
                if (!isTitle1Set) {
                  const { title } = slice || {}
                  isTitle1Set = true
                  setTitle1(title)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {title1 ? <div className='po-title'><Title data={title1} /></div> : null}

      {threeColumnCard ? <div className='po-column-card-grid'><ColumnCardGrid data={threeColumnCard} /></div> : null}

      {leftRightContentBlock ? <div className='po-left-right-content-block'><LeftRightContentBlock data={leftRightContentBlock} /></div> : null}

      {link
        ? (
          <div className='po-link'>
            <Link className='link' href={link.href} target={link.target || '_self'} underline='none'>
              <div>{link.label}</div>

              <div>&nbsp;<img className='img' alt='' src='/icon/arrow-outline.png' /></div>
            </Link>
          </div>
          )
        : null}

      {centreBanner ? <div className='po-centre-banner'><CentreBanner data={centreBanner} /></div> : null}
    </div>
  )
}

export default ProductAICApp
