import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { formatter } from '../../util'
import './styles.css'
import { Fade } from 'react-reveal'

function AxSteps (props) {
  const { className, data } = props
  const steps = Array.isArray(data) ? data : []

  return (
    <div className={clsx('ax-steps', className)}>
      <div className='box'>
        {steps.map((step, idx) => {
          const { id, description, title } = step

          return (
            <Fade cascade>
              <div className='step' key={id}>
                <div className='eclipse'>{idx + 1}</div>

                <div className='title'>{title}</div>

                <div className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />
              </div>
            </Fade>
          )
        })}

        <div className='line' />
      </div>

      <div className='box mobile'>
        {steps.map((step, idx) => {
          const { id, description, title } = step

          return (
            <div className='step' key={id}>
              <div className='left'>
                <div className='eclipse'>{idx + 1}</div>

                {idx !== steps.length - 1 ? <div className='line' /> : null}
              </div>

              <div className='right'>
                <div className='title'>{title}</div>

                <div className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

AxSteps.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxSteps
