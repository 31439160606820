import axios from 'axios'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import {
  HeaderHero, LeftContentImageCardBlock, LeftRightContentBlock, Title, TwoColumnFullWidth
} from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'
import { Fade, Slide } from 'react-reveal'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'
import formatter from '../../../util/formatter-v2'

function FoodBeverages(props) {
  const [headerHero, setHeaderHero] = useState({})
  const [leftContentImageCardBlock, setLeftContentImageCardBlock] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [text, setText] = useState()
  const [title, setTitle] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()
  const [components, setComponents] = useState([])

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-solution-food-beverages-slices.kind':
        return (
          <>
            <div className='snfb-box'>
              <div className='snfb-title'><Title data={{ first_partial_title: data.first_partial_title, span: ` ${data.span}`, second_partial_title: data.second_partial_title, description: data.description }} /></div>

              <div className='snfb-left-cards-image-block'>
                <div className='ax-left-cards-image-block'>
                  <Slide cascade down>
                    <div className='card-box'>
                      {data.features.map((item, idx) => {
                        const { text, first_icon = {}, second_icon = {} } = item
                        const type = (idx + 1) % 3

                        return (
                          <div className={`card c${type}`} key={idx}>
                            <img className='img' src={`${process.env.REACT_APP_BASE_URL}${first_icon.url}`} style={{ maxWidth: '33px' }} />

                            <div className='description'>{text}</div>

                            <img className='img' src={`${process.env.REACT_APP_BASE_URL}${second_icon.url}`} />
                          </div>
                        )
                      })}
                    </div>
                  </Slide>

                  <div className='image-box'>
                    <Slide right>
                      <img className='img' src={`${process.env.REACT_APP_BASE_URL}${data?.image?.url}`} />
                    </Slide>
                  </div>

                </div>
              </div>

            </div>

            <Box className='snfb-text' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.note) }} />
          </>
        )
      default:
        return null;
    }
  }

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Solution-Food-Beverages/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slice: slices } = data

          setComponents(slices)

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isLeftContentImageCardBlockSet = false
            let isLeftRightContentBlock = false
            let isTextSet = false
            let isTitleSet = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB2, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD2, __component)) {
                if (!isTextSet) {
                  const { note } = slice || {}
                  isTextSet = true
                  setText(note)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK9, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  const _contents = [].concat(contents)
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(_contents.splice(0, 2))
                }

                if (!isLeftContentImageCardBlockSet) {
                  const { content: contents } = slice || {}
                  isLeftContentImageCardBlockSet = true
                  setLeftContentImageCardBlock(contents[contents.length - 1])
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO2, __component)) {
                const {
                  backgrround_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.TITLE1, __component)) {
                if (!isTitleSet) {
                  const { title } = slice || {}
                  isTitleSet = true
                  setTitle(title)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH3, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock
        ? (
          <div className='snfb-left-right-content-block'>
            <LeftRightContentBlock data={leftRightContentBlock} reverse listIcon='/icon/arrow-right-circle.svg' />
          </div>
        )
        : null}

      {!!components.find(item => item.__component === 'page-solution-food-beverages-slices.kind') && renderComponent(components.find(item => item.__component === 'page-solution-food-beverages-slices.kind'))}

      {leftContentImageCardBlock
        ? <Fade><div className='snfb-left-content-image-card-block'><LeftContentImageCardBlock data={leftContentImageCardBlock} /></div></Fade>
        : null}

      {title ? <div className='snfb-title2'><Title data={{ first_partial_title: 'Ready To Be ', span: 'Different?' }} /></div> : null}

      {twoColumnFullWidth ? <div className='snfb-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default FoodBeverages
