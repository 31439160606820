import { Box, Tabs, Tab } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import Title from '../../components/title'
import { useState, Fragment } from 'react'
import useLoadingEffect from '../../hooks/useLoadingEffect'
import axios from "axios";
import formatter from '../../util/formatter-v2'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`privacy-tabpanel-${index}`}
      aria-labelledby={`privacy-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Privacy = () => {
  const [value, setValue] = useState(0);
  const [components, setComponents] = useState([])

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-privacy`
    const { data } = await axios.get(url)
    setComponents(data.slices)
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-privacy-slices.title':
        return <Title data={{ first_partial_title: data.title }} />
      case 'page-privacy-slices.policy':
        return renderTabs(data.policy);
      default:
        return null;
    }
  }

  const renderTabs = (data) => {
    return <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Privacy Tab">
          {data.map((item, index) => <Tab key={`tab-${index}`} label={item.title} />)}
        </Tabs>
      </Box>

      {data.map((item, index) => <TabPanel key={`tab-panel-${index}`} value={value} index={index} >
        <Box sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(item.text) }} />
      </TabPanel>)}
    </>
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Box sx={{
        ...containerSpacing
      }}>
        {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
      </Box>
    </ThemeProvider>
  )
}

export default Privacy