const validator = {
  isEmptyStr (value) {
    return typeof value !== 'string' || (typeof value === 'string' && value.trim().length < 1)
  },
  isDigit (str) {
    return /^\d+$/.test(str)
  },
  isEmail (str) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
  }
}
export default validator
