import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Box as MuiBox } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBox = styled(MuiBox, {})(({ theme }) => ({
  border: '0.5px solid #B8B8B8',
  borderRadius: '5px',
  margin: '20px 14.375%',
  padding: '60px'
}))

function AxBox (props) {
  const { children, className } = props

  return (
    <StyledBox
      className={clsx(className)}
      variant='contained'
      {...props}
    >
      {children}
    </StyledBox>
  )
}

AxBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
}

export default AxBox
