import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button as MuiButton, Typography, Box } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { formatter, util } from '../../util'

import './styles.css'

const sharedStyles = {
  borderRadius: '5px',
  color: '#FFFFFF',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  height: '45px',
  lineHeight: '19px',
  minWidth: '150px',
  textAlign: 'center'
}
const StyledButton1 = styled(MuiButton, {})(({ theme }) => ({
  ...sharedStyles,
  backgroundColor: 'transparent',
  border: '1px solid #FFFFFF',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: `${alpha('#000000', 0.2)}`
  }
}))
const StyledButton2 = styled(MuiButton, {})(({ theme }) => ({
  ...sharedStyles,
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(90deg, #46D3DC 0%, #4382DF 100%)',
  border: 'none',
  '&:hover': {
    backgroundImage: 'linear-gradient(90deg, #46D3DC 0%, #246CD6 100%)'
  }
}))

function AxCentreBanner(props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { background_image: image, button, description, title, button_label: buttonLabel1, button_href: buttonHref1 } = data
  const { url: imageUrl } = image || {}
  const { link } = button || {}
  const { href: buttonHref, label: buttonLabel, target: buttonTarget } = link || {}
  const buttons = Array.isArray(button) ? button : null

  return (
    <Box
      className={clsx('ax-centre-banner', className)}
      sx={{
        display: "flex", 
        alignItems: "center", 
        backgroundImage: `url(${process.env.REACT_APP_BASE_URL + imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: 3,
        justifyContent: "center"
      }}
    >
      <div className='backdrop' />

      <Box className='content-box' >
        <div>
          {title ? <Typography className='title'>{title}</Typography> : null}

          {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

          <div className='button-box'>
            {Array.isArray(buttons)
              ? buttons.map((button, idx) => {
                const { id, link } = button || {}
                const { href: buttonHref, label: buttonLabel, target: buttonTarget } = link || {}

                return buttonLabel || buttonHref
                  ? idx % 2 === 0
                    ? <StyledButton1 className='btn-action' key={id} href={buttonHref} target={buttonTarget || '_self'}>{buttonLabel || buttonHref}</StyledButton1>
                    : <StyledButton2 className='btn-action' key={id} href={buttonHref} target={buttonTarget || '_self'}>{buttonLabel || buttonHref}</StyledButton2>
                  : null
              })
              : buttonLabel || buttonHref
                ? <StyledButton1 className='btn-action' href={buttonHref} target={buttonTarget || '_self'}>{buttonLabel || buttonHref}</StyledButton1>
                : null}

            {buttonLabel1
              ? <StyledButton2 className='btn-action' href={buttonHref1} target={buttonTarget || '_self'}>{buttonLabel1}</StyledButton2>
              : null}
          </div>
        </div>
      </Box>
    </Box>
  )
}

AxCentreBanner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxCentreBanner
