import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'
import { Fade } from 'react-reveal'
import { getReactRevealYStyles } from '../../util/react-reveal-styles'

import './styles.css'

function AxLeftTitleTwoCard (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    cards: _columnCards, first_partial_title: startTitle, span, second_partial_title: endTitle
  } = data
  const columnCards = Array.isArray(_columnCards) ? _columnCards : []

  return (
    <div className={clsx('ax-left-title-two-card', className)}>
      {startTitle || span || endTitle
        ? (
          <Typography className='title'>
            {startTitle}{span ? <span style={{ color: '#4378DF' }}>{span}</span> : null}{endTitle}
          </Typography>
        )
        : null}

      <div className='cards'>
        {columnCards.map((card, index) => {
          const {
            id, first_partial_description: startText, image, span, second_partial_description: endText
          } = card
          const { url: imageUrl } = image || {}

          return (
            <Fade {...getReactRevealYStyles(index)}>
              <div className='card' key={id}>
                {imageUrl
                  ? (
                    <div className='image-box'>
                      <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
                      {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                    </div>
                  )
                  : null}

                {startTitle || span || endTitle
                  ? (
                    <Typography className='description'>
                      {startText}{span ? <span style={{ fontWeight: 'bold' }}>{span}</span> : null}{endText}
                    </Typography>
                  )
                  : null}

              </div>
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

AxLeftTitleTwoCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftTitleTwoCard
