import axios from 'axios'
import BigNumber from 'bignumber.js'
import qs from 'qs'
import React, { useState } from 'react'

import { Loop as LoopIcon } from '@mui/icons-material'
import {
  Alert, Checkbox, FormControl, FormGroup, FormHelperText, FormLabel, FormControlLabel as MuiFormControlLabel,
  Radio, RadioGroup, Snackbar, Box
} from '@mui/material'
import { Button, FormControlLabel, HeaderHero, TextField, Title } from '../../../components'
import { ComponentTypes, PackagesList } from '../../../constants'
import { util, validator } from '../../../util'
import { Fade } from 'react-reveal'
import FormatterV2 from '../../../util/formatter-v2'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function PricingSubscription(props) {
  const [agreeTnc, setAgreeTnc] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [formMessages, setFormMessages] = useState({})
  const [formValues, setFormValues] = useState({})
  const [headerHero, setHeaderHero] = useState({})
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})
  const [productsPricing, setProductsPricing] = useState([])
  const [addonsPricing, setAddonsPricing] = useState([])

  const changeAddOn = (e) => {
    const value = parseInt(e.target.value)
    const isChecked = e.target.checked

    let addons = formValues.addOn || []

    if (isChecked) {
      addons.push(value)
    } else {
      addons = addons.filter(item => item !== value)
    }

    setFormValues({ ...formValues, addOn: addons })
  }

  const changeAgreeTnc = (e, value) => {
    setAgreeTnc(value)
  }

  const changeFormValue = (e) => {
    const { target } = e || {}
    const { name, value } = target || {}
    const error = validateFormValue(name, value)

    if (name === 'subscription') {
      const productSelected = productsPricing.find(item => item.id === parseInt(value))

      if (!productSelected || productSelected?.price <= 0) {
        setFormValues({ ...formValues, [name]: value, addOn: [] })
      } else {
        setFormValues({ ...formValues, [name]: value })
      }
    } else {
      setFormValues({ ...formValues, [name]: value })
    }

    if (error) {
      setFormErrors({ ...formErrors, [name]: true })
      setFormMessages({ ...formMessages, [name]: error })
    } else {
      clearFormError(name)
    }
  }

  const handleHideError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowError(false)
  }

  const handleShowError = () => {
    setShowError(true)
  }

  const handleSubmit = () => {
    if (validateFormValues()) {
      handleShowError()
      return
    }

    setLoading(true)

    const {
      addOn, companyName, companyRegNo, domainName, email,
      firstName, lastName, storeAppName, subscription
    } = formValues

    const formData = {
      first_name: firstName,
      last_name: lastName,
      store_name: storeAppName,
      company_name: companyName,
      company_registration_number: companyRegNo,
      email,
      domain_name: domainName,
      aic_package: {
        id: subscription
      },
    }

    if (addOn && addOn.length > 0) {
      formData.add_on = { id: addOn.join(',') }
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/subscriptions`,
        formData
      )
      .then(() => {
        setLoading(false)
        setShowSuccess(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
      .catch((err) => {
        setLoading(false)

        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(err.response)
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error(err.message)
        }

        handleShowError()
      })
  }

  const renderSubmissionSuccessComponent = ({ image, first_partial_description, span, description, note, button_label, button_href }) => {
    return (
      <Box className='ps-success-box'>
        <Box className='image-box'><img className='img-success' src={`${process.env.REACT_APP_BASE_URL}${image}`} /></Box>

        <Box className='title-box'>
          <Box className='title'>{first_partial_description} <span className='highlight'>{span}</span>.</Box>

          <Box className='subtitle' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(description) }}>
          </Box>
        </Box>

        <Box className='note' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(note) }}>
        </Box>

        <Box className='button-box'>
          <Button href={button_href} type='solid'>{button_label}</Button>
        </Box>
      </Box>
    )
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-pricing-slices.disclaimer':
        return (
          <Box className='ps-text'>
            <Box className='additional-note' sx={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: FormatterV2.toHtml(data.disclaimer) }} />
          </Box>
        )
      default:
        return null;
    }
  }

  const allowAddonsSelection = () => productsPricing.find(item => item.id === parseInt(formValues.subscription))?.price > 0

  const getTotalAmount = () => {
    const productPrice = productsPricing.find(item => item.id === parseInt(formValues.subscription))?.price || 0
    const addonPrice = addonsPricing.reduce((previousValue, item) => {
      if ((formValues.addOn || []).includes(item.id)) previousValue += item.price
      return previousValue
    }, 0)

    return productPrice + addonPrice
  }

  useLoadingEffect(async () => {
    const handlePagePricingApiResponse = (response) => {
      if (!Array.isArray(response) && typeof response === 'object') {
        const { data } = response
        const { slices } = data

        setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-pricing-slices.acknowledgement'))
        setComponents([...components, ...data.slices.filter(item => item.__component !== 'page-pricing-slices.acknowledgement')])

        if (Array.isArray(slices)) {
          const _headerHero = {}

          for (let i = 0; i < slices.length; i++) {
            const slice = slices[i]
            const { __component } = slice

            if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
              const { breadcrumbs } = slice
              breadcrumbs.push({
                id: Number.MAX_SAFE_INTEGER,
                href: null,
                label: 'Pricing',
                target: null
              })
              Object.assign(_headerHero, {
                breadCrumb: breadcrumbs
              })
            } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
              const {
                background_image: background, description, href_current_page: currentHref, title
              } = slice
              Object.assign(_headerHero, {
                currentHref,
                currentPage: 'Subscribe',
                subtitle: description,
                title
              })

              if (background && background.url) {
                const { url } = background
                Object.assign(_headerHero, {
                  backgroundImage: url
                })
              }
            }
          }

          setHeaderHero(_headerHero)
        }
      }
    }

    const handlePageSubscribeApiResponse = (response) => {
      const { data } = response
      setComponents([...components, ...data.slices])
    }

    const handleProductsPricingApiResponse = ({ data = [] }) => {
      const { location } = window || {}
      const { search } = location || {}
      const { p: subscription } = qs.parse(search, { ignoreQueryPrefix: true })

      if (data.find(item => item.id === parseInt(subscription))) {
        setFormValues({ ...formValues, subscription })
      }

      setProductsPricing(data)
    }

    const handleAddonsPricingApiResponse = ({ data = [] }) => {
      setAddonsPricing(data)
    }

    const promises = [axios.get(`${process.env.REACT_APP_BASE_URL}/Page-Pricing/`), axios.get(`${process.env.REACT_APP_BASE_URL}/page-subscribe`), axios.get(`${process.env.REACT_APP_BASE_URL}/aic-packages`), axios.get(`${process.env.REACT_APP_BASE_URL}/add-ons`)]
    const [pagePricingApiResponse, pageSubscribeApiResponse, productsPricingApiResponse, addonsPricingApiResponse] = await Promise.all(promises)

    handlePagePricingApiResponse(pagePricingApiResponse)
    handlePageSubscribeApiResponse(pageSubscribeApiResponse)
    handleProductsPricingApiResponse(productsPricingApiResponse)
    handleAddonsPricingApiResponse(addonsPricingApiResponse)
  })

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleHideError}
        open={showError}
      >
        <Alert onClose={handleHideError} severity='error' sx={{ width: '100%' }}>
          Unable to submit successfully. {hasFormErrors() ? 'Please ensure all values are entered correctly.' : 'Please try again later.'}
        </Alert>
      </Snackbar>

      {showSuccess
        ? (
          <Fade>
            {renderSubmissionSuccessComponent({
              ...submissionSuccessComponent,
              image: submissionSuccessComponent?.image?.url,
            })}
          </Fade>
        )
        : (
          <>
            {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

            <div className='ps-title'>
              <Title data={{ span: 'axolot Instant Commerce', title: ' Subscription Form' }} />
            </div>

            <div className='ps-box'>
              <div className='form-box'>
                <div className='ps-form-grid col2'>
                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.firstName}
                        helperText={formMessages.firstName}
                        name='firstName'
                        onChange={changeFormValue}
                        placeholder='Enter Your First Name'
                        required
                        sx={{ width: '100%' }}
                        value={formValues.firstName || ''}
                        variant='outlined'
                      />
                    }
                    label='First Name *'
                    sx={{ width: '100%' }}
                  />

                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.lastName}
                        helperText={formMessages.lastName}
                        name='lastName'
                        onChange={changeFormValue}
                        placeholder='Enter Your Last Name'
                        required
                        sx={{ width: '100%' }}
                        value={formValues.lastName || ''}
                        variant='outlined'
                      />
                    }
                    label='Last Name *'
                    sx={{ width: '100%' }}
                  />
                </div>

                <div className='ps-form-grid'>
                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.storeAppName}
                        helperText={formMessages.storeAppName}
                        name='storeAppName'
                        onChange={changeFormValue}
                        placeholder='Enter Your Store/ App Name'
                        required
                        sx={{ width: '100%' }}
                        value={formValues.storeAppName || ''}
                        variant='outlined'
                      />
                    }
                    label='Store/ App Name *'
                    sx={{ width: '100%' }}
                  />
                </div>

                <div className='ps-form-grid col2'>
                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.companyName}
                        helperText={formMessages.companyName}
                        name='companyName'
                        onChange={changeFormValue}
                        placeholder='Enter Your Company Name'
                        sx={{ width: '100%' }}
                        value={formValues.companyName || ''}
                        variant='outlined'
                      />
                    }
                    label='Company Name'
                    sx={{ width: '100%' }}
                  />

                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.companyRegNo}
                        helperText={formMessages.companyRegNo}
                        name='companyRegNo'
                        onChange={changeFormValue}
                        placeholder='Enter Your Registration No.'
                        sx={{ width: '100%' }}
                        value={formValues.companyRegNo || ''}
                        variant='outlined'
                      />
                    }
                    label='Company Registration No.'
                    sx={{ width: '100%' }}
                  />
                </div>

                <div className='ps-form-grid col2'>
                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.domainName}
                        helperText={formMessages.domainName}
                        name='domainName'
                        onChange={changeFormValue}
                        placeholder='www.yourstore.com'
                        required
                        sx={{ width: '100%' }}
                        value={formValues.domainName || ''}
                        variant='outlined'
                      />
                    }
                    label='Domain Name/ URL *'
                    sx={{ width: '100%' }}
                  />

                  <FormControlLabel
                    control={
                      <TextField
                        error={formErrors.email}
                        helperText={formMessages.email}
                        name='email'
                        onChange={changeFormValue}
                        placeholder='Enter Your E-mail Address'
                        required
                        sx={{ width: '100%' }}
                        value={formValues.email || ''}
                        variant='outlined'
                      />
                    }
                    label='E-mail *'
                    sx={{ width: '100%' }}
                  />
                </div>

                <div className='cu-form-control'>
                  <FormControl className='radio-form-control' component='fieldset' error={formErrors.subscription}>
                    <FormLabel component='legend'>I wish to subscribe to:</FormLabel>

                    <RadioGroup
                      className='radio-button-group'
                      aria-label='subscription'
                      name='subscription'
                      value={formValues.subscription || ''}
                      onChange={changeFormValue}
                    >
                      {
                        productsPricing.sort((a, b) => a.price - b.price).map((item, index) => {
                          return (
                            <MuiFormControlLabel
                              className='radio'
                              key={`products-option-${index}`}
                              control={<Radio />}
                              label={(
                                <span className='text'>
                                  <span>{item.name}</span>

                                  {item.sub_name ? <span>&nbsp;&nbsp;<span className='extra'>{item.sub_name}</span></span> : null}
                                </span>
                              )}
                              value={item.id}
                            />
                          )
                        })
                      }
                    </RadioGroup>

                    {formMessages.subscription ? <FormHelperText>{formMessages.subscription}</FormHelperText> : null}
                  </FormControl>
                </div>

                {allowAddonsSelection()
                  ? (
                    <div className='cu-form-control'>
                      <FormControl className='checkbox-form-control' component='fieldset'>
                        <FormLabel component='legend'>Other Axolot optional add-on services:</FormLabel>
                        <FormGroup className='checkbox-form-group'>
                          {
                            addonsPricing.map((item, index) => {
                              return <MuiFormControlLabel
                                key={`addons-option-${index}`}
                                control={
                                  <Checkbox
                                    className='checkbox'
                                    value={item.id}
                                    onChange={changeAddOn}
                                  />
                                }
                                label={item.name}
                              />
                            })
                          }
                        </FormGroup>
                      </FormControl>
                    </div>
                  )
                  : null}

                {/* <div>
                  Recaptcha
                </div> */}
              </div>

              <div className='total-box'>
                <div className='label'>Total Payable:</div>

                <div className='amount'>RM {getTotalAmount().toFixed(2)}</div>
              </div>

              <div className='form-box'>
                <FormControl className='checkbox-form-control' component='fieldset'>
                  <FormGroup className='checkbox-form-group tnc'>
                    <MuiFormControlLabel
                      control={
                        <Checkbox onChange={changeAgreeTnc} />
                      }
                      label={<div>I have read and understood the <a className='link' href='/tnc' target="_blank">terms and conditions</a> for Axolot Instant Commerce (AIC).</div>}
                    />
                  </FormGroup>
                </FormControl>
              </div>

              <div className='button-box form-box'>
                <Button color='light blue' href='/pricing' type='outline'>
                  Cancel
                </Button>

                <Button disabled={!agreeTnc || loading} onClick={handleSubmit} type='solid'>
                  Send Order{loading ? <LoopIcon className='cu-rotating' /> : null}
                </Button>
              </div>
            </div>

            {!!components.find(item => item.__component === 'page-pricing-slices.disclaimer') && renderComponent(components.find(item => item.__component === 'page-pricing-slices.disclaimer'))}
          </>
        )}
    </div>
  )

  function clearFormError(name) {
    delete formErrors[name]
    delete formMessages[name]
    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
  }

  function getFormValidator() {
    return {
      firstName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter First Name'
        }
      },
      lastName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Last Name'
        }
      },
      storeAppName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Store/ App Name'
        }
      },
      companyRegNo(value) {
        if (!validator.isEmptyStr(value) && !validator.isDigit(value)) {
          return 'Only numbers are allowed.'
        }
      },
      email(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter E-mail'
        } else if (!validator.isEmail(value)) {
          return 'E-mail is invalid'
        }
      },
      domainName(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please enter Domain Name/ URL'
        }
      },
      subscription(value) {
        if (validator.isEmptyStr(value)) {
          return 'Please choose package'
        }
      }
    }
  }

  function hasFormErrors() {
    return Object.keys(formErrors).length > 0
  }

  function validateFormValue(name, value) {
    const formValidator = getFormValidator()
    const validate = formValidator[name]

    if (typeof validate === 'function') {
      return validate(value)
    }
  }

  function validateFormValues() {
    const fields = [
      'firstName', 'lastName', 'storeAppName',
      'companyRegNo', 'domainName', 'email', 'subscription'
    ]
    const formErrors = {}
    const formMessages = {}
    let errorCount = 0

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const value = formValues[field]
      const error = validateFormValue(field, value)

      if (error) {
        formErrors[field] = true
        formMessages[field] = error
        errorCount += 1
      } else {
        delete formErrors[field]
        delete formMessages[field]
      }
    }

    setFormErrors({ ...formErrors })
    setFormMessages({ ...formMessages })
    return errorCount > 0
  }
}

export default PricingSubscription
