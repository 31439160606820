import React from 'react'
import clsx from 'clsx'
import Title from '../title'
import './styles.css'
import { formatter } from '../../util'
import { Typography } from '@mui/material'

const LogoWithDescription = (props) => {
    const { data } = props
    const { logos, fineprint } = data

    return (
        <>
            <div className='logos-holder'>
                { logos && logos.map((logo) => {
                        
                        return (
                            <>
                            <img className='logos-content' src={process.env.REACT_APP_BASE_URL + logo.url} />
                            </>
                        )
                    })
                }
            </div>
            <div className='logo-description'>
                <Title data={data}/>
            </div>
            { fineprint &&
                <Typography className='logo-fineprint' dangerouslySetInnerHTML={{ __html: formatter.toHtml(fineprint) }} />
            }
        </>
    )
}

export default LogoWithDescription