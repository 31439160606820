import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Breadcrumbs, Typography, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import './styles.css'
import { Fade } from 'react-reveal'

function AxHeaderHero (props) {
  const { className, backgroundImage, breadCrumb, currentHref, currentPage, subtitle, title } = props
  let { backgroundImageStyle } = props
  backgroundImageStyle = typeof backgroundImageStyle === 'string'
    ? backgroundImageStyle
    : 'linear-gradient(360deg, #313C47 4.36%, rgba(49, 60, 71, 0) 217.11%)'

  return (
    <Fade>
      <div
        className={clsx('ax-header-hero', className)}
        style={{
          backgroundImage: `${backgroundImageStyle}${backgroundImage ? `, url(${process.env.REACT_APP_BASE_URL + backgroundImage})` : ''}`,
          // backgroundImage: `${backgroundImageStyle}${backgroundImage ? `, url(${'http://axolot.oceannexus.my:1337' + backgroundImage})` : ''}`,
          backgroundSize: 'cover',
          mixBlendMode: 'multiply'
        }}
      >
        <div className='text-box'>
          {title ? <Typography className='title'>{title}</Typography> : null}

          {subtitle ? <Typography className='subtitle'>{subtitle}</Typography> : null}
        </div>

        <Breadcrumbs className='breadcrumbs' aria-label='Breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
          {Array.isArray(breadCrumb)
            ? breadCrumb.map((bc, idx) => {
              const { id, href, label, label_link: label2, link_href: href1, link_label: label1, target } = bc

              return href || href1
                ? (
                  <Link className='link' key={`${idx}-${id}`} href={href || href1} target={target || '_self'} underline='hover'>
                    {label || label1 || label2}
                  </Link>
                )
                : (
                  <Typography className='link current' key={`${idx}-${id}`}>
                    {label || label1 || label2}
                  </Typography>
                )
            })
            : null}

          {currentPage || currentHref
            ? (
              <Typography className='link current'>
                {currentPage || currentHref}
              </Typography>
            )
            : null}
        </Breadcrumbs>
      </div>
    </Fade>
  )
}

AxHeaderHero.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  backgroundImage: PropTypes.string,
  backgroundImageStyle: PropTypes.string,
  breadCrumb: PropTypes.array,
  currentHref: PropTypes.string,
  currentPage: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node
}

export default AxHeaderHero
