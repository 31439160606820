import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import { Header, Footer } from './components/layout'
import { ComponentTypes } from './constants/component'
import { util } from './util'

import HomePage from './pages/homePage'
import ProductOverview from './pages/product_Overview'
import ProductAICApp from './pages/product_AIC_App'
import ProductAICComplete from './pages/product-AIC-Complete'
import ProductAICWeb from './pages/product-AIC-Web'
import SolutionRetailB2C from './pages/solution/retailB2C'
import SolutionBeautyHealth from './pages/solution/beautyHealth'
import SolutionFoodBeverages from './pages/solution/foodBeverages'
import SolutionHomeLifeStyle from './pages/solution/homeLifestyle'
import SolutionWebsiteCommerce from './pages/solution/websiteCommerce'
import SolutionMobileCommerce from './pages/solution/mobileCommerce'
import Demo from './pages/demo'
import Community from './pages/community'
import ServicesAppCare from './pages/services/appCare'
import ServicesHosting from './pages/services/hosting'
import ServicesPaymentIntegration from './pages/services/paymentIntegration'
import ServicesAxCelerate from './pages/services/axCelerate'
import Pricing from './pages/pricing'
import PricingSubscription from './pages/pricing/subscription'
import ContactUs from './pages/contactUs'
import NotFound from './pages/404'
import ComingSoon from './pages/coming-soon'
import FAQ from './pages/faq'
import Blog from './pages/blog'
import BlogPost from './pages/blog/post'
import About from './pages/about'
import TermsAndCondition from './pages/terms-and-condition'
import Privacy from './pages/privacy'
import PaymentTerms from './pages/payment-terms'
import Cancellation from './pages/cancellation'
import ReportABug from './pages/report-a-bug'
import Requirements from './pages/technical-info/requirements'
import RouteComponent from './RouteComponent'
import Loader from './components/loader'
import useLoader from './hooks/useLoader'
import { LoaderContext } from './helpers/context'
import AICPartners from './pages/community/aic-partners'

import './App.scss'
import "./mobile-style-patch.scss"

const App = () => {
  const [footer, setFooter] = useState({})
  const [header, setHeader] = useState({})
  const [loading, setLoading] = useState(false)
  const [navbar, setNavbar] = useState({})
  const [isLoading, showLoader, hideLoader] = useLoader()
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/base/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          let { base_slices: baseSlices } = data
          baseSlices = Array.isArray(baseSlices) ? baseSlices : []
          let isFooterSet = false
          let isHeaderSet = false
          let isNavbarSet = false

          for (let i = 0; i < baseSlices.length; i++) {
            const slice = baseSlices[i]
            const { __component } = slice

            if (util.isComponent(ComponentTypes.APP_HEADER, __component)) {
              if (!isHeaderSet) {
                isHeaderSet = true
                setHeader(slice)
              }
            } else if (util.isComponent(ComponentTypes.APP_NAVBAR, __component)) {
              if (!isNavbarSet) {
                isNavbarSet = true
                setNavbar(slice)
              }
            } else if (util.isComponent(ComponentTypes.APP_FOOTER, __component)) {
              if (!isFooterSet) {
                isFooterSet = true
                setFooter(slice)
              }
            }
          }
        }

        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  const mainStyle = {
    ...(isLoading ? { display: 'none' } : {})
  }

  return (
    <LoaderContext.Provider value={{ isLoading, showLoader, hideLoader }}>
      {isLoading ? <Loader /> : null}

      <div style={mainStyle}>
        <Header header={header} navbar={navbar} loading={loading} />

        <div className='ax-content'>
          <Switch>
            <Route exact path='/'>
              <HomePage />
            </Route>

            <Route exact path='/product'>
              <ProductOverview />
            </Route>

            <Route exact path='/page-product-aic-app'>
              <ProductAICApp />
            </Route>

            <Route exact path='/page-product-aic-complete'>
              <ProductAICComplete />
            </Route>

            <Route exact path='/page-product-aic-web'>
              <ProductAICWeb />
            </Route>

            <Route exact path='/page-solution-retail'>
              <SolutionRetailB2C />
            </Route>

            <Route exact path='/page-solution-beauty-health'>
              <SolutionBeautyHealth />
            </Route>

            <Route exact path='/page-solution-food-beverages'>
              <SolutionFoodBeverages />
            </Route>

            <Route exact path='/page-solution-home-lifestyle'>
              <SolutionHomeLifeStyle />
            </Route>

            <Route exact path='/page-solution-website-e-commerce'>
              <SolutionWebsiteCommerce />
            </Route>

            <Route exact path='/page-solution-mobile-e-commerce'>
              <SolutionMobileCommerce />
            </Route>

            <Route exact path='/community'>
              <Community />
            </Route>

            <Route exact path='/page-aic-partners'>
              <AICPartners />
            </Route>

            <Route exact path='/page-solution-retail'>
              <SolutionRetailB2C />
            </Route>

            <Route exact path='/page-solution-beauty-health'>
              <SolutionBeautyHealth />
            </Route>

            <Route exact path='/page-solution-food-beverages'>
              <SolutionFoodBeverages />
            </Route>

            <Route exact path='/page-solution-home-lifestyle'>
              <SolutionHomeLifeStyle />
            </Route>

            <Route exact path='/demo'>
              <Demo />
            </Route>

            <Route exact path='/axolot-appcare'>
              <ServicesAppCare />
            </Route>

            <Route exact path='/axolot-hosting'>
              <ServicesHosting />
            </Route>

            <Route exact path='/axolot-payment-integration'>
              <ServicesPaymentIntegration />
            </Route>

            <Route exact path='/axcelerate-setup-launch'>
              <ServicesAxCelerate />
            </Route>

            <Route exact path='/pricing'>
              <Pricing />
            </Route>

            <Route exact path='/pricing/subscribe'>
              <PricingSubscription />
            </Route>

            <Route exact path='/contact'>
              <ContactUs />
            </Route>

            <Route exact path='/faq'>
              <FAQ />
            </Route>

            <Route exact path='/blog'>
              <Blog />
            </Route>

            <Route exact path='/blog/:postId'>
              <BlogPost />
            </Route>

            <Route exact path='/coming-soon'>
              <ComingSoon />
            </Route>

            <Route exact path='/about-us'>
              <About />
            </Route>

            <Route exact path='/tnc'>
              <TermsAndCondition />
            </Route>

            <Route exact path='/privacy'>
              <Privacy />
            </Route>

            <Route exact path='/payment-terms'>
              <PaymentTerms />
            </Route>

            <Route exact path='/report-a-bug'>
              <ReportABug />
            </Route>

            <Route exact path='/cancellations'>
              <Cancellation />
            </Route>

            <Route exact path='/requirements'>
              <Requirements />
            </Route>

            <Route exact path='/(page-development-cycle|page-updates-and-features|page-technology|page-add-on-services)'>
              <RouteComponent />
            </Route>

            <Route exact path='/404'>
              <NotFound />
            </Route>

            <Redirect from='*' to='/404'></Redirect>
          </Switch>
        </div>

        <Footer footer={footer} loading={loading} />
      </div>
    </LoaderContext.Provider>
  )
}

export default App
