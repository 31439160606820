import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { FormControlLabel as MuiFormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledFormControlLabel = styled(MuiFormControlLabel, {})(({ theme }) => ({
  alignItems: 'flex-start',
  color: '#234171',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '19px',
  '& .MuiFormControlLabel-label': {
    fontWeight: '700',
    marginBottom: '13px'
  },
  '& > .MuiFormControl-root > .MuiFormHelperText-root': {
    marginBottom: '6px',
    marginLeft: '0',
    marginRight: '0'
  }
}))

function AxFormControlLabel (props) {
  const { children, className } = props

  return (
    <StyledFormControlLabel
      className={clsx(className)}
      labelPlacement='top'
      {...props}
    >
      {children}
    </StyledFormControlLabel>
  )
}

AxFormControlLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
}

export default AxFormControlLabel
