import axios from 'axios'
import React, { useState } from 'react'

import { Disclaimer, HeaderHero, ImageCardGrid, LeftContentButtonCard, LeftIconCard, LeftRightContentBlock } from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function Hosting (props) {
  const [disclaimer, setDisclaimer] = useState()
  const [headerHero, setHeaderHero] = useState({})
  const [imageCards, setImageCards] = useState()
  const [leftContentButtonCard, setLeftContentButtonCard] = useState()
  const [leftIconCard, setLeftIconCard] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Services-Axolot-Payment-Integration/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isDisclaimerSet = false
            let isImageCardsSet = false
            let isLeftContentButtonCard = false
            let isLeftIconCard = false
            let isLeftRightContentBlock = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_CONTENT_BUTTON_CARD, __component)) {
                if (!isLeftContentButtonCard) {
                  const card = slice || {}
                  isLeftContentButtonCard = true
                  setLeftContentButtonCard(card)
                }
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK5, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(contents)
                }
              } else if (util.isComponent(ComponentTypes.DISCLAIMER, __component)) {
                if (!isDisclaimerSet) {
                  const disclaimer = slice || {}
                  isDisclaimerSet = true
                  setDisclaimer(disclaimer)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.LEFT_ICON_CARD2, __component)) {
                if (!isLeftIconCard) {
                  const card = slice || {}
                  isLeftIconCard = true
                  setLeftIconCard(card)
                }
              } else if (util.isComponent(ComponentTypes.IMAGE_CARDS, __component)) {
                if (!isImageCardsSet) {
                  const { payment: cards } = slice || {}
                  isImageCardsSet = true
                  setImageCards(cards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock
        ? <div className='spi-left-right-content-block'><LeftRightContentBlock data={leftRightContentBlock} reverse /></div>
        : null}

      {leftIconCard ? <div className='spi-left-icon-card'><LeftIconCard data={leftIconCard} /></div> : null}

      {imageCards ? <div className='spi-image-card-grid'><ImageCardGrid data={imageCards} /></div> : null}

      {disclaimer ? <div className='spi-disclaimer'><Disclaimer data={disclaimer} /></div> : null}

      {leftContentButtonCard
        ? <div className='spi-left-content-button-card'><LeftContentButtonCard data={leftContentButtonCard} /></div>
        : null}
    </div>
  )
}

export default Hosting
