import axios from 'axios'
import React, { useState } from 'react'

import {
  HeaderHero, LeftContentImageCardBlock, LeftImageColumnCardsBlock, LeftRightContentBlock, Title, TwoColumnFullWidth
} from '../../../components'
import { ComponentTypes } from '../../../constants'
import { util } from '../../../util'
import { Fade } from 'react-reveal'

import './styles.css'
import useLoadingEffect from '../../../hooks/useLoadingEffect'

function HomeLifestyle (props) {
  const [headerHero, setHeaderHero] = useState({})
  const [leftContentImageCardBlock, setLeftContentImageCardBlock] = useState()
  const [leftRightContentBlock, setLeftRightContentBlock] = useState()
  const [leftImageColumnCardsBlock, setLeftImageColumnCardsBlock] = useState()
  const [title, setTitle] = useState()
  const [twoColumnFullWidth, setTwoColumnFullWidth] = useState()

  useLoadingEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Page-Solution-Home-Lifestyle/`)
      .then((response) => {
        if (!Array.isArray(response) && typeof response === 'object') {
          const { data } = response
          const { slices } = data

          if (Array.isArray(slices)) {
            const _headerHero = {}
            let isLeftContentImageCardBlockSet = false
            let isLeftImageColumnCardsBlockSet = false
            let isLeftRightContentBlock = false
            let isTitleSet = false
            let isTwoColumnFullWidthSet = false

            for (let i = 0; i < slices.length; i++) {
              const slice = slices[i]
              const { __component } = slice

              if (util.isComponent(ComponentTypes.BREADCRUMB2, __component)) {
                const { breadcrumbs } = slice
                Object.assign(_headerHero, {
                  breadCrumb: breadcrumbs
                })
              } else if (util.isComponent(ComponentTypes.LEFT_RIGHT_CONTENT_BLOCK8, __component)) {
                if (!isLeftRightContentBlock) {
                  const { content: contents } = slice || {}
                  const _contents = [].concat(contents)
                  isLeftRightContentBlock = true
                  setLeftRightContentBlock(_contents.splice(0, contents.length - 1))
                }

                if (!isLeftContentImageCardBlockSet) {
                  const { content: contents } = slice || {}
                  isLeftContentImageCardBlockSet = true
                  setLeftContentImageCardBlock(contents[contents.length - 1])
                }
              } else if (util.isComponent(ComponentTypes.LEFT_IMAGE_COLUMN_CARDS_BLOCK1, __component)) {
                if (!isLeftImageColumnCardsBlockSet) {
                  const block = slice || {}
                  isLeftImageColumnCardsBlockSet = true
                  setLeftImageColumnCardsBlock(block)
                }
              } else if (util.isComponent(ComponentTypes.HEADER_HERO1, __component)) {
                const {
                  background_image: background, description, href_current_page: currentHref, label_current_page: currentPage, title
                } = slice
                Object.assign(_headerHero, {
                  currentHref,
                  currentPage,
                  subtitle: description,
                  title
                })

                if (background && background.url) {
                  const { url } = background
                  Object.assign(_headerHero, {
                    backgroundImage: url
                  })
                }
              } else if (util.isComponent(ComponentTypes.FIRST_TITLE, __component)) {
                if (!isTitleSet) {
                  const { title } = slice || {}
                  isTitleSet = true
                  setTitle(title)
                }
              } else if (util.isComponent(ComponentTypes.TWO_COLUMN_FULL_WIDTH, __component)) {
                if (!isTwoColumnFullWidthSet) {
                  const { card: columnCards } = slice || {}
                  isTwoColumnFullWidthSet = true
                  setTwoColumnFullWidth(columnCards)
                }
              }
            }

            setHeaderHero(_headerHero)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <div>
      {Object.keys(headerHero).length > 0 ? <HeaderHero {...headerHero} /> : null}

      {leftRightContentBlock
        ? (
          <div className='snhl-left-right-content-block'>
            <LeftRightContentBlock data={leftRightContentBlock} reverse listIcon='/icon/arrow-right-circle.svg' />
          </div>
        )
        : null}

      {title ? <div className='snhl-title'><Title data={title} /></div> : null}

      {leftImageColumnCardsBlock
        ? <div className='snhl-left-image-column-cards-block'><LeftImageColumnCardsBlock data={leftImageColumnCardsBlock} /></div>
        : null}

      {leftContentImageCardBlock
        ? <Fade><div className='snhl-left-content-image-card-block'><LeftContentImageCardBlock data={leftContentImageCardBlock} /></div></Fade>
        : null}

      {twoColumnFullWidth ? <div className='snhl-two-column-full-width'><TwoColumnFullWidth data={twoColumnFullWidth} /></div> : null}
    </div>
  )
}

export default HomeLifestyle
