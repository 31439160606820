import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'

import './styles.css'

function AxImageBlock (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { image, label } = data
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-image-block', className)}>
      {imageUrl
        ? (
          <div className='image-box'>
            <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
            {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
          </div>
          )
        : null}

      {label ? <Typography className='title'>{label}</Typography> : null}
    </div>
  )
}

AxImageBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxImageBlock
