import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Button from '../button'
import { Fade } from 'react-reveal'
import { getReactRevealYStyles } from '../../util/react-reveal-styles'

import './styles.css'

function AxColumnCardGrid (props) {
  const { className, data, tick } = props
  const columnCards = Array.isArray(data) ? data : []

  return (
    <div className={clsx('ax-column-card-grid', className)}>
      {columnCards.map((card, index) => {
        const { id, button_label: buttonLabel, button_href: buttonHref, description, image, title } = card
        const { url: imageUrl } = image || {}

        return (
          <Fade {...getReactRevealYStyles(index)}>
            <div className='item' key={id}>
              {tick ? <div className='tick-box'><img className='img' alt='' src={tick}/></div> : null}

              <div className='card'>
                <div className='top-box'>
                  {imageUrl
                    ? (
                      <div className='image-box'>
                        <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl}/>
                        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
                      </div>
                    )
                    : null}

                  <div className='text-box'>
                    {title ? <Typography className='title'>{title}</Typography> : null}

                    {description ? <Typography className='description'>{description}</Typography> : null}
                  </div>
                </div>

                <div className='button-box'>
                  {buttonLabel || buttonHref ? <Button className='btn-action' color='blue' href={buttonHref} type='solid'>{buttonLabel || buttonHref}</Button> : null}
                </div>
              </div>
            </div>
          </Fade>
        )
      })}
    </div>
  )
}

AxColumnCardGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.array
}

export default AxColumnCardGrid
