import { Box, Grid } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles";
import CustomTheme, { containerSpacing } from "../../helpers/theme";
import { RadioField, DateTimeField, CheckField, RenderForm, validateForm } from "../../helpers/form"
import Title from "../../components/title"
import { useState, Fragment } from "react"
import { Button, TextField } from "../../components"
import { Loop as LoopIcon } from "@mui/icons-material"
import useForm from "../../hooks/useForm"
import * as yup from "yup";
import { format as DateFormat } from "date-fns"
import { Fade } from "react-reveal"
import axios from "axios"
import useSnackbar from "../../hooks/useSnackbar";
import useLoadingEffect from '../../hooks/useLoadingEffect'
import formatter from '../../util/formatter-v2'

const ReportABug = () => {
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [formValues, setFormValues] = useForm({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    platform: [],
    occurDate: null,
    bugReoccur: "",
    message: "",
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [, showSnackbar, SnackBar] = useSnackbar()
  const [components, setComponents] = useState([])
  const [submissionSuccessComponent, setSubmissionSuccessComponent] = useState({})

  const validationSchema = yup.object().shape({
    firstName: yup.string().required().label("First Name"),
    lastName: yup.string().required().label("Last Name"),
    email: yup.string().required().email().label("E-mail"),
    phoneNo: !formValues.phoneNo ? yup.string().notRequired() : yup.number().notRequired().positive().integer("Telephone No. is not valid").label("Telephone No."),
    companyName: yup.string().required().label("Company Name or AIC e-commerce Domain Name"),
    platform: yup.array().min(1).label("Platform"),
    occurDate: yup.date().max(new Date(), `Datetime must be before ${DateFormat(new Date(), "yyyy-MM-dd h:mm a")}`).label("Bug occur date"),
    bugReoccur: yup.string().required().label("Bug reoccur"),
    message: yup.string().required().label("Message / Bug Report")
  });
  const formFields = [
    {
      label: "First Name *",
      props: {
        name: "firstName",
        value: formValues.firstName,
        onChange: (event) => {
          setFormValues({ type: "firstName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Last Name *",
      props: {
        name: "lastName",
        value: formValues.lastName,
        onChange: (event) => {
          setFormValues({ type: "lastName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "E-mail *",
      props: {
        name: "email",
        type: "email",
        value: formValues.email,
        onChange: (event) => {
          setFormValues({ type: "email", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Telephone No.",
      props: {
        name: "phoneNo",
        type: "number",
        placeholder: "0121234567",
        value: formValues.phoneNo,
        onChange: (event) => {
          setFormValues({ type: "phoneNo", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
        md: 6,
      },
      component: TextField
    },
    {
      label: "Company Name or AIC e-commerce Domain Name *",
      props: {
        name: "companyName",
        value: formValues.companyName,
        onChange: (event) => {
          setFormValues({ type: "companyName", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: TextField
    },
    {
      label: "Platform *",
      props: {
        name: "platform",
        value: formValues.platform,
        options: [
          {
            label: "Front-end Website",
            value: "Front-end Website"
          },
          {
            label: "Front-end iOS mobile app",
            value: "Front-end iOS mobile app"
          },
          {
            label: "Front-end Android mobile app",
            value: "Front-end Android mobile app"
          },
          {
            label: "Admin Console",
            value: "Admin Console"
          }
        ],
        onChange: (event) => {
          setFormValues({ type: "platform", value: event })
        }
      },
      sizing: {
        xs: 12,
      },
      component: CheckField
    },
    {
      label: "When did the bug first occur? *",
      props: {
        name: "occurDate",
        value: formValues.occurDate,
        maxDate: new Date(),
        onChange: (event) => {
          setFormValues({ type: "occurDate", value: event })
        }
      },
      sizing: {
        xs: 12,
      },
      component: DateTimeField,
    },
    {
      label: "Did the bug reoccur? *",
      props: {
        name: "bugReoccur",
        value: formValues.bugReoccur,
        options: [
          {
            value: "Yes",
            label: "Yes",
          },
          {
            value: "No",
            label: "No",
          },
          {
            value: "Don't know",
            label: "Don't know",
          }
        ],
        onChange: (event) => {
          setFormValues({ type: "bugReoccur", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: RadioField,
    },
    {
      label: "Message / Bug Report *",
      props: {
        name: "message",
        maxRows: 10,
        minRows: 5,
        multiline: true,
        value: formValues.message,
        onChange: (event) => {
          setFormValues({ type: "message", value: event.target.value })
        }
      },
      sizing: {
        xs: 12,
      },
      component: TextField
    },
  ]

  const handleSubmit = async () => {
    const { isValid, errors } = await validateForm(validationSchema, formValues)

    if (!isValid) {
      setFormErrors(errors)
      showSnackbar("Please ensure all values are entered correctly.")
    }
    else {
      setFormErrors({})
      setLoading(true)

      const data = {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        telephone_number: formValues.phoneNo,
        company_name: formValues.companyName,
        platform: formValues.platform.join(", "),
        When_did_the_bug_first_occur: formValues.occurDate,
        Did_the_bug_reoccur: formValues.bugReoccur,
        message: formValues.message
      }

      try {
        const url = `${process.env.REACT_APP_BASE_URL}/bugs`
        await axios.post(url, data)
        setLoading(false)
        setIsSubmitted(true)

        setTimeout(() => {
          document.querySelector('#cu-success-box').scrollIntoView({
            behavior: "smooth"
          })
        }, 100)
      } catch (error) {
        setLoading(false)
        showSnackbar("Please try again later.")
        throw error
      }
    }
  }

  const getData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/page-report-a-bug`
    const { data } = await axios.get(url)
    setSubmissionSuccessComponent(data.slices.find(item => item.__component === 'page-cancellation-slices.acknowledgement'))
    setComponents(data.slices.filter(item => item.__component !== 'page-cancellation-slices.acknowledgement'))
  }

  const renderComponent = (data) => {
    switch (data.__component) {
      case 'page-cancellation-slices.content':
        return (
          <>
            <Title data={{ first_partial_title: data.title }} />
            <Box sx={{ lineHeight: 1.5, color: '#516997', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.description) }} />
          </>
        )
      default:
        return null;
    }
  }

  const renderSubmissionSuccessComponent = ({ image, first_partial_title, span, description }) => {
    return (
      <Box id="cu-success-box" className='cu-success-box'>
        <Box className='image-box'><img className='img-success' src={image} /></Box>

        <Box className='title-box'>
          <Box className='title'>{first_partial_title} <span className='highlight'>{span}</span>.</Box>

          <Box sx={{ lineHeight: 1.5, color: '#506A96' }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} />
        </Box>
      </Box>
    )
  }

  useLoadingEffect(getData)

  return (
    <Box>
      {SnackBar}

      <ThemeProvider theme={CustomTheme}>
        <Fade>
          <Box sx={{ ...containerSpacing }}>

            {
              !isSubmitted ?
                <>
                  {components.map((item, index) => <Fragment key={`component${index}`}>{renderComponent(item)}</Fragment>)}
                  <Box sx={{ marginTop: 5 }}>
                    <RenderForm fields={formFields} errors={formErrors} footer={
                      <Grid container direction="row" justifyContent="center" sx={{ marginTop: 5 }}>
                        <Grid item>
                          <Button disabled={loading} onClick={handleSubmit} type="solid">
                            Submit{loading ? <LoopIcon className="cu-rotating" /> : null}
                          </Button>
                        </Grid>
                      </Grid>
                    } />
                  </Box>
                </>
                :
                renderSubmissionSuccessComponent({
                  ...submissionSuccessComponent,
                  image: `${process.env.REACT_APP_BASE_URL}${submissionSuccessComponent?.image?.url}`,
                })

            }
          </Box>
        </Fade>
      </ThemeProvider>
    </Box>
  )
}

export default ReportABug