import { useParams, useHistory } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PostInfo from './components/post-info'
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme, { containerSpacing } from '../../helpers/theme';
import useLoadingEffect from '../../hooks/useLoadingEffect'
import axios from 'axios'
import { useState } from 'react'
import { format } from 'date-fns'
import { Fade } from 'react-reveal'
import formatter from '../../util/formatter-v2'
import './style.scss'

const BlogPost = () => {
  const { postId } = useParams()
  const [data, setData] = useState([])
  const history = useHistory()

  const getData = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/blogs/${postId}`
      const { data: { title, author, date, slug, content, image } } = await axios.get(url)

      setData({
        title,
        author,
        date: format(new Date(date), 'd LLL yyyy'),
        slug,
        content,
        image: image?.url ? `${process.env.REACT_APP_BASE_URL}${image.url}` : null
      })
    } catch (error) {
      if (error?.response?.status === 404) return history.push('/404')
      throw error
    }
  }

  useLoadingEffect(getData)

  return (
    <ThemeProvider theme={CustomTheme}>
      <Fade>
        <Box className="pb-5" sx={{ ...containerSpacing }}>
          <Typography sx={{ fontSize: '2.25rem', fontWeight: 'bold', marginTop: { custom: 7, xs: 5 }, marginBottom: 4, color: '#487FED', lineHeight: 1 }}>
            {data.title}
          </Typography>

          <Grid container sx={{ marginBottom: 4, flexDirection: 'row' }}>
            <Grid item sx={{ marginRight: 5 }} >
              <PostInfo body={`by ${data.author}`} />
            </Grid>

            <Grid item >
              <PostInfo body={data.date} />
            </Grid>
          </Grid>

          {
            data.image &&
            <Grid container sx={{ marginBottom: 4 }}>
              <img className="blog-post-banner" src={data.image} />
            </Grid>
          }

          <Grid container>
            <Box sx={{ color: '#234171', lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: formatter.toHtml(data.content) }} />
          </Grid>
        </Box>
      </Fade>
    </ThemeProvider>
  )
}

export default BlogPost