import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'

function AxText (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { description, title: description1 } = data

  return (
    <div className={clsx('ax-text', className)}>
      {description || description1
        ? (
          <Typography
            className='main'
            sx={{
              color: '#234171',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '25px',
              lineHeight: '29px',
              textAlign: 'center'
            }}
          >
            {description || description1}
          </Typography>
          )
        : null}
    </div>
  )
}

AxText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxText
