const States = {
  JOHOR: 'Johor',
  KEDAH: 'Kedah',
  KELANTAN: 'Kelantan',
  MELAKA: 'Melaka',
  NEGERI_SEMBILAN: 'Negeri Sembilan',
  PAHANG: 'Pahang',
  PERAK: 'Perak',
  PERLIS: 'Perlis',
  PULAU_PINANG: 'Pulau Pinang',
  SABAH: 'Sabah',
  SARAWAK: 'Sarawak',
  SELANGOR: 'Selangor',
  TERENGGANU: 'Terengganu',
  KUALA_LUMPUR: 'W. P. Kuala Lumpur',
  LABUAN: 'W. P. Labuan',
  PUTRAJAYA: 'W. P. Putrajaya'
}
export default States
export const StatesList = [
  { name: States.JOHOR, value: States.JOHOR },
  { name: States.KEDAH, value: States.KEDAH },
  { name: States.KELANTAN, value: States.KELANTAN },
  { name: States.MELAKA, value: States.MELAKA },
  { name: States.NEGERI_SEMBILAN, value: States.NEGERI_SEMBILAN },
  { name: States.PAHANG, value: States.PAHANG },
  { name: States.PERAK, value: States.PERAK },
  { name: States.PERLIS, value: States.PERLIS },
  { name: States.PULAU_PINANG, value: States.PULAU_PINANG },
  { name: States.SABAH, value: States.SABAH },
  { name: States.SARAWAK, value: States.SARAWAK },
  { name: States.SELANGOR, value: States.SELANGOR },
  { name: States.TERENGGANU, value: States.TERENGGANU },
  { name: States.KUALA_LUMPUR, value: States.KUALA_LUMPUR },
  { name: States.LABUAN, value: States.LABUAN },
  { name: States.PUTRAJAYA, value: States.PUTRAJAYA }
]
