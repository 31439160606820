import { LoaderContext } from "../helpers/context";
import { useContext, useEffect } from "react";

const useLoadingEffect = (cb) => {
  const { hideLoader, showLoader } = useContext(LoaderContext)
  useEffect(async () => {
    showLoader()
    if (!cb) return hideLoader()
    if (typeof cb !== "function") throw "Invalid callback"
    if (typeof cb.then === "function") cb.then(hideLoader)
    else {
      cb(); hideLoader()
    }
  }, [])

  return
}

export default useLoadingEffect