import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Card, Typography, Box } from '@mui/material'
import Button from '../button'
import { formatter, util } from '../../util'

import './styles.css'

function AxBannerLeftText(props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const {
    background_image: backgroundImage, button, description, title
  } = data
  const { image } = backgroundImage || {}
  const { link } = button || {}
  let { url: imageUrl } = image || {}
  const { href: buttonHref, label: buttonLabel, target: buttonTarget } = link || {}

  if (!imageUrl) {
    const { url } = backgroundImage || {}
    imageUrl = url
  }

  return (
    <Card
      className={clsx('ax-banner-left-text', className)}
      sx={{
        backgroundImage: `url(${process.env.REACT_APP_BASE_URL + imageUrl})`,
        // backgroundImage: `url(${'http://axolot.oceannexus.my:1337' + imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Box className='text-box' sx={{
        padding: 5, paddingLeft: {
          sm: 8,
          xs: 3
        }, paddingRight: {
          sm: 8,
          xs: 3,
        }
      }}>
        {title ? <Typography className='title'>{title}</Typography> : null}

        {description ? <Typography className='description' dangerouslySetInnerHTML={{ __html: formatter.toHtml(description) }} /> : null}

        {buttonLabel || buttonHref
          ? (
            <Button
              color='white'
              href={buttonHref}
              sx={{ textTransform: 'capitalize' }}
              target={buttonTarget || '_self'}
              type='solid'
            >
              {buttonLabel || buttonHref}
            </Button>
          )
          : null}
      </Box>
    </Card>
  )
}

AxBannerLeftText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxBannerLeftText
