const Categories = {
  RETAIL: 'Retail',
  SERVICE: 'Service'
}
const Industries = {
  RETAIL_GENERENAL: 'Retail - General Department Store',
  RETAIL_SUPERMARKET: 'Retail - Supermarket, Groceries, Foodstuff',
  RETAIL_FASHION: 'Retail - Fashion & Accessories',
  RETAIL_ELECTRICAL: 'Retail - Electrical & Electronics',
  RETAIL_BOOKS: 'Retail - Books & Publications, Stationeries, Office & School Supplies',
  RETAIL_FURNITURE: 'Retail - Furniture, Decorations',
  RETAIL_HARDWARE: 'Retail - Hardware & Tools',
  RETAIL_PHARMACEUTICALS: 'Retail - Pharmaceuticals, Cosmetics, Health Supplements',
  RETAIL_FNB: 'Retail - F&B, Kitchen Supplies & Equipment',
  RETAIL_AUTOMOTIVE: 'Retail - Automotive, Supplies & Equipment',
  RETAIL_SPORTS: 'Retail - Sports & Leisure Equipment',
  RETAIL_ART: 'Retail - Art Supplies & Equipment',
  RETAIL_GARDENING: 'Retail - Gardening Supplies & Equipment',
  RETAIL_PET: 'Retail - Pet Supplies & Equipment',
  RETAIL_OPTICAL: 'Retail - Optical Equipment',
  RETAIL_TRAVEL: 'Retail - Travel Equipment',
  RETAIL_TOYS: 'Retail - Toys & Hobbies',
  RETAIL_FLORIST: 'Florist',
  RETAIL_OTHERS: 'Retail - Others',
  SERVICE_HEALTH: 'Service - Health & Lifestyle',
  SERVICE_TRAVEL: 'Service - Travel',
  SERVICE_EDUCATION: 'Service - Education, Training',
  SERVICE_HOUSEHOLD: 'Service - Household, Cleaning, Maintenance',
  SERVICE_AUTOMOTIVE: 'Service - Automotive',
  SERVICE_COMMUNICATION: 'Service - Communication, Networking',
  SERVICE_TRANSPORTATION: 'Service - Transportation',
  SERVICE_EVENTS: 'Service - Service, Entertainment',
  SERVICE_CATERING: 'Service - Catering, F&B Related',
  SERVICE_OTHERS: 'Service - Others'
}
export default Industries
export const IndustriesList = [
  { name: Industries.RETAIL_GENERENAL.replace('Retail - ', ''), value: Industries.RETAIL_GENERENAL, category: Categories.RETAIL },
  { name: Industries.RETAIL_SUPERMARKET.replace('Retail - ', ''), value: Industries.RETAIL_SUPERMARKET, category: Categories.RETAIL },
  { name: Industries.RETAIL_FASHION.replace('Retail - ', ''), value: Industries.RETAIL_FASHION, category: Categories.RETAIL },
  { name: Industries.RETAIL_ELECTRICAL.replace('Retail - ', ''), value: Industries.RETAIL_ELECTRICAL, category: Categories.RETAIL },
  { name: Industries.RETAIL_BOOKS.replace('Retail - ', ''), value: Industries.RETAIL_BOOKS, category: Categories.RETAIL },
  { name: Industries.RETAIL_FURNITURE.replace('Retail - ', ''), value: Industries.RETAIL_FURNITURE, category: Categories.RETAIL },
  { name: Industries.RETAIL_HARDWARE.replace('Retail - ', ''), value: Industries.RETAIL_HARDWARE, category: Categories.RETAIL },
  { name: Industries.RETAIL_PHARMACEUTICALS.replace('Retail - ', ''), value: Industries.RETAIL_PHARMACEUTICALS, category: Categories.RETAIL },
  { name: Industries.RETAIL_FNB.replace('Retail - ', ''), value: Industries.RETAIL_FNB, category: Categories.RETAIL },
  { name: Industries.RETAIL_AUTOMOTIVE.replace('Retail - ', ''), value: Industries.RETAIL_AUTOMOTIVE, category: Categories.RETAIL },
  { name: Industries.RETAIL_SPORTS.replace('Retail - ', ''), value: Industries.RETAIL_SPORTS, category: Categories.RETAIL },
  { name: Industries.RETAIL_ART.replace('Retail - ', ''), value: Industries.RETAIL_ART, category: Categories.RETAIL },
  { name: Industries.RETAIL_GARDENING.replace('Retail - ', ''), value: Industries.RETAIL_GARDENING, category: Categories.RETAIL },
  { name: Industries.RETAIL_PET.replace('Retail - ', ''), value: Industries.RETAIL_PET, category: Categories.RETAIL },
  { name: Industries.RETAIL_OPTICAL.replace('Retail - ', ''), value: Industries.RETAIL_OPTICAL, category: Categories.RETAIL },
  { name: Industries.RETAIL_TRAVEL.replace('Retail - ', ''), value: Industries.RETAIL_TRAVEL, category: Categories.RETAIL },
  { name: Industries.RETAIL_TOYS.replace('Retail - ', ''), value: Industries.RETAIL_TOYS, category: Categories.RETAIL },
  { name: Industries.RETAIL_FLORIST.replace('Retail - ', ''), value: Industries.RETAIL_FLORIST, category: Categories.RETAIL },
  { name: Industries.RETAIL_OTHERS.replace('Retail - ', ''), value: Industries.RETAIL_OTHERS, category: Categories.RETAIL },
  { name: Industries.SERVICE_HEALTH.replace('Service - ', ''), value: Industries.SERVICE_HEALTH, category: Categories.SERVICE },
  { name: Industries.SERVICE_TRAVEL.replace('Service - ', ''), value: Industries.SERVICE_TRAVEL, category: Categories.SERVICE },
  { name: Industries.SERVICE_HOUSEHOLD.replace('Service - ', ''), value: Industries.SERVICE_HOUSEHOLD, category: Categories.SERVICE },
  { name: Industries.SERVICE_AUTOMOTIVE.replace('Service - ', ''), value: Industries.SERVICE_AUTOMOTIVE, category: Categories.SERVICE },
  { name: Industries.SERVICE_COMMUNICATION.replace('Service - ', ''), value: Industries.SERVICE_COMMUNICATION, category: Categories.SERVICE },
  { name: Industries.SERVICE_TRANSPORTATION.replace('Service - ', ''), value: Industries.SERVICE_TRANSPORTATION, category: Categories.SERVICE },
  { name: Industries.SERVICE_EVENTS.replace('Service - ', ''), value: Industries.SERVICE_EVENTS, category: Categories.SERVICE },
  { name: Industries.SERVICE_CATERING.replace('Service - ', ''), value: Industries.SERVICE_CATERING, category: Categories.SERVICE },
  { name: Industries.SERVICE_OTHERS.replace('Service - ', ''), value: Industries.SERVICE_OTHERS, category: Categories.SERVICE }
]
