import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { util } from '../../util'
import { Fade, Slide } from 'react-reveal'

import './styles.css'

function AxLeftImageColumnCardsBlock (props) {
  const { className } = props
  let { data } = props
  data = util.isObject(data) ? data : {}
  const { image, text: cards } = data
  const { url: imageUrl } = image || {}

  return (
    <div className={clsx('ax-left-image-column-cards-block', className)}>
      <div className='image-box'>
        <Slide left>
          <img className='img' alt='' src={process.env.REACT_APP_BASE_URL + imageUrl} />
        </Slide>
        {/* <img className='img' alt='' src={'http://axolot.oceannexus.my:1337' + imageUrl} /> */}
      </div>

      {Array.isArray(cards)
        ? (
          <Fade down cascade>
            <div className='list-box'>
              {cards.map((card) => {
                const { id, description, text } = card

                return (
                  <div className='item' key={id}>
                    <img className='img' alt='' src='/icon/tick-solid-blue.png' />

                    {description || text ? <Typography className='description'>{description || text}</Typography> : null}
                  </div>
                )
              })}
            </div>
          </Fade>
        )
        : null}
    </div>
  )
}

AxLeftImageColumnCardsBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  data: PropTypes.object
}

export default AxLeftImageColumnCardsBlock
