import { RadioGroup, Radio, FormControlLabel as MuiFormControlLabel, TextField as MuiTextField, FormGroup, Checkbox, Grid, FormControl, FormHelperText, MenuItem, Slider } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { FormControlLabel, Select } from "../components"
import { createElement } from "react";

export const RadioField = (props) => {
  return (
    <RadioGroup name={props.name}>
      {
        props.options.map(({ label, value }, index) => {
          return (
            <MuiFormControlLabel key={`radio-option-${index}`} label={label} sx={{ '.MuiFormControlLabel-label': { marginBottom: 0, fontWeight: 400, color: "#234171" } }} control={<Radio onChange={props.onChange} name={props.name} disabled={props.disabled} value={value} inputProps={{ 'aria-label': label }} />} />
          )
        })
      }
    </RadioGroup>
  )
}

export const DateField = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <MuiTextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export const DateTimeField = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        {...props}
        inputFormat="dd/MM/yyyy hh:mm aa"
        renderInput={(params) => <MuiTextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export const CheckField = (props) => {
  const state = props.value || []

  const handleChange = (event) => {
    const value = event.target.value
    const index = state.findIndex(item => item === value)

    if (index !== -1) state.splice(index, 1)
    else state.push(value)

    props.onChange(state)
  }

  return (
    <FormGroup>
      {
        props.options.map(({ label, value }, index) => {
          return (
            <MuiFormControlLabel key={`checkbox-option-${index}`} label={label} sx={{ '.MuiFormControlLabel-label': { marginBottom: 0, fontWeight: 400, color: "#234171" } }} control={<Checkbox onChange={handleChange} name={props.name} disabled={props.disabled} value={value} inputProps={{ 'aria-label': label }} />} />
          )
        })
      }
    </FormGroup>
  )
}

export const SelectField = (props) => {
  const propsCopy = { ...props }
  delete propsCopy.options
  delete propsCopy.emptyText

  return (
    <Select
      {...propsCopy}
      displayEmpty
    >
      {props.options.map(({ label, value, disabled, selected }, index) => <MenuItem key={`select-item-${index}`} disabled={!!disabled} value={value} selected={!!selected}>{label}</MenuItem>)}
    </Select>
  )
}

export const SliderField = (props) => {
  return (
    <Slider
      {...props}
      aria-label={props.label}
      valueLabelDisplay="auto"
    />
  )
}

export const RenderForm = ({ fields, errors, footer, disabled = false, header }) => {
  return (
    <Grid container sx={{ border: "0.5px solid #DBDBDB", borderRadius: "5px", padding: { custom: "58px", xs: 2 } }}>
      {header}

      <form>
        <FormControl error={!!errors}>
          <Grid container spacing={2}>
            {
              fields.map(({ props, component, label, sizing }, index) => {
                return <Grid container item {...sizing} key={`form-field-${index}`} sx={{ alignItems: 'start' }}>
                  {
                    label ? <FormControlLabel label={label} control={
                      createElement(component, { ...props, sx: { width: "100%" }, disabled })
                    } sx={{ width: "100%", marginBottom: 2 }}></FormControlLabel>
                      : createElement(component, { ...props, sx: { width: "100%" }, disabled })
                  }
                  {(errors[props.name]) && <FormHelperText>{errors[props.name]}</FormHelperText >}
                </Grid>
              })
            }
          </Grid>

          {footer}
        </FormControl>
      </form>
    </Grid>
  )
}

export const validateForm = async (schema, values) => {
  // *** schema ***
  // e.g. yup.object().shape({[key]: [yup validation]})

  // *** values ***
  // e.g. {key: value}

  try {
    await schema.validate(values, { abortEarly: false })
    return {
      isValid: true,
    }
  } catch (error) {
    if (Array.isArray(error?.inner)) {
      const errors = error.inner.map(({ path, errors }) => ({ path, message: errors[0] }))
        .reduce((response, { path, message }) => {
          response[path] = message
          return response
        }, {})

      return {
        isValid: false,
        errors: errors
      }
    }

    throw error
  }
}